import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { updateProductInfo } from '../../ReduxSlices/productState';
import useAuth from '../../hooks/useAuth';
const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

const ClearCache = ({ seriesID }) => {
  const dispatch = useDispatch();
  const { auth } = useAuth();
  function clearCacheBySeries() {
    Axios.post(`${apiAddress}/products/clearCacheBySeries`, {
      sku: seriesID,
    })
      .then((res) => {
        dispatch(updateProductInfo({ notification: `${res.data}` }));
      })
      .catch((err) => {
        dispatch(updateProductInfo({ notification: `${err}` }));
      });
  }

  function clearCache() {
    Axios.post(`${apiAddress}/products/clearCache`)
      .then((res) => {
        dispatch(updateProductInfo({ notification: `${res.data}` }));
      })
      .catch((err) => {
        dispatch(updateProductInfo({ notification: `${err}` }));
      });
  }

  return (
    <div className='clear-cache-div'>
      {auth?.roles?.includes(1117) && (
        <button
          onClick={clearCache}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              clearCache();
            }
          }}
          className='discover-button clear-cache'
        >
          Clear Cache
        </button>
      )}
      {[1984, 1117].some((value) => auth?.roles?.includes(value)) && (
        <button
          onClick={clearCacheBySeries}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              clearCacheBySeries();
            }
          }}
          className='discover-button clear-cache'
        >
          Clear Product Cache
        </button>
      )}
    </div>
  );
};

export default ClearCache;
