import { AiOutlineCloseCircle } from 'react-icons/ai';
import '../../CSS/Popup.css';
const EnvPopup = ({ element, setShow }) => {
  return (
    <div className='popup'>
      <div className='overlay'></div>
      <div className='popup-element'>
        <AiOutlineCloseCircle
          className='close-button'
          onClick={() => {
            setShow('');
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setShow('');
            }
          }}
        />
        {element}
      </div>
    </div>
  );
};

export default EnvPopup;
