function getAllowedDropCoordinates(stageBuild, item, selected) {
  let newCoordinates = { ...item.dimensions };
  const { topLeft: tl, topRight: tr, bottomRight: br, bottomLeft: bl } = item.dimensions;
  let connectionFound = false;
  if (stageBuild.length < 1 || (stageBuild.length === 1 && selected === stageBuild[0].uid)) {
    newCoordinates = {
      //find x and y for center of drop box
      ...item.dimensions,
      topLeft: { ...tl, x: tl.x, y: tl.y },
      topRight: { ...tr, x: tr.x, y: tr.y },
      bottomRight: { ...br, x: br.x, y: br.y },
      bottomLeft: { ...bl, x: bl.x, y: bl.y },
    };
    connectionFound = true;
  } else {
    const distances = getAllCornerDistances(stageBuild, item);
    //loop through the distances and set the newCoordinates to the closest corner that connects
    for (let i = 0; i < distances.length; i++) {
      const d = distances[i];
      if (checkConnection(stageBuild, item, d)) {
        const closestItem = stageBuild.find((i) => i.uid === d.existingItemUid);
        const closestCorner = closestItem.dimensions[d.existingItemCorner];
        const rotationAngleDeg =
          findAngleOfRotation(
            tr,
            tl,
            closestItem.dimensions.topRight,
            closestItem.dimensions.topLeft
          ) *
          (180 / Math.PI);
        //dont use middle points unless connecting two rectangles on a 65° - 110° angle
        if (d.newItemCorner.includes('Middle') || d.existingItemCorner.includes('Middle')) {
          if (
            item.type !== 'rectangle' ||
            closestItem.type !== 'rectangle' ||
            rotationAngleDeg < 65 ||
            rotationAngleDeg > 110
          ) {
            continue;
          }
        }
        const deltaX = closestCorner.x - item.dimensions[d.newItemCorner].x;
        const deltaY = closestCorner.y - item.dimensions[d.newItemCorner].y;
        newCoordinates = {
          ...item.dimensions,
          topLeft:
            d.newItemCorner === 'topLeft' ?
              { ...tl, x: closestCorner.x, y: closestCorner.y }
            : { ...tl, x: tl.x + deltaX, y: tl.y + deltaY },
          topRight:
            d.newItemCorner === 'topRight' ?
              { ...tr, x: closestCorner.x, y: closestCorner.y }
            : { ...tr, x: tr.x + deltaX, y: tr.y + deltaY },
          bottomRight:
            d.newItemCorner === 'bottomRight' ?
              { ...br, x: closestCorner.x, y: closestCorner.y }
            : { ...br, x: br.x + deltaX, y: br.y + deltaY },
          bottomLeft:
            d.newItemCorner === 'bottomLeft' ?
              { ...bl, x: closestCorner.x, y: closestCorner.y }
            : { ...bl, x: bl.x + deltaX, y: bl.y + deltaY },
        };
        if (item.type === 'rectangle') {
          const { topLeft, topRight, bottomRight, bottomLeft } = newCoordinates;
          newCoordinates = {
            ...newCoordinates,
            topMiddle: {
              ...item.dimensions.topMiddle,
              x: (topRight.x + topLeft.x) / 2,
              y: (topRight.y + topLeft.y) / 2,
            },
            bottomMiddle: {
              ...item.dimensions.bottomMiddle,
              x: (bottomRight.x + bottomLeft.x) / 2,
              y: (bottomRight.y + bottomLeft.y) / 2,
            },
          };
        }

        //need to make other corners match. check if 2 corners are already connected and if the connections work (if alreadyConnectedCorners.length is 3, its a rectangle connecting to another rectangle and doesn't need to be checked)
        const alreadyConnectedCorners = checkForConnectedCorners(stageBuild, item, newCoordinates);
        if (
          alreadyConnectedCorners.length < 2 ||
          !checkConnection(
            stageBuild,
            item,
            alreadyConnectedCorners[0],
            alreadyConnectedCorners.length === 2 && alreadyConnectedCorners[1]
          )
        ) {
          //find the next closest corner
          let d2;
          for (let j = 0; j < distances.length; j++) {
            if (
              i !== j &&
              distances[j].existingItemUid === closestItem.uid &&
              distances[j].newItemCorner !== d.newItemCorner &&
              distances[j].existingItemCorner !== d.existingItemCorner &&
              checkConnection(stageBuild, item, distances[j], d)
            ) {
              d2 = distances[j];
              break;
            }
          }
          if (d2) {
            newCoordinates = getRotatedCoordinates(
              stageBuild,
              item,
              newCoordinates,
              d,
              d2,
              closestItem,
              closestCorner
            );
          }
        }
        if (
          checkOverlap(item, newCoordinates) ||
          checkForConnectedCorners(stageBuild, item, newCoordinates).length < 2
        ) {
          continue;
        }
        connectionFound = true;
        break;
      }
    }
  }
  if (!connectionFound) {
    while (checkOverlap(item, newCoordinates)) {
      Object.keys(newCoordinates).forEach((corner) => (newCoordinates[corner].x += 15));
    }
  }
  if (item.type === 'rectangle') {
    const { topLeft, topRight, bottomRight, bottomLeft } = newCoordinates;
    newCoordinates = {
      ...newCoordinates,
      topMiddle: {
        ...item.dimensions.topMiddle,
        x: (topRight.x + topLeft.x) / 2,
        y: (topRight.y + topLeft.y) / 2,
      },
      bottomMiddle: {
        ...item.dimensions.bottomMiddle,
        x: (bottomRight.x + bottomLeft.x) / 2,
        y: (bottomRight.y + bottomLeft.y) / 2,
      },
    };
  }
  return newCoordinates;
}

function getAllCornerDistances(stageBuild, item) {
  const distances = [];
  stageBuild.forEach((i) => {
    if (i.uid !== item.uid) {
      Object.keys(item.dimensions).forEach((newItemCorner) => {
        Object.keys(i.dimensions).forEach((existingItemCorner) => {
          distances.push({
            newItemCorner: newItemCorner,
            distance: calculateDistance(
              i.dimensions[existingItemCorner].x,
              i.dimensions[existingItemCorner].y,
              item.dimensions[newItemCorner].x,
              item.dimensions[newItemCorner].y
            ),
            existingItemUid: i.uid,
            existingItemId: i.id,
            existingItemCorner: existingItemCorner,
          });
        });
      });
    }
  });
  distances.sort((a, b) => a.distance - b.distance);
  return distances;
}
function calculateDistance(x1, y1, x2, y2) {
  const distance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2); // distance formula
  return distance;
}

function checkConnection(stageBuild, item, distance, otherDistance) {
  const connectionItem = stageBuild.find((i) => i.uid === distance.existingItemUid);
  if (
    item.productList[0].productInfo.Series === 'Risers' &&
    connectionItem.productList[0].productInfo.Series === 'Risers' &&
    item.type === connectionItem.type
  ) {
    if (item.level === connectionItem.level) {
      return checkConnectingSide('sides', distance, otherDistance);
    } else {
      if (item.level + 1 === connectionItem.level) {
        return checkConnectingSide('top', distance, otherDistance);
      } else if (item.level - 1 === connectionItem.level) {
        return checkConnectingSide('bottom', distance, otherDistance);
      }
    }
  } else if (
    item.productList[0].productInfo.Series === 'Fixed Height Stages' &&
    connectionItem.productList[0].productInfo.Series === 'Fixed Height Stages'
  ) {
    if (item.type === 'rectangle') {
      if (connectionItem.type === 'rectangle') {
        if (
          item.level === connectionItem.level ||
          item.level + 1 === connectionItem.level ||
          item.level - 1 === connectionItem.level
        ) {
          if (otherDistance) {
            //make sure both connections are not middle points
            let middlepoints = 0;
            if (distance.newItemCorner.includes('Middle')) {
              middlepoints++;
            }
            if (distance.existingItemCorner.includes('Middle')) {
              middlepoints++;
            }
            if (otherDistance.newItemCorner.includes('Middle')) {
              middlepoints++;
            }
            if (otherDistance.existingItemCorner.includes('Middle')) {
              middlepoints++;
            }
            return middlepoints < 2;
          } else {
            return true;
          }
        }
      } else if (connectionItem.type === 'pie' && item.level === connectionItem.level) {
        return checkConnectingSide('sidesUpsideDown', distance, otherDistance);
      }
    } else if (item.type === 'pie') {
      if (connectionItem.type === 'rectangle' && item.level === connectionItem.level) {
        return checkConnectingSide('sidesUpsideDown', distance, otherDistance);
      } else if (connectionItem.type === 'pie') {
        if (item.level === connectionItem.level) {
          return checkConnectingSide('sides', distance, otherDistance);
        } else if (item.level + 1 === connectionItem.level) {
          return checkConnectingSide('top', distance, otherDistance);
        } else if (item.level - 1 === connectionItem.level) {
          return checkConnectingSide('bottom', distance, otherDistance);
        }
      }
    }
  }
  return false;
}

function checkConnectingSide(allowedSide, distance, otherDistance) {
  const itemCornerParts = distance.newItemCorner.split(/(?=[A-Z])/);
  const connectionItemParts = distance.existingItemCorner.split(/(?=[A-Z])/);
  switch (allowedSide) {
    case 'top':
      if (
        itemCornerParts[0] === 'top' &&
        connectionItemParts[0] === 'bottom' &&
        itemCornerParts[1] === connectionItemParts[1]
      ) {
        return true;
      }
      return false;
    case 'bottom':
      if (
        itemCornerParts[0] === 'bottom' &&
        connectionItemParts[0] === 'top' &&
        itemCornerParts[1] === connectionItemParts[1]
      ) {
        return true;
      }
      return false;
    case 'sidesUpsideDown': //can connect to either side, right side up and upside down
      if (
        (((itemCornerParts[0] === 'top' && connectionItemParts[0] === 'bottom') ||
          (itemCornerParts[0] === 'bottom' && connectionItemParts[0] === 'top')) &&
          itemCornerParts[1] === connectionItemParts[1]) ||
        (((itemCornerParts[1] === 'Right' && connectionItemParts[1] === 'Left') ||
          (itemCornerParts[1] === 'Left' && connectionItemParts[1] === 'Right')) &&
          itemCornerParts[0] === connectionItemParts[0])
      ) {
        return true;
      }
      return false;
    case 'sides':
      if (
        ((itemCornerParts[1] === 'Right' && connectionItemParts[1] === 'Left') ||
          (itemCornerParts[1] === 'Left' && connectionItemParts[1] === 'Right')) &&
        itemCornerParts[0] === connectionItemParts[0]
      ) {
        if (otherDistance) {
          return (
            distance.newItemCorner !== otherDistance.existingItemCorner &&
            distance.existingItemCorner !== otherDistance.newItemCorner
          );
        } else {
          return true;
        }
      }
      return false;
    default:
      return false;
  }
}

function checkOverlap(item, newCoordinates) {
  const svg = document.getElementById('stage-svg');
  const paths = document.querySelectorAll(`.path:not(.uid-${item.uid})`);
  const perimeter = []; //need to calculate all ordered pairs along the perimeter!
  //find the y=mx+b for each side of the item
  const lines = Object.keys(newCoordinates).map((coordinate, index) => {
    const orderedPair = newCoordinates[coordinate];
    const nextOrderedPair =
      index < 3 ?
        newCoordinates[Object.keys(newCoordinates)[index + 1]]
      : newCoordinates[Object.keys(newCoordinates)[0]];
    const slope = (orderedPair.y - nextOrderedPair.y) / (orderedPair.x - nextOrderedPair.x);
    return {
      m: slope,
      b: -slope * orderedPair.x + orderedPair.y,
      minX: orderedPair.x < nextOrderedPair.x ? orderedPair.x : nextOrderedPair.x,
      maxX: orderedPair.x < nextOrderedPair.x ? nextOrderedPair.x : orderedPair.x,
      minY: orderedPair.y < nextOrderedPair.y ? orderedPair.y : nextOrderedPair.y,
      maxY: orderedPair.y < nextOrderedPair.y ? nextOrderedPair.y : orderedPair.y,
    };
  });
  //find the points along each of those lines
  lines.forEach((line) => {
    var x = line.minX;
    var y = line.minY;
    if (Number.isFinite(line.m) && line.m <= 1 && line.m >= -1) {
      do {
        y = line.m * x + line.b;
        perimeter.push({ x: x, y: y, type: 1 });
        x++;
      } while (x <= line.maxX);
    } else if (line.m > 1 || line.m < -1) {
      do {
        x = (y - line.b) / line.m;
        perimeter.push({ x: x, y: y, type: 2 });
        y++;
      } while (y <= line.maxY);
    } else {
      do {
        perimeter.push({ x: x, y: y++, type: 3 });
      } while (y <= line.maxY);
    }
  });

  //check if any of the points along the item's lines are in any other path's fill
  let overlapping = false;
  for (let p = 0; p < paths.length; p++) {
    perimeter.forEach((orderedPair) => {
      const pointObj = svg.createSVGPoint();
      pointObj.x = orderedPair.x;
      pointObj.y = orderedPair.y;
      if (paths[p].isPointInFill(pointObj) && !paths[p].isPointInStroke(pointObj)) {
        overlapping = true;
      }
    });
    //check if all 4 corners overlap - it is directly on top on the same shape
    let overlappingCorners = 0;
    Object.keys(newCoordinates).forEach((coordinate) => {
      const pointObj = svg.createSVGPoint();
      pointObj.x = newCoordinates[coordinate].x;
      pointObj.y = newCoordinates[coordinate].y;
      if (paths[p].isPointInStroke(pointObj)) {
        overlappingCorners++;
      }
    });
    if (overlappingCorners > 3) {
      overlapping = true;
    }
  }

  return overlapping;
}
//if at least two corners are connected properly, returns an array of connected corners. Otherwise returns []
function checkForConnectedCorners(stageBuild, item, newCoordinates) {
  let connectedCorners = [];
  for (const i of stageBuild) {
    if (i.uid !== item.uid) {
      for (const corner of Object.keys(newCoordinates)) {
        for (const c of Object.keys(i.dimensions)) {
          if (
            Math.abs(newCoordinates[corner].x - i.dimensions[c].x) <= 1 &&
            Math.abs(newCoordinates[corner].y - i.dimensions[c].y) <= 1 &&
            checkConnection(stageBuild, item, {
              newItemCorner: corner,
              existingItemCorner: c,
              existingItemUid: i.uid,
            })
          ) {
            connectedCorners.push({
              newItemCorner: corner,
              //distance: calculateDistance(i.dimensions[existingItemCorner].x, i.dimensions[existingItemCorner].y, item.dimensions[newItemCorner].x, item.dimensions[newItemCorner].y),
              existingItemUid: i.uid,
              existingItemId: i.id,
              existingItemCorner: c,
            });
          }
        }
      }
      if (connectedCorners.length === 2 && item.type === 'rectangle' && i.type === 'rectangle') {
        //make sure rectangles are not connected on two middle points
        if (!checkConnection(stageBuild, item, connectedCorners[0], connectedCorners[1])) {
          connectedCorners = [];
        }
      }
      if (connectedCorners.length > 1) {
        return connectedCorners;
      } else {
        connectedCorners = [];
      }
    }
  }
  return [];
}

function checkIfStable(stageBuild, item, dontInclude) {
  let stable = false;
  const stableConnections = {};
  for (const i of stageBuild) {
    if (
      i.uid !== item.uid &&
      (!dontInclude || i.uid !== dontInclude.uid) &&
      item.level === i.level
    ) {
      for (const corner of Object.keys(item.dimensions)) {
        for (const c of Object.keys(i.dimensions)) {
          if (
            Math.abs(item.dimensions[corner].x - i.dimensions[c].x) <= 1 &&
            Math.abs(item.dimensions[corner].y - i.dimensions[c].y) <= 1
          ) {
            if (i.type === 'rectangle') {
              stableConnections[i.uid] = stableConnections[i.uid] || [];
              stableConnections[i.uid].push({ corner: corner, cornerConnectedTo: c });
            } else if (i.type === 'pie' && !dontInclude) {
              stable = stable || checkIfStable(stageBuild, i, item);
            }
          }
        }
      }
      stable =
        stable || Object.keys(stableConnections).some((key) => stableConnections[key].length > 1);
    }
  }
  return stable;
}

function checkForBadConnections(stageBuild, item) {
  const badConnections = [];
  const badSides = [];
  for (const i of stageBuild) {
    if (i.uid !== item.uid) {
      for (const corner of Object.keys(item.dimensions)) {
        for (const c of Object.keys(i.dimensions)) {
          if (
            Math.abs(item.dimensions[corner].x - i.dimensions[c].x) <= 3 &&
            Math.abs(item.dimensions[corner].y - i.dimensions[c].y) <= 3 &&
            !checkConnection(stageBuild, item, {
              newItemCorner: corner,
              existingItemCorner: c,
              existingItemUid: i.uid,
            })
          ) {
            if (
              badConnections.find((n) => n.newItemCorner === corner && n.existingItemUid === i.uid)
            ) {
              continue;
            }
            badConnections.push({
              newItemCorner: corner,
              existingItemUid: i.uid,
            });
          }
        }
      }
      const grouped = badConnections.reduce((acc, obj) => {
        const key = obj.existingItemUid;
        acc[key] = acc[key] || [];
        acc[key].push(obj.newItemCorner);
        return acc;
      }, {});
      Object.keys(grouped).forEach((existingItem) => {
        if (grouped[existingItem].length > 1) {
          if (
            grouped[existingItem][0].includes('top') &&
            grouped[existingItem][1].includes('top') &&
            !badSides.includes('top')
          ) {
            badSides.push('top');
          } else if (
            grouped[existingItem][0].includes('Right') &&
            grouped[existingItem][1].includes('Right') &&
            !badSides.includes('Right')
          ) {
            badSides.push('Right');
          } else if (
            grouped[existingItem][0].includes('Left') &&
            grouped[existingItem][1].includes('Left') &&
            !badSides.includes('Left')
          ) {
            badSides.push('Left');
          } else if (
            grouped[existingItem][0].includes('bottom') &&
            grouped[existingItem][1].includes('bottom') &&
            !badSides.includes('bottom')
          ) {
            badSides.push('bottom');
          }
        }
      });
    }
  }
  return badSides;
}

function getRotatedCoordinates(
  stageBuild,
  item,
  newCoordinates,
  d,
  d2,
  closestItem,
  closestCorner
) {
  //Need to find angle of rotation - theta
  const theta = findAngleOfRotation(
    newCoordinates[d.newItemCorner],
    newCoordinates[d2.newItemCorner],
    closestCorner,
    closestItem.dimensions[d2.existingItemCorner]
  );

  //rotate counterclockwise (positive theta)
  newCoordinates = rotateShape(item, d, newCoordinates, theta);
  //check if d2 connect now, otherwise rotate back to initial position and clockwise (theta * -2)
  if (checkForConnectedCorners(stageBuild, item, newCoordinates).length < 2) {
    newCoordinates = rotateShape(item, d, newCoordinates, theta * -2);
  }
  //if the angle is obtuse, it won't connect using theta because theta only calculates the acute angle
  //if d2 still doesn't connect, rotate back to initial position and then rotate the suplementary angle (180 - theta)- positive
  //make sure its not rotating more than 180 degrees
  if (
    checkForConnectedCorners(stageBuild, item, newCoordinates).length < 2 &&
    Math.PI - theta < Math.PI
  ) {
    newCoordinates = rotateShape(item, d, newCoordinates, theta * -1);
    newCoordinates = rotateShape(item, d, newCoordinates, Math.PI - theta);
  }
  //if still doesn't connect, rotate back and then rotate the suplementary angle - negative
  if (
    checkForConnectedCorners(stageBuild, item, newCoordinates).length < 2 &&
    Math.PI - theta < Math.PI
  ) {
    newCoordinates = rotateShape(item, d, newCoordinates, (Math.PI - theta) * -2);
  }
  //if still doesn't connect, rotate back to initial position
  if (
    checkForConnectedCorners(stageBuild, item, newCoordinates).length < 2 &&
    Math.PI - theta < Math.PI
  ) {
    newCoordinates = rotateShape(item, d, newCoordinates, Math.PI - theta);
  }

  return newCoordinates;
}
//Finds angle in RADIANS formed by 2 line segments using the endpoints of each
function findAngleOfRotation(pointA, pointB, pointC, pointD) {
  //find the slopes of the two sides - ▲y/▲x
  let lineABslope = (pointA.y - pointB.y) / (pointA.x - pointB.x);
  let lineCDslope = (pointC.y - pointD.y) / (pointC.x - pointD.x);
  //if slope is infinite/ -infinite (vertical line) set it to 90/-90°
  lineABslope =
    Number.isFinite(lineABslope) ? lineABslope
    : lineABslope > 0 ? 90
    : -90;
  lineCDslope =
    Number.isFinite(lineCDslope) ? lineCDslope
    : lineCDslope > 0 ? 90
    : -90;
  //calculate angle of rotation based on slopes (finds the acute angle formed by the intersecting lines)
  const theta = Math.atan(Math.abs((lineCDslope - lineABslope) / (1 + lineCDslope * lineABslope)));

  return theta;
}
function rotateShape(item, d, newCoordinates, theta) {
  //Params: item rotating, the center of rotation, coordinates that need to be rotated, angle of rotation
  const centerOfRotation = {
    x: newCoordinates[d.newItemCorner]?.x || d.x,
    y: newCoordinates[d.newItemCorner]?.y || d.y,
  };
  Object.keys(item.dimensions).forEach((corner) => {
    if (corner !== d.newItemCorner && !corner.includes('Middle')) {
      //step #1: translate point to origin by subtracting the coordinate that its being rotated around
      const x1 = newCoordinates[corner].x - centerOfRotation.x;
      const y1 = -newCoordinates[corner].y + centerOfRotation.y;
      //step #2: use the rotation formula to rotate the point around the origin
      const x2 = x1 * Math.cos(theta) - y1 * Math.sin(theta);
      const y2 = x1 * Math.sin(theta) + y1 * Math.cos(theta);
      //step #3: translate the point back to original spot
      const x3 = x2 + centerOfRotation.x;
      const y3 = y2 - centerOfRotation.y;
      newCoordinates[corner].x = x3;
      newCoordinates[corner].y = -y3;
    }
  });
  return newCoordinates;
}
function findMidpoint(dimensions) {
  const { topLeft, topRight, bottomRight, bottomLeft } = dimensions;
  //Find midpoint of topRight to bottomLeft
  const mp1 = { x: (topRight.x + bottomLeft.x) / 2, y: (topRight.y + bottomLeft.y) / 2 };
  //Find midpoint of topLeft to bottomRight
  const mp2 = { x: (topLeft.x + bottomRight.x) / 2, y: (topLeft.y + bottomRight.y) / 2 };
  //Find midpoint of the two midpoints
  return { x: (mp1.x + mp2.x) / 2, y: (mp1.y + mp2.y) / 2 };
}

//find all the sides that are not near another item - can get steps, guardrails, and/or skirting
function findExposedSides(stageBuild, combineRiserSides) {
  const lines = [];
  let sideIdTracker = 0;
  stageBuild.forEach((item) => {
    const itemCorners =
      item.type === 'rectangle' ?
        ['topLeft', 'topMiddle', 'topRight', 'bottomRight', 'bottomMiddle', 'bottomLeft']
      : ['topLeft', 'topRight', 'bottomRight', 'bottomLeft'];
    //find the y=mx+b for each side of the item
    const itemLines = itemCorners.map((corner, index) => {
      const orderedPair = item.dimensions[corner];
      const nextOrderedPair = item.dimensions[itemCorners[(index + 1) % itemCorners.length]];
      const slope = (orderedPair.y - nextOrderedPair.y) / (orderedPair.x - nextOrderedPair.x);
      return {
        id: sideIdTracker++,
        m: slope,
        b: -slope * orderedPair.x + orderedPair.y,
        minX: orderedPair.x < nextOrderedPair.x ? orderedPair.x : nextOrderedPair.x,
        maxX: orderedPair.x < nextOrderedPair.x ? nextOrderedPair.x : orderedPair.x,
        minY: orderedPair.y < nextOrderedPair.y ? orderedPair.y : nextOrderedPair.y,
        maxY: orderedPair.y < nextOrderedPair.y ? nextOrderedPair.y : orderedPair.y,
        itemUid: item.uid,
        corner1: { corner: corner, x: orderedPair.x, y: orderedPair.y },
        corner2: {
          corner: itemCorners[index + 1] || itemCorners[0],
          x: nextOrderedPair.x,
          y: nextOrderedPair.y,
        },
      };
    });
    lines.push(...itemLines);
  });
  //go thru all the lines and check if any other line is similar to this line (near it)
  let exposedSides = [];
  for (let i = 0; i < lines.length; i++) {
    let isUnique = true;
    for (let j = 0; j < lines.length; j++) {
      if (
        i !== j &&
        Math.abs(lines[i].minX - lines[j].minX) <= 5 &&
        Math.abs(lines[i].maxX - lines[j].maxX) <= 5 &&
        Math.abs(lines[i].minY - lines[j].minY) <= 5 &&
        Math.abs(lines[i].maxY - lines[j].maxY) <= 5
      ) {
        isUnique = false;
        break;
      }
    }
    if (
      isUnique &&
      calculateDistance(
        lines[i].corner1.x,
        lines[i].corner1.y,
        lines[i].corner2.x,
        lines[i].corner2.y
      ) > 10
    ) {
      //if it is unique and is has length (not the tip of a pie)
      exposedSides.push(lines[i]);
    }
  }
  exposedSides = combineRiserSides ? combineMultilevelRiserSides(exposedSides) : exposedSides;
  return exposedSides;
}

function combineMultilevelRiserSides(segments) {
  //recursively runs through all the exposed sides (segments) and connects any adjacent sides of risers to form one long side
  let madeAnyCombinations = false;
  const combinedSegments = [];
  const visited = new Set();

  for (let i = 0; i < segments.length; i++) {
    if (visited.has(i)) {
      continue;
    }

    let mergedSegment = segments[i];
    //if the side's length is less than 20 (only sides of risers are so short) or the id includes an underscore (it is already a combination of two riser sides)
    if (
      calculateDistance(
        mergedSegment.corner1.x,
        mergedSegment.corner1.y,
        mergedSegment.corner2.x,
        mergedSegment.corner2.y
      ) < 20 ||
      mergedSegment.id.toString().includes('_')
    ) {
      for (let j = i + 1; j < segments.length; j++) {
        if (visited.has(j)) {
          continue;
        }
        //if the two line segments are connected and the slopes are similar and the second segment is either less than 20 long or already a combination of two riser sides
        if (
          areConnected(mergedSegment, segments[j]) &&
          isClose(mergedSegment.m, segments[j].m, 0.1) &&
          (calculateDistance(
            segments[j].corner1.x,
            segments[j].corner1.y,
            segments[j].corner2.x,
            segments[j].corner2.y
          ) < 20 ||
            segments[j].id.toString().includes('_'))
        ) {
          madeAnyCombinations = true;
          mergedSegment = mergeSegments(mergedSegment, segments[j]);
          visited.add(j);
        }
      }
    }

    combinedSegments.push(mergedSegment);
    visited.add(i);
  }
  if (madeAnyCombinations) {
    //if any new combinations were made, call this function recursively to check if there are any more possible combinations, using the newly combined line segments
    return combineMultilevelRiserSides(combinedSegments);
  } else {
    return combinedSegments;
  }
}
function isClose(value1, value2, margin) {
  return Math.abs(value1 - value2) <= (margin || 5);
}

function areConnected(segment1, segment2) {
  //check if two line segments are connected (if either end of first is close to either end of second)
  return (
    (isClose(segment1.corner2.x, segment2.corner1.x) &&
      isClose(segment1.corner2.y, segment2.corner1.y)) ||
    (isClose(segment1.corner1.x, segment2.corner2.x) &&
      isClose(segment1.corner1.y, segment2.corner2.y)) ||
    (isClose(segment1.corner1.x, segment2.corner1.x) &&
      isClose(segment1.corner1.y, segment2.corner1.y)) ||
    (isClose(segment1.corner2.x, segment2.corner2.x) &&
      isClose(segment1.corner2.y, segment2.corner2.y))
  );
}

function mergeSegments(segment1, segment2) {
  //combines two segments (sides of risers)
  const [newCorner1, newCorner2] = getDistinctCorners(segment1, segment2);
  return {
    id: `${segment1.id}_${segment2.id}`,
    itemUid: `${segment1.itemUid}_${segment2.itemUid}`,
    m: (segment1.m + segment2.m) / 2,
    b: (segment1.b + segment2.b) / 2,
    minX: Math.min(segment1.minX, segment2.minX),
    maxX: Math.max(segment1.maxX, segment2.maxX),
    minY: Math.min(segment1.minY, segment2.minY),
    maxY: Math.max(segment1.maxY, segment2.maxY),
    corner1: newCorner1,
    corner2: newCorner2,
  };
}
function getDistinctCorners(segment1, segment2) {
  //finds the two end corners of tow line segments that are being connected (not the corners that the lines are being connected on, but the other end of each line)
  const corners = [segment1.corner1, segment1.corner2, segment2.corner1, segment2.corner2];
  const distinctCorners = [];
  for (let i = 0; i < corners.length; i++) {
    let unique = true;
    for (let j = 0; j < corners.length; j++) {
      if (i !== j && isClose(corners[i].x, corners[j].x) && isClose(corners[i].y, corners[j].y)) {
        unique = false;
        break;
      }
    }
    if (unique) {
      distinctCorners.push(corners[i]);
    }
  }
  return distinctCorners;
}

function getTotalBuildDims(stageBuild) {
  const ends = {};
  let minX;
  let maxX;
  let minY;
  let maxY;
  for (const item of stageBuild) {
    Object.keys(item.dimensions).forEach((corner) => {
      if (item.dimensions[corner].x < minX || !minX) {
        ends.left = item.dimensions[corner];
        minX = item.dimensions[corner].x;
      }
      if (item.dimensions[corner].x > maxX || !maxX) {
        ends.right = item.dimensions[corner];
        maxX = item.dimensions[corner].x;
      }
      if (item.dimensions[corner].y < minY || !minY) {
        ends.top = item.dimensions[corner];
        minY = item.dimensions[corner].y;
      }
      if (item.dimensions[corner].y > maxY || !maxY) {
        ends.bottom = item.dimensions[corner];
        maxY = item.dimensions[corner].y;
      }
    });
  }
  return ends;
}

function enlargeBuild(build, max) {
  let stageBuild = JSON.parse(JSON.stringify(build));
  const middleRow = stageBuild.filter((i) => i.middle === 'true');
  let middleWidth = 0;
  let midline = 0;
  //get width of middle row and midline(x value of right side of middle row)
  middleRow.forEach((i) => {
    let leastX = Infinity;
    let greatestX = 0;
    for (const key in i.dimensions) {
      if (i.dimensions[key].x > midline) {
        midline = i.dimensions[key].x;
      }
      if (i.dimensions[key].x < leastX) {
        leastX = i.dimensions[key].x;
      }
      if (i.dimensions[key].x > greatestX) {
        greatestX = i.dimensions[key].x;
      }
    }
    if (middleWidth < greatestX - leastX) {
      middleWidth = greatestX - leastX;
    }
  });
  //all the items to the right of midline get moved over middleWidth units to the right
  stageBuild = stageBuild.map((i) => {
    if (Object.values(i.dimensions).some((val) => val.x >= midline) && i.middle !== 'true') {
      Object.values(i.dimensions).forEach((dim) => (dim.x = dim.x + middleWidth));
    }
    return i;
  });
  //duplicate middle row and place in space in the middle
  let greatestUid = 0;
  let greatestBuildItemId = 0;
  stageBuild.forEach((i) => {
    if (i.uid > greatestUid) {
      greatestUid = i.uid;
    }
    if (i.buildItemId > greatestBuildItemId) {
      greatestBuildItemId = i.buildItemId;
    }
  });
  middleRow.forEach((i) => {
    const newItem = {
      ...i,
      uid: greatestUid + 1,
      buildItemId: greatestBuildItemId + 1,
      middle: 'false',
      dimensions: JSON.parse(JSON.stringify(i.dimensions)), // Deep copy of dimensions
    };
    greatestUid++;
    greatestBuildItemId++;
    Object.entries(newItem.dimensions).forEach(([key, val]) => {
      newItem.dimensions[key] = { x: val.x + middleWidth, y: val.y };
    });
    stageBuild.push(newItem);
  });

  //call this function recursively until enlarged to max
  const buildDims = getTotalBuildDims(stageBuild);
  const buildLength = Math.floor(parseFloat(buildDims.right.x - buildDims.left.x).toFixed(1));
  if (max.length && buildLength < max.length) {
    //if still smaller than max, enlarge again
    return enlargeBuild(stageBuild, max);
  } else if (max.length && buildLength > max.length) {
    //if once enlared is greater than max, return original
    return build;
  }
  if (max.capacity && stageBuild.reduce((total, i) => total + i.capacity, 0) < max.capacity) {
    return enlargeBuild(stageBuild, max);
  }
  return stageBuild;
}

export {
  calculateDistance,
  checkForBadConnections,
  checkForConnectedCorners,
  checkIfStable,
  findAngleOfRotation,
  findExposedSides,
  findMidpoint,
  getAllowedDropCoordinates,
  getTotalBuildDims,
  rotateShape,
  enlargeBuild,
};
