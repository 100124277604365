import clipboardCopy from 'clipboard-copy';
import { useEffect, useState } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { changeProduct, updateProductInfo } from '../../ReduxSlices/productState';
import StageSkuMessage from '../Stage/StageSkuMessage';
import GetColorInfo from './GetColorInfo';
import SkuHover from './SkuHover';

const Sku = ({
  presetOptions,
  fullConfigOptions,
  // colorList,
}) => {
  const {
    optionsSelected,
    filterExceptionList,
    finishTypes,
    product,
    skuDisplayed,
    skuSeries: seriesSku,
    stageBuilder,
  } = useSelector((state) => state.productState.value);
  // const sku = product?.modelNum;
  const dispatch = useDispatch();
  const [skuStatus, setSkuStatus] = useState('Not Completed'); //Options -Completed, Not Completed, Copied

  const [hoverClear, setHoverClear] = useState(false);
  const [skuComponents, setSkuComponents] = useState([]);
  // const optional = ['Height', 'Premium Edge Color'];
  const preset = presetOptions?.map((i) => i.name);

  function checkSku() {
    if (
      !Object.keys(product?.configOptions)
        .filter((i) => !preset.includes(i))
        .filter((i) => !Object.keys(fullConfigOptions?.addOns).includes(i))
        .filter((i) => !Object.keys(fullConfigOptions?.accessories)?.includes(i))
        .every((i) => Object.keys(optionsSelected).includes(i))
    ) {
      return 'Not Completed';
    }
    return 'Completed';
  }

  useEffect(() => {
    setSkuStatus(checkSku());
  }, [optionsSelected, product]);

  useEffect(() => {
    if (skuStatus === 'Not Completed') {
      dispatch(updateProductInfo({ skuCompleted: false }));
    } else {
      dispatch(updateProductInfo({ skuCompleted: true }));
    }
  }, [skuStatus]);

  async function generateSKU() {
    const configOptions = product?.configOptions;
    var skuComponentsHolder = [];
    if (seriesSku) {
      skuComponentsHolder.push({
        pos: 1,
        value: seriesSku,
        hover: 'Series',
      });
    }

    for (var optionName in configOptions) {
      var option = configOptions[optionName];
      const { pos, charLen, value } = option;
      if (Object.keys(optionsSelected).includes(option.configName)) {
        // eslint-disable-next-line no-empty
        if (pos === 0 || pos === null) {
        } else if (filterExceptionList.includes(optionName)) {
          const color = await GetColorInfo(
            optionsSelected[optionName],
            finishTypes,
            optionName,
            product
          );
          const colorCode = color?.value ? color?.value : '__';
          skuComponentsHolder.push({
            pos: pos,
            value: colorCode,
            hover: option.configName,
          });
        } else {
          const optionValue = optionsSelected[optionName];
          if (optionValue !== '0') {
            const optionSubstring = value.padStart(charLen, '0');
            skuComponentsHolder.push({
              pos: pos,
              value: optionSubstring,
              hover: option.configName,
            });
          }
        }
      } else {
        // eslint-disable-next-line no-empty
        if (pos === 0 || pos === null) {
        } else if (presetOptions.some((obj) => Object.values(obj).includes(option.configName))) {
          skuComponentsHolder.push({
            pos: pos,
            value: value,
            hover: option?.configName,
          });
          // eslint-disable-next-line no-empty
        } else if (Object.keys(fullConfigOptions?.addOns).includes(option?.configName)) {
        } else {
          skuComponentsHolder.push({
            pos: pos,
            value: '_'.repeat(charLen),
            hover: option?.configName,
          });
        }
      }
    }
    setSkuComponents(skuComponentsHolder);
  }

  useEffect(() => {
    const sku = skuComponents
      .filter((i) => i.pos > 0 && i.pos !== null)
      .sort((a, b) => a.pos - b.pos)
      ?.reduce((result, item) => {
        const existingItemIndex = result.findIndex((existing) => existing.pos === item.pos);

        if (existingItemIndex !== -1) {
          if (item.value !== '__' && item.value !== '_') {
            result[existingItemIndex] = item;
          }
        } else {
          result.push(item);
        }
        return result;
      }, [])
      .map((i) => i.value)
      .join('');
    dispatch(updateProductInfo({ skuDisplayed: sku }));
  }, [skuComponents]);

  useEffect(() => {
    generateSKU();
  }, [optionsSelected, product]);

  return (
    <div className='sku-div'>
      <div
        style={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div className='sku-title'>SKU:</div>
        <div
          role='button'
          aria-label='sku'
          tabIndex={0}
          className={`sku sku-${skuStatus}`}
          onClick={() => {
            if (skuStatus === 'Completed') {
              setSkuStatus('Copied');
              clipboardCopy(skuDisplayed);
              clipboardCopy(skuDisplayed);
              setTimeout(() => {
                setSkuStatus('Completed');
              }, 2000);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (skuStatus === 'Completed') {
                setSkuStatus('Copied');
                clipboardCopy(skuDisplayed);
                clipboardCopy(skuDisplayed);
                setTimeout(() => {
                  setSkuStatus('Completed');
                }, 2000);
              }
            }
          }}
        >
          {skuComponents
            ?.filter((i) => i.pos > 0)
            .sort((a, b) => a.pos - b.pos)
            ?.reduce((result, item) => {
              const existingItemIndex = result.findIndex((existing) => existing.pos === item.pos);

              if (existingItemIndex !== -1) {
                if (item.value !== '__' && item.value !== '_') {
                  result[existingItemIndex] = item;
                }
              } else {
                result.push(item);
              }

              return result;
            }, [])

            ?.map((i) => (
              <SkuHover
                key={i.hover}
                skuComponent={i}
                skuStatus={skuStatus}
              />
            ))}
          {/* {skuStatus !== "Not Completed" ? skuDisplayed : ""} */}
        </div>
        {stageBuilder && <StageSkuMessage />}
      </div>
      <div
        style={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          className='sku-status'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          SKU {skuStatus}
          {/* {skuStatus === "Not Completed" ? (
            <div
              onMouseEnter={() => {
                setHover(true);
              }}
              onMouseLeave={() => {
                setHover(false);
              }}
              className="sku-info"

            >
              <HiInformationCircle className="clear-selections-x" />
             
              {hover ? (
                <div>
                  To complete SKU select the following:<br></br>
                  {Object.keys(skuComponents)
                    ?.filter((i) => skuComponents[i].value.includes("_"))
                    // ?.filter((i) => {
                    //   return !(
                    //     optional.includes(i) ||
                    //     Object.keys(optionsSelected).includes(i)
                    //   );
                    // })
                    ?.map((i) => {
                      return <div>{skuComponents[i].hover}</div>;
                    })}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )} */}
        </div>
        <div
          className='info-pop'
          style={{
            position: 'relative',
            // margin: "0 4px",
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            // top: "5.4rem",
            // left: "62rem",
          }}
        >
          <IoCloseCircleOutline
            className='clear-selections-x'
            onMouseEnter={() => {
              setHoverClear(true);
            }}
            onMouseLeave={() => {
              setHoverClear(false);
            }}
            onClick={() => {
              dispatch(changeProduct());
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                dispatch(changeProduct());
              }
            }}
          />
          {hoverClear ?
            <div
              style={{
                position: 'absolute',
                background: '#00435B',
                padding: '.5rem',
                fontSize: '10px',
                color: 'white',
                fontWeight: '300',
                textTransform: 'none',
                width: '125px',
                marginTop: '-4.5rem',
                marginLeft: '-3.5rem',
                borderRadius: '.5rem',
                boxShadow: '0 0 5px black',
                textAlign: 'center',
              }}
            >
              Clear All Selections
            </div>
          : ''}
        </div>
      </div>
      {/* {hoverClose ? <div>Clear Selections</div> : ""} */}
    </div>
  );
};

export default Sku;
