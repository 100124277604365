import { useEffect, useState } from 'react';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import EditableForm from './EditableForm';
const EditEnvList = () => {
  const [data, setData] = useState();
  const [notice, setNotice] = useState();

  const axiosJWT = useAxiosJWT();
  const columns = [
    {
      type: 'text',
      name: 'ID',
      searchAllowed: true,
      editable: false,
      table: 'environments',
      fieldName: 'ID',
      display: false,
    },
    {
      type: 'text',
      name: 'env_name',
      searchAllowed: true,
      editable: true,
      table: 'environments',
      fieldName: 'env_name',
      display: true,
    },
  ];
  useEffect(() => {
    if (notice) {
      const timer = setTimeout(() => {
        setNotice();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [notice]);

  function getData() {
    axiosJWT.get(`adminPortal/getEnvListRaw`).then((response) => {
      setData(response.data);
    });
  }

  async function saveHandler(values) {
    try {
      await axiosJWT.post(`adminPortal/updateData`, { values, columns });

      getData();

      setNotice('SAVED');
    } catch (err) {
      console.error(err);

      setNotice('ERROR SAVING');
    }
  }

  function addHandler(values) {
    axiosJWT
      .post(`adminPortal/addData`, {
        table: 'environments',
        keys: ['env_name'],
        values: [values.env_name],
      })
      .then(() => {
        getData();
        setNotice('DATA ADDED');
      })
      .catch(() => {
        setNotice('ERROR ADDING DATA');
      });
  }

  function removeHandler(id) {
    axiosJWT
      .post(`adminPortal/removeData`, {
        ID: id,
        table: 'environments',
      })
      .then(() => {});
  }

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <EditableForm
        title={'Environments List'}
        notice={notice}
        data={data}
        columns={columns}
        saveHandler={saveHandler}
        addHandler={addHandler}
        removeHandler={removeHandler}
      />
    </div>
  );
};

export default EditEnvList;
