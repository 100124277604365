import Axios from 'axios';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  findExposedSides,
  getAllowedDropCoordinates,
  getTotalBuildDims,
} from '../Components/Stage/StageFunctions';
import { exportPDF } from '../Components/Stage/StagePDF';
import { clearAndResetStateAction, updateProductInfo } from '../ReduxSlices/productState';

const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

const StageContext = createContext({});

export const StageProvider = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [ItemList, setItemList] = useState();
  const [buildStep, setBuildStep] = useState(1);
  const [warning, setWarning] = useState(false);
  const [notification, setNotification] = useState(false);
  const [configure, setConfigure] = useState();
  const [packagesData, setPackagesData] = useState();
  const [riserData, setRiserData] = useState();
  const [stageData, setStageData] = useState();
  const [packageShapes, setPackageShapes] = useState([]);
  const [packages, setPackages] = useState([]);
  const [stageBuild, setStageBuild] = useState([]);
  const [buildProducts, setBuildProducts] = useState([]);
  const [uniqueIdTracker, setUniqueIdTracker] = useState(0);
  const [hover, setHover] = useState();
  const [selected, setSelected] = useState();
  const [selectedPosition, setSelectedPosition] = useState({ top: 440, left: 1163 });
  const [dragging, setDragging] = useState();
  const [allSelected, setAllSelected] = useState(false);
  const [notConnected, setNotConnected] = useState([]);
  const [unstable, setUnstable] = useState([]);
  const [badConnections, setBadConnections] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [surfaceOptions, setSurfaceOptions] = useState();
  const [showProductList, setShowProductList] = useState(false);
  const [buildDims, setBuildDims] = useState();
  const [spaceArea, setSpaceArea] = useState();
  const [unitsInInches, setunitsInInches] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [areaDimensions, setAreaDimensions] = useState({ width: 648, length: 948 });
  const [showZoomBar, setShowZoomBar] = useState(false);
  const [zoom, setZoom] = useState(1.0);
  const [clickedZoomIn, setClickedZoomIn] = useState(false);
  const [maxAreaWidth, setMaxAreaWidth] = useState(696);
  const [maxAreaLength, setMaxAreaLength] = useState(1000);
  const [savedScrollPosition, setSavedScrollPosition] = useState(0);

  const [accessoryPage, setAccessoryPage] = useState(0);
  const [cartProducts, setCartProducts] = useState();
  const [surface, setSurface] = useState('Black Carpet');
  const [stepsData, setStepsData] = useState();
  const [steps, setSteps] = useState([]);
  const [stageGuardrailData, setStageGuardrailData] = useState();
  const [riserGuardrailData, setRiserGuardrailData] = useState();
  const [sideGuardrailData, setSideGuardrailData] = useState();
  const [guardrails, setGuardrails] = useState([]);
  const [skirtingData, setSkirtingData] = useState();
  const [selectedColor, setSelectedColor] = useState();
  const [selectedStyle, setSelectedStyle] = useState();
  const [skirting, setSkirting] = useState([]);
  const [stageDolliesData, setStageDolliesData] = useState();
  const [stageDollies, setStageDollies] = useState();
  const [riserDollies, setRiserDollies] = useState();
  const [capacity, setCapacity] = useState();
  const [onboardingSteps, setOnboardingSteps] = useState([]);
  const [showOnboarding, setShowOnboarding] = useState(
    !localStorage.getItem('stage-configurator-onboarding-1') ||
      !localStorage.getItem('stage-configurator-generate-custom')
  );

  const imageRef = useRef(null);
  const Series = [
    {
      id: 0,
      title: 'Add Stages',
      name: 'Fixed Height Stages',
    },
    {
      id: 1,
      title: 'Add Risers',
      name: 'Risers',
    },
    {
      id: 2,
      title: 'Prebuilt Packages',
      name: 'PrebuiltPackage',
    },
    {
      id: 3,
      title: 'Generate Custom Stage',
      name: 'GenerateCustom',
    },
  ];

  async function getStageData(product, productState, setProductState) {
    if (!productState) {
      Axios.post(`${apiAddress}/products/getProductData`, {
        sku: product,
      })
        .then((response) => {
          setProductState(response.data);
        })
        .catch((err) => console.error(err));
    }
  }

  function getPackagesData() {
    if (!packagesData) {
      Axios.get(`${apiAddress}/products/getPrebuiltStagePackages`)
        .then(async (response) => {
          setPackagesData(response.data);
          if (
            response.data.find((p) => p.items.find((i) => i.modelNum.includes('RS'))) ||
            response.data.find((p) => p.items.find((i) => i.modelNum.includes('RT')))
          ) {
            await Axios.post(`${apiAddress}/products/getProductData`, {
              sku: 'Risers',
            })
              .then((riserResponse) => {
                setRiserData(riserResponse.data.skuList);
                setSurfaceOptions(riserResponse.data.configOptions.finishes.Surface.Surface.values);
              })
              .catch((error) => {
                console.error('Error fetching riser data for stage package:', error);
              });
          }
          if (
            response.data.find((p) =>
              p.items.find((i) => i.modelNum.includes('S') && !i.modelNum.includes('R'))
            ) ||
            response.data.find((p) => p.items.find((i) => i.modelNum.includes('SP')))
          ) {
            await Axios.post(`${apiAddress}/products/getProductData`, {
              sku: 'Fixed Height Stages',
            })
              .then((stageResponse) => {
                setStageData(stageResponse.data.skuList);
                setSurfaceOptions(stageResponse.data.configOptions.finishes.Surface.Surface.values);
              })
              .catch((error) => {
                console.error('Error fetching stage data for stage package:', error);
              });
          }
          const shapesArray = [];
          response.data.forEach((p) => {
            if (
              !shapesArray.find((s) => s.shape === p.shape && s.capacityType === p.capacityType)
            ) {
              shapesArray.push({ shape: p.shape, capacityType: p.capacityType });
            }
          });
          setPackageShapes(shapesArray);
        })
        .catch((error) => {
          console.error('Error fetching stage package data:', error);
        });
    }
  }

  function getOnboardingSteps(onboardingName) {
    Axios.post(`${apiAddress}/content/getOnboardingSteps`, { onboardingName: onboardingName })
      .then((response) => {
        setOnboardingSteps(
          response.data
            .map((s) => {
              const mappedStep = { ...s, element: s.element };
              if (mappedStep.element === '') {
                delete mappedStep.element;
              }
              if (onboardingName === 'createStageBuild' && mappedStep.sort === 1) {
                mappedStep.options = { nextLabel: 'Check It Out!' };
              }
              return mappedStep;
            })
            .sort((a, b) => a.sort - b.sort)
        );
      })
      .catch((err) => console.error(err));
  }

  function handleConfigureItem(series, configOptions) {
    setSavedScrollPosition(window.scrollY);
    if (series === 'PrebuiltPackage' || series === 'GenerateCustom') {
      if (configure === series) {
        setConfigure();
      } else {
        setConfigure(series);
      }
    }
    //if click on open one
    else if (configure === series && !configOptions) {
      setConfigure();
      dispatch(clearAndResetStateAction({ stageBuilder: false, series: [] }));
    }
    //if click on closed one
    else if (!configure && !configOptions) {
      dispatch(updateProductInfo({ stageBuilder: 'newProduct', series: series }));
    }
    //if click on closed one when another is already open
    else if (configure !== series && !configOptions) {
      dispatch(clearAndResetStateAction({ stageBuilder: 'newProduct', series: series }));
    }
    //if open edit from the stage options
    else if (configOptions) {
      const optnsSelected = {};
      Object.keys(configOptions).forEach((option) => {
        if (configOptions[option].configName !== 'Surface') {
          optnsSelected[configOptions[option].configName] = configOptions[option].selectionName;
        }
      });
      //if edit an open one or if edit a closed one
      if (configure === series || !configure) {
        dispatch(
          updateProductInfo({
            stageBuilder: 'existingProduct',
            series: series,
            optionsSelected: optnsSelected,
          })
        );
      }
      //if edit a closed one when another is already open
      else if (configure !== series) {
        dispatch(
          clearAndResetStateAction({
            stageBuilder: 'existingProduct',
            series: series,
            optionsSelected: optnsSelected,
          })
        );
      }
    }
  }

  async function handleAddItem(item, productList) {
    item.productList = productList;
    setStageBuild([
      ...stageBuild,
      {
        ...item,
        uid: uniqueIdTracker,
        dimensions: getAllowedDropCoordinates(stageBuild, item),
      },
    ]);
    setSelected(uniqueIdTracker);
    setUniqueIdTracker(uniqueIdTracker + 1);
  }

  function handleDelete(selected) {
    setStageBuild((prevStageBuild) => prevStageBuild.filter((i) => i.uid !== selected));
    setSelected();
  }

  function handleDuplicate(selected) {
    const p = stageBuild.find((i) => i.uid === selected).productList;
    handleAddItem(
      ItemList.find((item) => p[0].modelNum.includes(item.modelNum)),
      p
    );
  }

  function handleSelectAll() {
    setAllSelected(!allSelected);
    setSelected();
  }

  function groupByProduct(productList) {
    const groupedByProduct = [];
    productList.forEach((item) => {
      let found = false;
      for (const p of groupedByProduct) {
        if (p.modelNum.includes(item.modelNum || item.product.modelNum)) {
          p.quantity += 1;
          found = true;
          break;
        }
      }
      if (!found) {
        groupedByProduct.push({
          ...item,
          product:
            item.product ||
            item?.productList?.find(
              (p) => p.configOptions.Surface.selectionName === 'Black Carpet'
            ),
          modelNum: item.modelNum || item.product?.modelNum,
          quantity: 1,
        });
      }
    });
    return groupedByProduct;
  }

  function resetStageStates() {
    setBuildStep(1);
    setStageBuild([]);
    setUniqueIdTracker(0);
    setSelected();
    setSelectedPosition({ top: 440, left: 1163 });
    setSpaceArea();
    setunitsInInches(false);
    setToggle(false);
    setAreaDimensions({ width: 648, length: 948 });
  }

  function setOnboardingNextStepState(nextIndex) {
    switch (nextIndex) {
      case 2:
        setConfigure();
        if (!areaDimensions.width || !areaDimensions.length) {
          setAreaDimensions({ width: 516, length: 840 });
        }
        setToggle(true);
        if (!stageBuild.length) {
          setStageBuild(
            packagesData
              .find((p) => p.id === 20)
              .items.map((i) => {
                return {
                  ...i,
                  productList: stageData?.filter((s) => s.modelNum.includes(i.modelNum)),
                };
              })
          );
        }
        return;
      case 5:
        setAllSelected(false);
        if (!selected) {
          //setSelected(stageBuild.find((i) => i.uid === 361) ? 361 : 0);
          setSelected(stageBuild[0]?.uid);
        }
        return;
    }
  }

  function resetZoom(build) {
    const buildDims = getTotalBuildDims(build);
    const length = buildDims?.right.x - buildDims?.left.x;
    let maxLength = maxAreaLength;
    let z = zoom;
    if (length > maxAreaLength) {
      do {
        z = parseFloat((z - 0.1).toFixed(1));
        maxLength = 1000 / z;
      } while (length > maxLength);
      setZoom(z);
    }
  }

  useEffect(() => {
    getPackagesData();
  }, []);

  useEffect(() => {
    if (packagesData) {
      const formattedPackagesData = [...packagesData];
      formattedPackagesData.forEach((p) => {
        p.items.map((i) => {
          i.productList =
            i.modelNum.includes('RS') || i.modelNum.includes('RT') ?
              riserData?.filter((r) => r.modelNum.includes(i.modelNum))
            : stageData?.filter((s) => s.modelNum.includes(i.modelNum));
        });
      });
      setPackages(formattedPackagesData);
    }
  }, [riserData, stageData]);

  useEffect(() => {
    window.scrollTo(0, savedScrollPosition);
  }, [location]);

  useEffect(() => {
    if (stageBuild.length) {
      setBuildDims(getTotalBuildDims(stageBuild));
    } else {
      setBuildDims();
    }
    setGuardrails(
      findExposedSides(stageBuild, true).filter(
        (side) =>
          stageBuild.find((i) => i.uid === side.itemUid)?.productList[0]?.configOptions.Height
            .value !== '8'
      )
    );
  }, [stageBuild]);

  useEffect(() => {
    // if (buildStep === 2) {
    //   if (accessoryPage > 0) {
    //     setShowOnboarding(true);
    //   }
    // }
    if (buildStep > 1) {
      setSelected();
      setAccessoryPage(0);
    } else if (buildStep === 1) {
      setCartProducts();
      setAccessoryPage(0);
      setSurface('Black Carpet');
      setSteps([]);
      setGuardrails(
        findExposedSides(stageBuild, true).filter(
          (side) =>
            stageBuild.find((i) => i.uid === side.itemUid)?.productList[0]?.configOptions.Height
              .value !== '8'
        )
      );
      //setSkirting(findExposedSides(stageBuild));
      setSkirting([]);
      setStageDollies();
      setSelectedColor();
      setSelectedStyle();
    }
    if (buildStep === 3) {
      setShowProductList();
    }
  }, [buildStep]);

  useEffect(() => {
    if (buildStep === 1) {
      if (
        !localStorage.getItem('stage-configurator-onboarding-1') ||
        (localStorage.getItem('stage-configurator-onboarding-1') &&
          localStorage.getItem('stage-configurator-generate-custom'))
      ) {
        setOnboardingSteps(getOnboardingSteps('createStageBuild'));
      } else if (!localStorage.getItem('stage-configurator-generate-custom')) {
        setConfigure('GenerateCustom');
      }
    } else if (buildStep === 2) {
      switch (accessoryPage) {
        case 0:
          setOnboardingSteps(getOnboardingSteps('stageSurface'));
          break;
        case 1:
          setOnboardingSteps(getOnboardingSteps('stageSteps'));
          break;
        case 2:
          setOnboardingSteps(getOnboardingSteps('stageGuardrails'));
          break;
        case 3:
          setOnboardingSteps(getOnboardingSteps('stageSkirting'));
          break;
        case 4:
          setOnboardingSteps(getOnboardingSteps('stageDollies'));
          break;
      }
    } else if (buildStep === 3 && accessoryPage === 0) {
      setOnboardingSteps(getOnboardingSteps('stageAddToCart'));
    } else {
      setOnboardingSteps([]);
    }
    if (buildStep === 2) {
      setShowOnboarding(
        !localStorage.getItem(`stage-configurator-onboarding-${buildStep}-${accessoryPage}`)
      );
    } else {
      setShowOnboarding(!localStorage.getItem(`stage-configurator-onboarding-${buildStep}`));
    }
  }, [buildStep, accessoryPage]);

  useEffect(() => {
    if (
      configure === 'GenerateCustom' &&
      !localStorage.getItem('stage-configurator-generate-custom') &&
      !showOnboarding
    ) {
      setShowOnboarding(true);
      setOnboardingSteps(getOnboardingSteps('generateCustomStage'));
    }
    if (document.getElementsByClassName('introjs-helperLayer')[0]) {
      if (configure) {
        document.getElementsByClassName('introjs-helperLayer')[0].style.height = '647px';
      } else {
        document.getElementsByClassName('introjs-helperLayer')[0].style.height = '256px';
      }
    }
  }, [configure]);
  useEffect(() => {
    if (document.getElementsByClassName('introjs-helperLayer')[0]) {
      if (!showProductList) {
        document.getElementsByClassName('introjs-helperLayer')[0].style.height = '72px';
        document.getElementsByClassName('introjs-helperLayer')[0].style.width = '74px';
        document.getElementsByClassName('introjs-helperLayer')[0].style.top = '925.609px';
        document.getElementsByClassName('introjs-helperLayer')[0].style.left = '1564.09px';
      } else {
        document.getElementsByClassName('introjs-helperLayer')[0].style.height = '698px';
        document.getElementsByClassName('introjs-helperLayer')[0].style.width = '352px';
        document.getElementsByClassName('introjs-helperLayer')[0].style.top = '19rem';
        document.getElementsByClassName('introjs-helperLayer')[0].style.left = '80.25rem';
      }
    }
  }, [showProductList]);

  useEffect(() => {
    setMaxAreaWidth(696 / zoom);
    setMaxAreaLength(1000 / zoom);
    setAreaDimensions({ ...areaDimensions });
  }, [zoom]);

  useEffect(() => {
    if (areaDimensions.width < 0) {
      setAreaDimensions({ ...areaDimensions, width: 0 });
    }
    if (areaDimensions.length < 0) {
      setAreaDimensions({ ...areaDimensions, length: 0 });
    }
    if (zoom > 0.6) {
      if (
        spaceArea &&
        clickedZoomIn &&
        (areaDimensions.width > maxAreaWidth || areaDimensions.length > maxAreaLength)
      ) {
        setNotification('Space area is too large to zoom in.');
      } else if (
        spaceArea &&
        (areaDimensions.width > maxAreaWidth || areaDimensions.length > maxAreaLength)
      ) {
        setZoom(Math.round((zoom - 0.1) * 10) / 10);
      }
    } else {
      if (areaDimensions.width > maxAreaWidth) {
        setAreaDimensions({ ...areaDimensions, width: maxAreaWidth });
      }
      if (areaDimensions.length > maxAreaLength) {
        setAreaDimensions({ ...areaDimensions, length: maxAreaLength });
      }
    }
    setClickedZoomIn(false);
  }, [areaDimensions, spaceArea]);

  useEffect(() => {
    let totalSeatedCapacity = 0;
    let totalSeatedBandCapacity = 0;
    let totalStandingCapacity = 0;
    stageBuild.forEach((i) => {
      switch (i.capacityType) {
        case 'Seated':
          totalSeatedCapacity = totalSeatedCapacity + i.capacity;
          break;
        case 'Seated Band':
          totalSeatedBandCapacity = totalSeatedBandCapacity + i.capacity;
          break;
        case 'Standing':
          totalStandingCapacity = totalStandingCapacity + i.capacity;
          break;
      }
    });
    setCapacity({
      Seated: totalSeatedCapacity,
      SeatedBand: totalSeatedBandCapacity,
      Standing: totalStandingCapacity,
    });
  }, [stageBuild]);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  return (
    <StageContext.Provider
      value={{
        Series,
        ItemList,
        setItemList,
        buildStep,
        setBuildStep,
        warning,
        setWarning,
        notification,
        configure,
        setConfigure,
        packagesData,
        setPackagesData,
        riserData,
        stageData,
        packageShapes,
        packages,
        stageBuild,
        setStageBuild,
        buildProducts,
        setBuildProducts,
        uniqueIdTracker,
        setUniqueIdTracker,
        hover,
        setHover,
        selected,
        setSelected,
        selectedPosition,
        setSelectedPosition,
        dragging,
        setDragging,
        allSelected,
        setAllSelected,
        notConnected,
        setNotConnected,
        unstable,
        setUnstable,
        badConnections,
        setBadConnections,
        openPopup,
        setOpenPopup,
        surfaceOptions,
        setSurfaceOptions,
        showProductList,
        setShowProductList,
        buildDims,
        setBuildDims,
        spaceArea,
        setSpaceArea,
        unitsInInches,
        setunitsInInches,
        toggle,
        setToggle,
        areaDimensions,
        setAreaDimensions,
        showZoomBar,
        setShowZoomBar,
        zoom,
        setZoom,
        setClickedZoomIn,
        maxAreaWidth,
        maxAreaLength,
        savedScrollPosition,
        setSavedScrollPosition,

        // accessories and cart states
        accessoryPage,
        setAccessoryPage,
        cartProducts,
        setCartProducts,
        surface,
        setSurface,
        stepsData,
        setStepsData,
        steps,
        setSteps,
        stageGuardrailData,
        setStageGuardrailData,
        riserGuardrailData,
        setRiserGuardrailData,
        sideGuardrailData,
        setSideGuardrailData,
        guardrails,
        setGuardrails,
        skirtingData,
        setSkirtingData,
        selectedColor,
        setSelectedColor,
        selectedStyle,
        setSelectedStyle,
        skirting,
        setSkirting,
        stageDolliesData,
        setStageDolliesData,
        stageDollies,
        setStageDollies,
        riserDollies,
        setRiserDollies,

        showOnboarding,
        setShowOnboarding,
        onboardingSteps,
        imageRef,
        capacity,
        // createBuildOnboarding,
        // addAccessoriesOnboarding,

        //functions
        getStageData,
        getPackagesData,
        handleConfigureItem,
        handleAddItem,
        handleDelete,
        handleDuplicate,
        handleSelectAll,
        groupByProduct,
        resetStageStates,
        exportPDF,
        setOnboardingNextStepState,
        resetZoom,
      }}
    >
      {children}
    </StageContext.Provider>
  );
};

export default StageContext;

export const useStageInfo = () => {
  return useContext(StageContext);
};
