import { IoWarning } from 'react-icons/io5';
import { useStageInfo } from '../../context/StageProvider';

function StageSkuMessage() {
  const { setHover } = useStageInfo();
  return (
    <div>
      <IoWarning
        onMouseEnter={(event) =>
          setHover({
            text: 'You will be able to choose your preferred surface color when you add the build to your cart.',
            color: '#C1D3D9',
            top: event.clientY,
            left: event.clientX + 10,
          })
        }
        onMouseLeave={() => setHover(false)}
        className='stage-sku-warning'
      />
    </div>
  );
}

export default StageSkuMessage;
