import Axios from 'axios';

const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

function GetColorInfo(colorName, finishesTypes, colorType, product) {
  const key = colorType === 'Premium Edge Color' ? 'Edge Color' : colorType;

  if (finishesTypes?.length <= 0 || !finishesTypes) {
    return fetchColorInfo();
  } else {
    for (let i = 0; i < finishesTypes[key]?.values?.length; i++) {
      if (finishesTypes[key].values[i]?.selectionName?.toUpperCase() === colorName?.toUpperCase()) {
        if (colorType === 'Premium Edge Color') {
          if (finishesTypes[key].values[i].Type === product?.configOptions.Edge.selectionName) {
            return finishesTypes[key].values[i];
          }
        } else {
          return finishesTypes[key].values[i];
        }
      }
    }
  }

  async function fetchColorInfo() {
    const response = await Axios.post(`${apiAddress}/products/getFullColorInfo`, {
      type: 'WoodLaminates',
      name: colorName,
    });
    return response.data[0];
  }
}

export default GetColorInfo;
