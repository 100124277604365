import Axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useGeneralImages from '../hooks/useGeneralImages';
import '../CSS/Terms.css';

const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

const Terms = () => {
  const { generalImages } = useGeneralImages();
  const termsIcon = generalImages.find((i) => i.publicId === 'termsIcon_c8wfhc')?.imageLink;
  const banner = generalImages.find((i) => i.publicId === 'terms_edepax')?.imageLink;
  const navigate = useNavigate();
  const [terms, setTerms] = useState();

  useEffect(() => {
    document.title = 'Terms - National Public Seating';
  }, []);

  function getTerms() {
    Axios.get(`${apiAddress}/content/getTerms`)
      .then((response) => {
        setTerms(response.data);
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    getTerms();
  }, []);

  return (
    <div>
      <div className='banner'>
        <img
          src={banner}
          alt='Terms and Conditions Banner'
        ></img>
      </div>

      <div className='terms inner-padding'>
        {terms?.map((i, index) => {
          return (
            <div
              className='term'
              key={index}
            >
              <h1>{i.title}</h1>
              <h2>{i.details}</h2>
            </div>
          );
        })}
      </div>
      <div className='portalNotice'>
        <img
          src={termsIcon}
          alt='Customer Support Icon'
        ></img>
        <h1>Need Help? Contact us through our Service Portal</h1>
        <button
          onClick={() => {
            navigate('/contact');
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              navigate('/contact');
            }
          }}
        >
          Go to Portal
        </button>
      </div>
      <div style={{ height: '23rem' }}></div>
    </div>
  );
};

export default Terms;
