import { useState } from 'react';
// import useCart from "../../hooks/useCart";
import '../../CSS/NBToggler.css';
import ToggleButton from '../FreightCart/ToggleButton';
// import { useCart } from "../../context/CartContext";
const NBToggle = ({
  sku,
  nbAvailable,
  isNBFromCart = false,
  updateCartOnChange = true,
  title = false,
  updateLocalVariable = false,
  className = false,
}) => {
  // const { updateNB } = useCart();
  // const [nbAvailable, setNBAvailable] = useState(nbAvailable);
  const [nb, setNB] = useState();
  // useEffect(() => {
  //   if (updateCartOnChange) {
  //     updateNB(nb, sku);
  //   }
  //   if (updateLocalVariable) {
  //     updateLocalVariable(nb);
  //   }
  // }, [nb]);

  // async function getNonBoxed() {
  //   const result = await nonBoxedCheck(sku);
  //   setNBAvailable(result);
  // }

  return (
    <>
      {nbAvailable ?
        <div className={className ? className : ''}>
          {title ? title : ''}
          <ToggleButton
            state={updateLocalVariable ? nb : isNBFromCart}
            setState={setNB}
            updateDatabase={updateCartOnChange}
            updateLocalVariable={updateLocalVariable}
            sku={sku}
          />
        </div>
      : ''}
    </>
  );
};

export default NBToggle;
