import { CgClose } from 'react-icons/cg';
import { useSelector } from 'react-redux';
import { useCart } from '../../context/CartContext';
import useAuth from '../../hooks/useAuth';

const CartPrice = ({
  errorMessage,
  requested,
  requestAccess,
  mobileCurrentPage,
  setMobileCurrentPage,
  setNotice,
}) => {
  const mobileScreen = useSelector((state) => state.isMobile.value.isMobile);
  const { cart, clearCart, notificationData } = useCart();
  const { auth } = useAuth();
  return (
    <>
      {mobileScreen && mobileCurrentPage === 'pageOne' && (
        <div className={mobileScreen ? 'help-section' : ''}>
          <div className='clear'>
            {cart?.length > 0 && (
              <button
                className='mobile-button'
                onClick={clearCart}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    clearCart();
                  }
                }}
              >
                <CgClose /> Clear All
              </button>
            )}
          </div>
          <button
            className='mobile-button'
            onClick={() => {
              if (cart?.length > 0) {
                setMobileCurrentPage('pageTwo');
              } else {
                setNotice('Cart Is Empty');
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (cart?.length > 0) {
                  setMobileCurrentPage('pageTwo');
                } else {
                  setNotice('Cart Is Empty');
                }
              }
            }}
          >
            Freight Quote
          </button>
        </div>
      )}
      {cart?.length > 0 && (
        <div className={`${mobileScreen ? 'access-div' : ''}`}>
          <div
            style={{
              justifyContent:
                (
                  auth?.accounts
                    ?.find((account) => account.last_login === 1)
                    ?.AccountRoles?.some((role) => [1100, 1200, 1300].includes(role)) ||
                  auth?.accounts?.length <= 0
                ) ?
                  'end'
                : 'space-between',
            }}
            className='clear-div'
          >
            {(
              auth?.accounts
                ?.find((account) => account.last_login === 1)
                ?.AccountRoles?.some((role) => [1100, 1200, 1300].includes(role))
            ) ?
              ''
            : (
              auth?.accounts?.length > 0 &&
              auth?.roles?.includes(3001) &&
              !notificationData?.some((i) => i.Path === 'pricing')
            ) ?
              requested ?
                <div className='clear-div-left'>
                  <h3 className='access-requested'>Access Requested</h3>
                </div>
              : errorMessage ?
                <div className='pricing-request'>
                  <h3 className='error-message'>{errorMessage}</h3>
                </div>
              : <div className='pricing-request'>
                  <h3 className='request-access-message'>Want to view item pricing?</h3>

                  <div
                    role='button'
                    aria-label='Request Price'
                    tabIndex={0}
                    style={{
                      background: 'none',
                      border: 'none',
                      padding: 0,
                      color: 'blue',
                      cursor: 'pointer',
                    }}
                    onClick={requestAccess}
                    className='request-access-link'
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        requestAccess();
                      }
                    }}
                  >
                    Request access
                  </div>
                </div>

            : ''}
            {!mobileScreen && (
              <button
                className='clear-button'
                onClick={clearCart}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    clearCart();
                  }
                }}
              >
                <CgClose /> Clear All
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CartPrice;
