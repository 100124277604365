import { useEffect, useState } from 'react';

const LaminatesInfoHover = () => {
  const [topUse, setTopUse] = useState();
  const [rightUse, setRightUse] = useState();
  useEffect(() => {
    setTopUse(document?.getElementById(`laminates-infopopup`)?.getBoundingClientRect().top + 20);
    setRightUse(
      document?.getElementById(`laminates-infopopup`)?.getBoundingClientRect().right - 20
    );
  }, []);

  return (
    <div
      style={{
        position: 'fixed',
        top: `${topUse}px`,
        left: `${rightUse}px`,
        zIndex: '7',
      }}
      className='info-popup'
    >
      May be subject to minimum quantity and upcharge
    </div>
  );
};

export default LaminatesInfoHover;
