import { useStageInfo } from '../../context/StageProvider';
import AddGuardrails from './AddGuardrails';
import AddSkirting from './AddSkirting';
import AddSteps from './AddSteps';
import SelectStageItems from './SelectStageItems';

function StageAddAccessories() {
  const { accessoryPage } = useStageInfo();
  return (
    accessoryPage === 0 ?
      <SelectStageItems
        selected={[]}
        setSelected={() => {}}
      />
    : accessoryPage === 1 ? <AddSteps />
    : accessoryPage === 2 ? <AddGuardrails />
    : accessoryPage === 3 ? <AddSkirting />
    : accessoryPage === 4 && (
        <SelectStageItems
          type='cart'
          selected={[]}
          setSelected={() => {}}
        />
      )
  );
}

export default StageAddAccessories;
