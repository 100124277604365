import { useEffect, useState } from 'react';
import { IoMdInformationCircle } from 'react-icons/io';
import { useSelector } from 'react-redux';

const InfoPopup = ({ message, color }) => {
  const [hover, setHover] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const mobileScreen = useSelector((state) => state.isMobile.value.isMobile);

  useEffect(() => {
    // Clear any existing timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (hover) {
      // Store the new timeoutId
      setTimeoutId(
        setTimeout(() => {
          setHover(false);
        }, 3500)
      );
    }
    return () => clearTimeout(timeoutId);
  }, [hover]);

  return (
    <div className='info-pop'>
      <IoMdInformationCircle
        onMouseEnter={() => {
          if (!mobileScreen) {
            setHover(true);
          }
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        onClick={() => {
          setHover((prevHover) => {
            return !prevHover;
          });
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setHover((prevHover) => {
              return !prevHover;
            });
          }
        }}
        style={{ color: color ? color : '' }}
      />
      {hover && <div className='hover-div'>{message}</div>}
    </div>
  );
};

export default InfoPopup;
