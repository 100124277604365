import { AdvancedImage } from '@cloudinary/react';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { useEffect, useState } from 'react';
import { BsCheckLg, BsCheckSquareFill } from 'react-icons/bs';
import axios from '../../api/axios';
import FormatImageLink from '../Shared/FormatImageLink';
const QuoteDetails = ({ order }) => {
  // const [open, setOpen] = useState(false);

  const decodeAndFormat = FormatImageLink();
  // const [insideDelivery, setInsideDelivery] = useState();
  // const [liftgate, setLiftgate] = useState();
  // const [limitedAccess, setLimitedAccess] = useState();
  // const [residentialDelivery, setResidentialDelivery] = useState();
  const [accesorials, setAccesorials] = useState();
  const [statusIcons, setStatusIcons] = useState();
  function getAccessorials() {
    // setInsideDelivery(
    //   order?.accesorials.substring(1, 2) === "Y" ? true : false
    // );
    // setLiftgate(order?.accesorials.substring(2, 3) === "Y" ? true : false);
    // setLimitedAccess(order?.accesorials.substring(3, 4) === "Y" ? true : false);
    // setResidentialDelivery(
    //   order?.accesorials.substring(4, 5) === "Y" ? true : false
    // );

    setAccesorials([
      {
        name: 'Inside Delivery',
        value: order?.accesorials.substring(1, 2) === 'Y' ? true : false,
      },
      {
        name: 'Lift Gate',
        value: order?.accesorials.substring(2, 3) === 'Y' ? true : false,
      },
      {
        name: 'Limited Access',
        value: order?.accesorials.substring(3, 4) === 'Y' ? true : false,
      },
      {
        name: 'Residential Delivery',
        value: order?.accesorials.substring(4, 5) === 'Y' ? true : false,
      },
    ]);
  }

  async function getStatusIcons() {
    await axios
      .get(`products/statusIcons`)
      .then((response) => {
        setStatusIcons(response.data);
      })
      .catch((error) => {
        console.error('Error fetching status icons:', error);
      });
  }
  useEffect(() => {
    const fetchData = async () => {
      await getAccessorials();
      await getStatusIcons();
    };

    fetchData();
  }, []);

  return (
    <div className={`po-details quote-details`}>
      <div>
        <div className='freight-options'>
          <h3>Freight Selections:</h3>
          {accesorials?.length ?
            accesorials
              ?.filter((i) => {
                return i.value;
              })
              .map((i, index) => {
                return (
                  <h4 key={index}>
                    <BsCheckLg /> {i.name}
                  </h4>
                );
              })
          : ''}
        </div>

        <div className='order-comment'>
          <div>{order.details}</div>
          <div
          // style={{ marginRight: "5rem" }}
          >
            ZIP: <span style={{ fontWeight: 'bold' }}>{order.zipcode}</span>
          </div>
          {order.refNum && order.refNum !== 'undefined' ?
            <div>
              Ref # <span style={{ fontWeight: 'bold' }}>{order.refNum}</span>
            </div>
          : ''}
        </div>
      </div>

      <div className={`po-details-list `}>
        <div className='row'>
          <div>IMAGE</div>
          <div>SKU</div>
          <div>DESCRIPTION</div>
          <div>NONBOXED</div>
          <div>QUANTITY</div>
          {/* <div>UNIT PRICE</div>
          <div>AMOUNT</div> */}
        </div>
        {order.items?.map((i, index) => {
          return (
            <div
              key={index}
              className='row'
            >
              <div
                style={{
                  height: '100px',
                  width: '100px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={i.imageLink}
                  style={{
                    maxHeight: '100%',
                    maxWidth: '100%',
                  }}
                  alt={i.displaySku}
                />
                {i.productStatus !== 'A' && statusIcons && (
                  <AdvancedImage
                    style={{
                      position: 'absolute',
                      marginTop: '-83px',
                      marginLeft: '-58px',
                    }}
                    cldImg={decodeAndFormat(
                      statusIcons?.find((j) => j.status === i.productStatus)?.icon_link
                    )?.resize(scale().width(35))}
                    alt='Product Status'
                  />
                )}
              </div>
              <div>{i.displaySku}</div>
              <div
                style={{
                  textAlign: 'center',
                  width: '20rem',
                  lineHeight: '26px',
                }}
              >
                {i.description}
              </div>
              <div>
                {i.nonboxed === 'Y' ?
                  <BsCheckSquareFill style={{ height: '1.5rem', width: '1.5rem' }} />
                : ''}
              </div>
              <div>{i.qty}</div>
              {/* <div>${i.unitPrice}</div>
              <div>${i.unitPrice * i.qty}</div> */}
            </div>
          );
        })}
        {/* <div>Total: {order.totalCount}</div> */}
      </div>
    </div>
  );
};

export default QuoteDetails;
