import React, { useState } from 'react';
import { FaFileUpload, FaRegImage } from 'react-icons/fa';
import { IoCloseCircle } from 'react-icons/io5';
import '../../CSS/UploadFiles.css';
const UploadFiles = ({ files, setFiles }) => {
  const [dragActive, setDragActive] = useState(false);
  const hiddenFileInput = React.useRef(null);

  function handleClick() {
    hiddenFileInput.current.click();
  }
  const handleChange = (event) => {
    var holderArray = Array.from(event.target.files);
    setFiles([...files, ...holderArray]);
  };
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      var holderArray = Array.from(e.dataTransfer.files);
      setFiles([...files, ...holderArray]);
    }
  };

  return (
    <div className='file-upload'>
      <div
        role='button'
        aria-label='Upload a File'
        tabIndex={0}
        className='selectorDiv'
        onClick={handleClick}
        onDragEnter={handleDrag}
        onDrop={handleDrop}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleClick();
          }
        }}
      >
        <FaFileUpload className='file-svg' />
        <div className='title'>
          Drop a file here to upload, or <span className='link'>click here to browse</span>
          <input
            type='file'
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: 'none' }}
            multiple={true}
          />
        </div>
      </div>
      <div className='files'>
        {files?.length ?
          files?.map((i, index) => {
            return (
              <div
                className='file'
                key={index}
              >
                <FaRegImage />
                <h4>{i.name}</h4>
                <IoCloseCircle
                  onClick={() => {
                    setFiles(
                      files?.filter((j) => {
                        return j.name !== i.name;
                      })
                    );
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setFiles(
                        files?.filter((j) => {
                          return j.name !== i.name;
                        })
                      );
                    }
                  }}
                  className='image-remove'
                />
              </div>
            );
          })
        : ''}
      </div>
      {dragActive && (
        <div
          id='drag-file-element'
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </div>
  );
};

export default UploadFiles;
