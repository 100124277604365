import useAxiosJWT from '../hooks/useAxiosJWT';
import useAuth from './useAuth';

const useChangeAccount = () => {
  const axiosJWT = useAxiosJWT();
  const { auth } = useAuth();

  async function changeAccount(accountNum) {
    await axiosJWT
      .post(`account/changeAccount`, {
        userID: auth?.accounts[0].user_ID,
        accountNum: accountNum,
      })
      .then(() => {
        if (window.location.pathname === '/unauthorized') {
          window.location = document.referrer;
        } else {
          window.location.reload();
        }
      });
  }

  return changeAccount;
};

export default useChangeAccount;
