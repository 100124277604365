import { useState } from 'react';
import { useSelector } from 'react-redux';
import Options from '../../Components/FreightCart/Options.jsx';
import { useCart } from '../../context/CartContext';
import CartQuantity from '../FreightCart/CartQuantity';
import NBToggle from '../Shared/NBToggle';
const InstantFreightInput = ({
  insideDelivery,
  setInsideDelivery,
  liftgate,
  setLiftgate,
  limitedAccess,
  setLimitedAccess,
  residentialDelivery,
  setResidentialDelivery,
  qty,
  setQty,
  zipCode,
  setZipCode,
  clickHandler,
  setFreightShow,
  nonboxed,
  setNonboxed,
  setNotification,
}) => {
  const {
    product: modelNum,
    optionsSelected,
    skuDisplayed,
    customerPrice,
  } = useSelector((state) => state.productState.value);
  const { addToCart } = useCart();
  const [showError, setShowError] = useState(false);

  return (
    <div className='form'>
      <div className='row1'>
        <div className='skuTitle'>
          <div>SKU:&nbsp; </div>

          <div style={{ color: '#C0E66C' }}> {modelNum?.productInfo?.FreightQuoteModelNum}</div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className='quantityTitle'>Quantity</div>
          <CartQuantity
            placeholder={'0'}
            quantity={qty}
            updateQty={setQty}
          />
        </div>
      </div>
      <div className='row2'>
        Please enter the Zip Code:
        <input
          placeholder='ZIP CODE'
          value={zipCode}
          onChange={(e) => {
            setZipCode(e.target.value);
          }}
        ></input>
        <div
          className='error-msg-text'
          style={{
            height: '17px',
            marginTop: '3rem',
            marginBottom: '0',
            color: '#c80e1a',
            fontSize: '12px',
            marginLeft: '0',
            position: 'absolute',
          }}
        >
          {showError ? '**Zip code is required**' : ''}
        </div>
        <NBToggle
          sku={modelNum.productInfo.FreightQuoteModelNum}
          nbAvailable={modelNum.productInfo.ShipsNonBoxed === 'Y'}
          title={'Ship Nonboxed:'}
          className={'instant-freight-toggle'}
          updateLocalVariable={setNonboxed}
          updateCartOnChange={false}
        />
      </div>
      <div className='row3'>
        <Options
          insideDelivery={insideDelivery}
          setInsideDelivery={setInsideDelivery}
          liftgate={liftgate}
          setLiftgate={setLiftgate}
          limitedAccess={limitedAccess}
          setLimitedAccess={setLimitedAccess}
          residentialDelivery={residentialDelivery}
          setResidentialDelivery={setResidentialDelivery}
          instantFreight={true}
        />
      </div>
      <div
        className='row4'
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* <div
              onClick={() => {
                setAllItems(!allItems);
              }}
              className="selection-box"
              style={{
                background: allItems ? "#004D44" : "white",
                borderColor: allItems ? "#004D44" : "#939393",
              }}
            >
              {allItems ? <BsCheckLg style={{ color: "white" }} /> : ""}
            </div> */}
          <button
            className='calculate'
            onClick={() => {
              const response = addToCart(
                modelNum.productInfo.FreightQuoteModelNum,
                qty,
                skuDisplayed,
                modelNum.productInfo.ShortDescription,
                modelNum.cdnLinks[0]?.Image,
                optionsSelected,
                modelNum.productInfo.BaseModelNum,
                parseInt(modelNum.productInfo.Quantity) * parseInt(qty),
                parseInt(modelNum.productInfo.Quantity),
                modelNum.productInfo.ShipsNonBoxed,
                nonboxed,
                modelNum.productInfo.Status,
                Object.keys(customerPrice).length > 0 ? customerPrice : false
              );
              if (response?.data?.error) {
                setNotification(response.data.error);
              } else {
                setFreightShow(false);
                window.open('/cart', '_blank');
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                const response = addToCart(
                  modelNum.productInfo.FreightQuoteModelNum,
                  qty,
                  skuDisplayed,
                  modelNum.productInfo.ShortDescription,
                  modelNum.cdnLinks[0]?.Image,
                  optionsSelected,
                  modelNum.productInfo.BaseModelNum,
                  parseInt(modelNum.productInfo.Quantity) * parseInt(qty),
                  parseInt(modelNum.productInfo.Quantity),
                  modelNum.productInfo.ShipsNonBoxed,
                  nonboxed,
                  modelNum.productInfo.Status,
                  Object.keys(customerPrice).length > 0 ? customerPrice : false
                );
                if (response?.data?.error) {
                  setNotification(response.data.error);
                } else {
                  setFreightShow(false);
                  window.open('/cart', '_blank');
                }
              }
            }}
          >
            Add to Cart
          </button>
        </div>
        <button
          className='calculate'
          onClick={() => {
            if (zipCode) {
              clickHandler();
            } else {
              setShowError(true);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (zipCode) {
                clickHandler();
              } else {
                setShowError(true);
              }
            }
          }}
        >
          Calculate Freight Quote
        </button>
      </div>
    </div>
  );
};

export default InstantFreightInput;
