import clipboardCopy from 'clipboard-copy';
import { useEffect, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import OrderDetails from './OrderDetails';
const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;
const OrderTracking = ({ order, open, setOpenItem, orderStatusIcons, account, link }) => {
  const [myItems, setMyItems] = useState();
  const [notification, setNotification] = useState();
  const [linkGood, setLinkGood] = useState(true);
  const axiosJWT = useAxiosJWT();
  var trackingNum = order?.trackingNum?.toLowerCase();
  const carrierCode = order?.carrierCode?.toLowerCase();

  function trackingLinkClick() {
    switch (carrierCode) {
      case 'dylt':
      case 'ef8':
        trackingNum = trackingNum.replace(/%/g, '').replace(/[^\d]/g, '');
        break;
      case 'mfgn':
        trackingNum = trackingNum.replace('ship', '');
        break;
      case 'ef60':
      case 'risf':
        trackingNum = trackingNum.replace('lb', '');
        break;
      case 'paaf':
        trackingNum = trackingNum.replace(/[a-zA-Z]+$/g, '');
        break;
      default:
        break;
    }
    if (link?.copy === 1) {
      clipboardCopy(`${trackingNum}`);
      setNotification('Tracking Number Copied');
    } else {
      window.open(`${link?.tracking_url}${trackingNum}${link?.url_suffix}`);
    }
  }

  const getOrderItems = async () => {
    await axiosJWT
      .post(`${apiAddress}/orders/orderItems`, {
        orderNum: order.orderNumOriginal,
        orderNumPart: order.orderNumPart,
        account: account,
      })
      .then((response) => {
        setMyItems(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    const isLinkValid = () => {
      if (!trackingNum || !link || trackingNum === 'not pro' || /\//.test(trackingNum)) {
        return false;
      }
      if (carrierCode === 'ef69' && /[A-Za-z]/.test(trackingNum)) {
        return false;
      }
      if (carrierCode === 'paaf' && /^[a-zA-Z]/.test(trackingNum)) {
        return false;
      }
      return true;
    };
    setLinkGood(isLinkValid());
  }, [order]);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification();
      }, 1500);
      return () => {
        clearTimeout(timer);
        window.open(`${link?.tracking_url}${link?.url_suffix}`);
      };
    }
  }, [notification]);

  return (
    <div
      style={{ width: '1230px' }}
      className={`order ${open === order.orderNum ? 'open-order' : 'closed-order'}`}
    >
      <div
        role='button'
        aria-label={`Open Order Number ${order.orderNum}`}
        tabIndex={0}
        style={{ width: '100%' }}
        className={`order-header`}
        onClick={() =>
          open === order.orderNum ? setOpenItem() : (setOpenItem(order.orderNum), getOrderItems())
        }
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            open === order.orderNum ?
              setOpenItem()
            : (setOpenItem(order.orderNum), getOrderItems());
          }
        }}
      >
        <div>
          PO#
          <span
            style={{ wordWrap: 'break-word' }}
            className='orderNum'
          >
            {order.poNum}
          </span>
        </div>
        <div>
          Order# <span className='orderNum'>{order.orderNum}</span>
        </div>
        <div>
          Date: <span className='orderDate'>{order.orderDate}</span>
        </div>
        <div>
          Ship Date: <span className='orderDate'>{order.shipDate}</span>
        </div>
        <div
          className={`status ${
            order?.invoiceNum &&
            orderStatusIcons?.find((j) => j.code === order?.invoiceNum)?.status.replace(/ /g, '-')
          }`}
        >
          {order?.invoiceNum && orderStatusIcons?.find((j) => j.code === order?.invoiceNum)?.status}
        </div>
        {open === order.orderNum ?
          <FiChevronUp
            className='open'
            onClick={() => {
              setOpenItem();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setOpenItem();
              }
            }}
          />
        : <FiChevronDown
            className='open'
            onClick={() => {
              setOpenItem(order.orderNum);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setOpenItem(order.orderNum);
              }
            }}
          />
        }
      </div>
      {open === order.orderNum ?
        <div
          style={{ width: '1227px' }}
          className='order-details'
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div className='over-view'>
              <div className='carrier-info'>
                <div style={{ display: 'flex' }}>
                  <div className='carrierTitle'>Carrier:</div>
                  <div className='carrier'>{order.carrier}</div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div className='trackingTitle'>Tracking #: </div>
                  <div
                    role='button'
                    aria-label={`Track Order`}
                    tabIndex={0}
                    className={`tracking ${linkGood ? 'has-tracking' : ''}`}
                    onClick={() => {
                      linkGood && trackingLinkClick();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        linkGood && trackingLinkClick();
                      }
                    }}
                  >
                    {order.trackingNum || 'Unavailable'}
                  </div>
                </div>
              </div>
              <div
                className='carrier-info'
                style={{ marginRight: '0' }}
              >
                <div className='carrierTitle'>Destination:</div>
              </div>
              <div className='carrier-info'>
                <div className='carrier'>
                  {order?.shipName?.toUpperCase()}
                  <br></br>
                  {order?.address?.lineOne?.toUpperCase()}
                  <br></br>
                  {order?.address?.lineThree?.toUpperCase()}
                  {order?.address?.lineThree ?
                    <br></br>
                  : ''}
                  {order?.city?.toUpperCase()}, {order?.state} {order?.zipCode}
                </div>
              </div>
            </div>
          </div>
          <OrderDetails items={myItems} />
        </div>
      : ''}
      {notification ?
        <div
          className='notice'
          style={{
            border: '2px solid white',
            left: '40%',
          }}
        >
          {notification}
        </div>
      : ''}
    </div>
  );
};

export default OrderTracking;
