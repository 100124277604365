import { useEffect, useState } from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { useCart } from '../../context/CartContext';
import CartQuantity from './CartQuantity';

function SkuItem({ item }) {
  const { updateQty, removeFromCart } = useCart();
  const [quantity, setQuantity] = useState(item?.qty || 1);

  useEffect(() => {
    setQuantity(item?.qty || 1);
  }, [item.qty]);

  return (
    <>
      <div className='return-sku'>
        <div className='sku-label'>Sku: </div>
        <div className='sku-value'>{item.displaySku}</div>
      </div>
      <div className='sku-controls'>
        <div className='cart-quanity-cont'>
          <CartQuantity
            quantity={item?.qty}
            displaySku={item?.displaySku}
            uom={item.uom}
            updateQty={updateQty}
            quantityState={quantity}
            setQuantityState={setQuantity}
          />
        </div>
        <div
          role='button'
          aria-label='Remove From Cart'
          tabIndex={0}
          className='remove-from-cart'
          onClick={() => {
            removeFromCart(item.displaySku);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              removeFromCart(item.displaySku);
            }
          }}
        >
          <IoIosCloseCircleOutline />
        </div>
      </div>
    </>
  );
}

export default SkuItem;
