import Axios from 'axios';
import { useState } from 'react';
import { useStageInfo } from '../../context/StageProvider';
import SelectStageItems from './SelectStageItems';

const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

function SaveStageBuild() {
  const { stageBuild, setOpenPopup, buildDims } = useStageInfo();
  const [buildName, setBuildName] = useState('');
  const [code, setCode] = useState('');
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [middle, setMiddle] = useState([]);
  const [shape, setShape] = useState('');
  const shapeOptions = [
    { id: 0, name: 'Rectangle' },
    { id: 1, name: 'Rectangle Arch' },
    { id: 2, name: 'Rounded Arch' },
    { id: 3, name: 'Bow' },
    { id: 4, name: 'Ring' },
    { id: 5, name: 'Oval' },
  ];

  function handleSave() {
    Axios.post(`${apiAddress}/products/saveStageBuild`, {
      name: buildName,
      code: code,
      length: length || parseInt(buildDims?.right.x - buildDims?.left.x),
      width: width || parseInt(buildDims?.bottom.y - buildDims?.top.y),
      itemWidth: getItemWidth(),
      capacity: stageBuild.reduce((sum, item) => sum + item.capacity, 0),
      build: stageBuild,
      middleRow: middle,
      shape: shape,
    }).catch((error) => {
      console.error('Error saving stage build data:', error);
    });
    setOpenPopup(false);
  }

  function getItemWidth() {
    switch (stageBuild[0].capacityType) {
      case 'Seated Band':
        return 48;
      case 'Seated':
        return 36;
      case 'Standing':
        return 18;
    }
  }

  return (
    <div className='save-build-popup'>
      <h2>Save Stage Build</h2>
      <input
        type='text'
        className='save-build-name'
        placeholder='Name Your Build'
        value={buildName}
        onChange={(e) => setBuildName(e.target.value)}
      ></input>
      <div className='save-build-inputs'>
        <input
          type='text'
          placeholder='code *'
          value={code}
          onChange={(e) => setCode(e.target.value)}
        ></input>
        <input
          type='number'
          placeholder='length'
          value={length}
          onChange={(e) => setLength(e.target.value)}
        ></input>
        <input
          type='number'
          placeholder='width'
          value={width}
          onChange={(e) => setWidth(e.target.value)}
        ></input>
      </div>
      <h4>Select Build Shape:</h4>
      <div className='select-build-shape'>
        {shapeOptions.map((optn) => (
          <span
            role='button'
            aria-label={optn.name}
            tabIndex={0}
            key={optn.id}
            onClick={() => setShape(optn.name)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setShape(optn.name);
              }
            }}
          >
            <input
              type='checkbox'
              className='checkbox'
              checked={shape === optn.name}
            ></input>
            <span>{optn.name}</span>
          </span>
        ))}
      </div>
      <h4>Select Items in Middle Row:</h4>
      <SelectStageItems
        type='middle'
        selected={middle}
        setSelected={setMiddle}
      />
      {/* <button>Create Link</button> */}
      <button
        onClick={handleSave}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSave();
          }
        }}
        className='submit save-build'
      >
        Save
      </button>
    </div>
  );
}

export default SaveStageBuild;
