import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStageInfo } from '../../context/StageProvider';
import useGeneralImages from '../../hooks/useGeneralImages';

function AddDollies() {
  const { generalImages } = useGeneralImages();
  const {
    stageBuild,
    stageDolliesData,
    setStageDolliesData,
    stageDollies,
    setStageDollies,
    getStageData,
  } = useStageInfo();

  useEffect(() => {
    getStageData('Stage Dollies', stageDolliesData, setStageDolliesData);
  }, []);

  useEffect(() => {
    if (stageDolliesData) {
      let stageCount = 0;
      // let riserCount = 0;
      stageBuild.forEach((item) => {
        if (item.productList[0].productInfo.Series === 'Fixed Height Stages') {
          stageCount++;
        }
        // else {
        //   riserCount++;
        // }
      });
      if (stageCount) {
        setStageDollies({
          modelNum: stageDolliesData.skuList[0].modelNum,
          product: stageDolliesData.skuList[0],
          quantity: Math.ceil(stageCount / 6),
        });
      }
      // if (riserCount) {
      //   setRiserDollies({
      //     modelNum: riserDolliesData.skuList[0].modelNum,
      //     product: riserDolliesData.skuList[0],
      //     quantity: Math.ceil(riserCount / 10),
      //   });
      // }
    }
  }, [stageDolliesData]);

  return (
    <>
      <h2>Dollies</h2>
      <div>
        <span>
          The stage dollies let you store and move up to six stages. The quantity of dollies is
          calculated automatically based on the number of stages in your build. However, you can
          adjust the quantity of dollies as needed.
          <div className='riser-dolly-note'>Note: Riser dollies are coming soon.</div>
        </span>
      </div>
      <img
        width='185px'
        height='220px'
        src={generalImages.find((i) => i.publicId === 'SDL_01_biwtp2')?.imageLink}
        alt='stage dolly'
      />
      <span className='dollies-qty'>
        <div
          className='minus'
          role='button'
          aria-label='minus'
          tabIndex={0}
          onClick={() => {
            if (stageDollies?.quantity > 1) {
              setStageDollies({ ...stageDollies, quantity: stageDollies.quantity - 1 });
            } else {
              setStageDollies();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (stageDollies?.quantity > 1) {
                setStageDollies({ ...stageDollies, quantity: stageDollies.quantity - 1 });
              } else {
                setStageDollies();
              }
            }
          }}
        >
          -
        </div>
        <div className='quantity-input'>
          <input
            type='integer'
            name='quantity'
            placeholder='Quantity'
            id='quantity'
            value={stageDollies?.quantity || 0}
            min={0}
            onChange={(e) => {
              if (parseInt(e.target.value) > 0) {
                setStageDollies({
                  modelNum: stageDolliesData.skuList[0].modelNum,
                  product: stageDolliesData.skuList[0],
                  quantity: parseInt(e.target.value),
                });
              } else {
                setStageDollies();
              }
            }}
          ></input>
        </div>
        <div
          className='plus'
          role='button'
          aria-label='plus'
          tabIndex={0}
          onClick={() => {
            if (stageDollies) {
              setStageDollies({ ...stageDollies, quantity: stageDollies.quantity + 1 });
            } else {
              setStageDollies({
                modelNum: stageDolliesData.skuList[0].modelNum,
                product: stageDolliesData.skuList[0],
                quantity: 1,
              });
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (stageDollies) {
                setStageDollies({ ...stageDollies, quantity: stageDollies.quantity + 1 });
              } else {
                setStageDollies({
                  modelNum: stageDolliesData.skuList[0].modelNum,
                  product: stageDolliesData.skuList[0],
                  quantity: 1,
                });
              }
            }
          }}
        >
          +
        </div>
      </span>
      <span className='accessory-product-link'>
        Learn more about this product by
        <Link
          to={{
            pathname: `/products/Stage Dollies`,
          }}
          target='_blank'
          rel='noopener noreferrer'
        >
          clicking here
        </Link>
        .
      </span>
    </>
  );
}

export default AddDollies;
