import { Link } from 'react-router-dom';
import CallUsButton from '../../assets/SVG/ReactSvg/CallUsButton';
import CartButton from '../../assets/SVG/ReactSvg/CartButton';
import GenericTextButton from '../../assets/SVG/ReactSvg/genericTextButton';
import LoginButton from '../../assets/SVG/ReactSvg/LoginButton';
import '../../CSS/HeaderNav.css';
import '../../CSS/MobileApp/M_HeaderNav.css';
import useAuth from '../../hooks/useAuth';
import useUserInfo from '../../hooks/useUserInfo';
import DealerLogin from '../Login/DealerLogin';
// import LoginButtonBlue from "../../assets/SVG/ReactSvg/LoginButtonBlue";
// import { useEffect } from "react";
import { useEffect, useState } from 'react';
import useLogout from '../../hooks/useLogout';
// import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { IconContext } from 'react-icons';
import { SlMenu } from 'react-icons/sl';
import { useSelector } from 'react-redux';
import MobileDropMenu from '../Dropdown/MobileDropMenu';
//import MobileMenu from "../Dropdown/MobileMenu";
import { FiLogOut } from 'react-icons/fi';
import { HiUsers } from 'react-icons/hi2';
import useChangeAccount from '../../hooks/changeAccount';
import MobileUserDrop from '../Dropdown/MobileUserDrop';

const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

const Header = () => {
  const { auth } = useAuth();
  const logout = useLogout();

  // eslint-disable-next-line no-unused-vars
  const { displayBoolean, displayLoginBox } = useUserInfo();
  const [loginHover, setLoginHover] = useState(false);
  const [accountHover, setAccountHover] = useState(false);
  // const navigate = useNavigate();
  const [displayUserProfile, setDisplayUserProfile] = useState(false);
  const mobileScreen = useSelector((state) => state.isMobile.value);
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [globalMenuData, setGlobalMenuData] = useState([]);
  const [fixList, setFixList] = useState(true);
  const changeAccount = useChangeAccount();

  useEffect(() => {
    getData();
    getCategories();
  }, []);
  function getData() {
    Axios.post(`${apiAddress}/content/getDropDownTabs`, {}).then((response) => {
      setGlobalMenuData([
        {
          title: '',
          sublinks: response.data.dropdown,
          secondarySublinks: [
            { title: 'Freight Cart', link: 'cart', special: 'quick-link' },
            {
              title: 'Order Tracking',
              link: 'track-order',
              special: 'quick-link',
            },
            {
              title: 'Digital Catalog',
              link: 'catalog',
              special: 'quick-link',
            },
          ],
        },
      ]);
    });
  }
  function getCategories() {
    Axios.get(`${apiAddress}/products/getCategories`).then((response) => {
      setProductsList(response.data.productList);
    });
  }

  const signOut = async () => {
    if (mobileScreen.isMobile) {
      setDisplayUserProfile(true);
    } else {
      await logout();
    }
  };
  const openDropdownMenu = () => {
    setShowDropdownMenu(true);
  };

  return (
    <div className='padding'>
      {mobileScreen.isMobile ?
        <div className='mobile-banner'>
          <h4>Call Us Today | 800-261-4112</h4>
        </div>
      : ''}

      <header className='App-header'>
        <IconContext.Provider
          value={{
            color: 'rgb(1,66,90',
            size: '5em',
            className: 'dropdown-menu-icon-context',
          }}
        >
          <div
            role='button'
            aria-label='Dropdown Menu Icon'
            className='dropdown-menu-icon'
            tabIndex={0}
            onClick={openDropdownMenu}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                openDropdownMenu();
              }
            }}
          >
            <SlMenu />
          </div>
        </IconContext.Provider>
        <div className='logo'>
          <Link
            aria-label='Go to National Public Seating homepage'
            to='/'
          ></Link>
        </div>
        <div className='header-buttons'>
          {auth?.roles?.includes(5150) && (
            <GenericTextButton
              buttonText='Admin'
              buttonLink='/admin'
            />
          )}
          {auth?.roles?.includes(1984) && (
            <GenericTextButton
              buttonText='Editor'
              buttonLink='/edit'
            />
          )}
          {auth?.roles?.includes(3500) && (
            <GenericTextButton
              buttonText='Employee Portal'
              buttonLink='/employee-portal'
            />
          )}
          {/* <div className="callUsButton"></div> */}
          <CallUsButton className='callUsButton' />
          {/* {auth?.rep ? (
            <button onClick={signOut} className="callUsButton">
              Hi {auth?.rep.substring(0, auth?.rep.indexOf(" "))}! Logout
            </button>
          ) : displayBoolean ? (
            <LoginButtonBlue className="callUsButton" />
          ) : ( */}
          <div>
            <div
              onMouseEnter={() => {
                setLoginHover(true);
              }}
              onMouseLeave={() => {
                setLoginHover(false);
                setAccountHover(false);
              }}
            >
              <LoginButton
                className='callUsButton'
                displayLoginBox={displayLoginBox}
                loggedIn={auth?.rep}
                name={auth?.rep?.substring(0, auth?.rep?.indexOf(' ')) || auth?.rep}
                logOut={logout}
              />

              {auth?.rep && loginHover && auth?.accounts?.length > 0 && (
                <div className={loginHover ? 'account-dropdown active' : 'account-dropdown'}>
                  {auth?.accounts?.length > 1 && (
                    <div
                      role='button'
                      aria-label='My Accounts'
                      tabIndex={0}
                      onClick={() => setAccountHover(!accountHover)}
                      className='account-dropdown-list'
                      style={{
                        color: accountHover ? 'var(--darkblue)' : '',
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setAccountHover(!accountHover);
                        }
                      }}
                    >
                      <HiUsers />
                      <div
                        style={{
                          marginLeft: '7px',
                        }}
                      >
                        My Accounts
                      </div>
                    </div>
                  )}
                  {accountHover && (
                    <div
                      className={accountHover ? 'subcat-accounts-div show' : 'subcat-accounts-div'}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {auth?.accounts
                        ?.filter((i) => i.last_login !== 1)
                        .map((i, index) => (
                          <div
                            role='button'
                            aria-label={`Account #${i.account_num}`}
                            tabIndex={0}
                            key={index}
                            onClick={() => changeAccount(i.account_num)}
                            className='account'
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                changeAccount(i.account_num);
                              }
                            }}
                          >
                            {'Account #'}
                            {i.account_num}
                          </div>
                        ))}
                    </div>
                  )}

                  {/* {auth?.accounts
                    ?.find((i) => i.last_login === "true")
                    ?.AccountRoles?.some((role) =>
                      [1100, 1200].includes(role)
                    ) && (
                    <div className="account-dropdown-list">
                      <HiUsers />
                      <a
                        onClick={() => {
                          navigate(`/account-users`);
                        }}
                        style={{ marginLeft: "7px" }}
                      >
                        My Users
                      </a>
                    </div>
                  )} */}
                  <div
                    role='button'
                    aria-label='Log Out'
                    tabIndex={0}
                    onClick={() => {
                      signOut();
                      setLoginHover(false);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        signOut();
                        setLoginHover(false);
                      }
                    }}
                    className='account-dropdown-list'
                  >
                    <FiLogOut style={{ width: '1.1rem', height: '1.1rem' }} />
                    <div
                      className='log-out'
                      style={{ marginLeft: '7px' }}
                    >
                      Log Out
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* )} */}
          {/* <div className="dealerLoginButton" onClick={displayLoginBox}></div> */}
          <CartButton />
          {/* <div className="cartButton" onClick={addToCart}> */}
          {/* <div className="cartCount">{cartCount}</div> */}
          {/* </div> */}
        </div>
      </header>
      <DealerLogin />
      {displayUserProfile && (
        <MobileUserDrop
          setDisplayUserProfile={setDisplayUserProfile}
          auth={auth}
        />
      )}
      {
        showDropdownMenu ?
          <MobileDropMenu
            globalMenuData={globalMenuData}
            setGlobalMenuData={setGlobalMenuData}
            menuData={globalMenuData[0].sublinks}
            secondarySublinks={globalMenuData[0].secondarySublinks}
            showDropdownMenu={showDropdownMenu}
            setShowDropdownMenu={setShowDropdownMenu}
            fixList={fixList}
            setFixList={setFixList}
            needsOverlay={true}
            productsList={productsList}
          ></MobileDropMenu>
          //<MobileMenu setShowDropdownMenu={setShowDropdownMenu}></MobileMenu>
        : ''
      }
    </div>
  );
};

export default Header;
