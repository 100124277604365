import { useEffect, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FaRegCheckCircle } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useCart } from '../../context/CartContext';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import FormInput from '../NPSportal/FormInput';

function FreightCartForm({
  showFreightForm = false,
  setShowFreightForm = false,
  showPriceForm = false,
  setShowPriceForm = false,
  quoteError = false,
  items = false,
  setMobileCurrentPage,
}) {
  const axiosJWT = useAxiosJWT();
  const mobileScreen = useSelector((state) => state.isMobile.value.isMobile);
  const { cart } = useCart();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    message: '',
  });

  useEffect(() => {
    setFormValues({
      name: '',
      email: '',
      message: '',
    });
  }, []);
  useEffect(() => {
    setFormValues({
      name: '',
      email: '',
      message: '',
    });
  }, []);

  function handleChange(name, value) {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }
  function submitForm(e) {
    e.preventDefault();
    //validate email
    if (formValues.email) {
      const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (!emailRegex.test(formValues.email)) {
        // eslint-disable-next-line no-alert
        alert('Please enter a valid email address');
        return;
      }
      if (showFreightForm) {
        handleQuestionSubmit();
      } else if (showPriceForm) {
        handleQuoteSubmit();
      }
    }
  }

  function handleQuestionSubmit() {
    axiosJWT
      .post('forms/freightQuestion', {
        name: formValues.name,
        email: formValues.email,
        message: formValues.message,
        cart: JSON.stringify(cart),
        error: quoteError || '',
      })
      .then(() => {
        setFormSubmitted(true);
      })
      .catch((err) => {
        setFormError(true);
        console.error(err);
      });
  }
  function handleQuoteSubmit() {
    axiosJWT
      .post('forms/quoteRequest', {
        name: formValues.name,
        email: formValues.email,
        message: formValues.message,
        cart: items ? JSON.stringify(items) : JSON.stringify(cart),
      })
      .then(() => {
        setFormSubmitted(true);
      })
      .catch((err) => {
        setFormError(true);
        console.error(err);
      });
  }
  function resetForm() {
    setShowPriceForm(false);
    setShowFreightForm(false);
    setFormSubmitted(false);
    setFormError(false);
    setFormValues({
      name: '',
      email: '',
      message: '',
    });
  }
  return (
    <div className={`freight-cart-form cartButtons ${mobileScreen ? 'mobile-cart-form' : ''}`}>
      {formSubmitted ?
        <div className='formSubmitted'>
          <AiOutlineCloseCircle
            className='close-button'
            onClick={() => {
              resetForm();
              if (mobileScreen) {
                setMobileCurrentPage('pageOne');
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                resetForm();
                if (mobileScreen) {
                  setMobileCurrentPage('pageOne');
                }
              }
            }}
          />
          <FaRegCheckCircle style={{ height: '100px', width: '100px', color: '#C0E66C' }} />
          <h2>
            {showFreightForm ?
              'Your Freight Question has been received!'
            : 'Thanks for requesting a Price Quote!'}
          </h2>
          <h4>
            {showFreightForm ?
              'We appreciate your interest and will get back to you as soon as possible. If you have any urgent inquiries, please feel free to contact us directly.'
            : 'We appreciate your interest and will review your request as soon as possible.'}
          </h4>
          <div className='underline'></div>
        </div>
      : formError ?
        <div className='formSubmitted'>
          {!mobileScreen && (
            <AiOutlineCloseCircle
              className='close-button'
              onClick={() => {
                resetForm();
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  resetForm();
                }
              }}
            />
          )}

          <h2>Oops... something went wrong.</h2>
          <h4>Please try again later</h4>
          <div className='underline'></div>
        </div>
      : showFreightForm || showPriceForm ?
        <form>
          {!mobileScreen && (
            <AiOutlineCloseCircle
              className='close-button'
              onClick={() => {
                resetForm();
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  resetForm();
                }
              }}
            />
          )}
          {!mobileScreen && (
            <div className='freight-form-header'>
              <h2>{showFreightForm ? 'FREIGHT QUESTIONS' : 'REQUEST A PRICE QUOTE'}</h2>
              <div className='underline'></div>
            </div>
          )}

          <FormInput
            legend='Name'
            name={'name'}
            type='text'
            value={formValues.name}
            onChange={(value) => {
              handleChange('name', value);
            }}
            placeholder='Enter name'
          />

          <FormInput
            legend='Email'
            name='email'
            asterisk={true}
            type='email'
            value={formValues.email}
            onChange={(value) => {
              handleChange('email', value);
            }}
            placeholder='Enter email'
            required={true}
          />

          <FormInput
            legend='Message'
            name='message'
            type='text'
            inputType='textarea'
            value={formValues.message}
            onChange={(value) => {
              handleChange('message', value);
            }}
            placeholder='Write message here...'
          />

          <div className='form-submit-button'>
            <button
              className={`${mobileScreen ? 'mobile-button' : ''}`}
              onClick={submitForm}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  submitForm();
                }
              }}
            >
              Submit
            </button>
          </div>
        </form>
      : ''}
    </div>
  );
}

export default FreightCartForm;
