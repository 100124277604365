import { useEffect, useState } from 'react';
import { BsGrid3X3GapFill } from 'react-icons/bs';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import { ImArrowUp2 } from 'react-icons/im';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import '../CSS/Layout.css';
import useAuth from '../hooks/useAuth';
import useAxiosJWT from '../hooks/useAxiosJWT';
import useGeneralImages from '../hooks/useGeneralImages';
import Header from './Header/Header';
import HeaderNav from './Header/HeaderNav';
import Footer from './Footer/footer';

const Layout = () => {
  const { auth } = useAuth();
  const { generalImages } = useGeneralImages();
  const contact = generalImages.find((i) => i.publicId === 'Contact_Us_xe7p7t')?.imageLink;
  const dealer = generalImages.find((i) => i.publicId === 'Dealer_Portal_zo9epu')?.imageLink;
  const track = generalImages.find((i) => i.publicId === 'Track_Your_Order_cjd5zm')?.imageLink;
  const guide = generalImages.find(
    (i) => i.publicId === 'Button_Website_Guide_1_bzvyoj'
  )?.imageLink;
  const axiosJWT = useAxiosJWT();
  const [open, setOpen] = useState(false);
  const [bannerNotifications, setBannerNotifications] = useState();
  const [responseData, setResponseData] = useState();
  const [testBanner, setTestBanner] = useState('');
  const navigate = useNavigate();

  const mobileScreen = useSelector((state) => state.isMobile.value);

  function getData() {
    axiosJWT.get(`content/getBannerNotifications`).then((response) => {
      setResponseData(response.data);
    });
  }

  useEffect(() => {
    getData();
    checkLocation();
  }, []);
  // useEffect(() => {
  //   filterBannerNotifications(responseData);
  // }, [window?.location.pathname]);

  const testLocations = [
    'localhost',
    '192.168.0.185',
    '192.168.1.182',
    '192.168.0.194',
    '192.168.1.129',
    'test.nationalpublicseating.com',
  ];

  const checkLocation = () => {
    if (testLocations.includes(window.location.hostname)) {
      setTestBanner(<div>THIS IS A TEST SITE!</div>);
    } else {
      setTestBanner();
    }
  };

  function filterBannerNotifications() {
    const currentDate = new Date();
    setBannerNotifications(
      responseData
        ?.filter((obj) => {
          return (
            (obj['On/Off'] === 1 ||
              (obj.Active === 1 &&
                obj['Start Date'] &&
                obj['End Date'] &&
                new Date(obj['Start Date']) <= currentDate &&
                currentDate <= new Date(obj['End Date']))) &&
            (obj.Path ? obj.Path === window.location.pathname : true)
          );
        })
        ?.filter((i) => i.type === 'banner' || i.type === 'maintenance')
        ?.filter((i) => (!auth?.rep ? i.require_auth === 0 : true))
    );
  }

  useEffect(() => {
    filterBannerNotifications();
  }, [responseData, window.location.pathname]);

  return (
    <div className='content'>
      {testBanner || bannerNotifications?.filter((i) => i.type !== 'maintenance').length ?
        <div
          className='notification-banner'
          style={{
            background: 'red',
            width: mobileScreen.isMobile ? '1440px' : '100%',
            color: 'white',
            padding: '.5rem',
            textAlign: 'center',
            fontSize: mobileScreen.isMobile ? '50px' : 'initial',
            boxSizing: 'border-box',
          }}
        >
          {bannerNotifications
            ?.filter((i) => i.type !== 'maintenance')
            ?.map((i, index) => (
              <div key={index}>{i.Text}</div>
            ))}
          {testBanner}
          {/* {window.location.pathname === "/cart" &&
            "Our freight cart is currently down; we apologize for this inconvenience. If you need freight quote, please email freightquotes@nationalpublicseating.com or reach out to your account manager for assistance."} */}
        </div>
      : ''}
      <div className='grid'>
        <div className='grid-paddding-outer'></div>
      </div>
      <Header />
      {(
        bannerNotifications?.find((i) => i.type === 'maintenance') &&
        bannerNotifications?.find((i) => i.type === 'maintenance')['On/Off'] === 1 &&
        !auth?.roles?.includes(1117)
      ) ?
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
          className='inner-padding'
        >
          <img
            style={{ width: '80rem' }}
            src={generalImages.find((i) => i.publicId === 'UNDER_MAINTENANCE_aly0oc')?.imageLink}
            alt='under maintenance'
          ></img>
        </div>
      : <>
          <HeaderNav />

          <Outlet />

          <div className='arrowContact'>
            <div
              role='button'
              aria-label='Scroll to the Top of the Page'
              tabIndex={0}
              className='arrow'
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }
              }}
            >
              <ImArrowUp2 />
            </div>
            <div
              role='button'
              aria-label='Quick Access Navigation'
              tabIndex={0}
              className='menu'
              onClick={() => {
                setOpen(!open);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setOpen(!open);
                }
              }}
            >
              {open ?
                <FiMinusCircle className='open' />
              : <FiPlusCircle className='open' />}
              <BsGrid3X3GapFill className='square' />
            </div>
            {open ?
              <div className='menu-open'>
                <div
                  role='button'
                  aria-label='Website Guide'
                  tabIndex={0}
                  onClick={() => {
                    setOpen(false);
                    window.open(
                      generalImages.find((i) => i.publicId === 'Website_Guide_vlito9')?.imageLink
                    );
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setOpen(false);
                      window.open(
                        generalImages.find((i) => i.publicId === 'Website_Guide_vlito9')?.imageLink
                      );
                    }
                  }}
                >
                  <img
                    src={guide}
                    alt='Website Guide'
                    style={{ transform: 'scale(1.08)', marginLeft: '-2px' }}
                  />
                </div>
                <div
                  role='button'
                  aria-label='Contact Us'
                  tabIndex={0}
                  onClick={() => {
                    setOpen(false);
                    navigate('/contact?category=contact-us');
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setOpen(false);
                      navigate('/contact?category=contact-us');
                    }
                  }}
                >
                  <img
                    src={contact}
                    alt='Contact Us'
                  />
                </div>
                <div
                  role='button'
                  aria-label='Track Your Order'
                  tabIndex={0}
                  onClick={() => {
                    setOpen(false);
                    navigate('/track-order');
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setOpen(false);
                      navigate('/track-order');
                    }
                  }}
                >
                  <img
                    src={track}
                    alt='Track an Order'
                  />
                </div>
                <div
                  role='button'
                  aria-label='Dealer Portal'
                  tabIndex={0}
                  onClick={() => {
                    setOpen(false);
                    navigate('/dealer-portal');
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setOpen(false);
                      navigate('/dealer-portal');
                    }
                  }}
                >
                  <img
                    src={dealer}
                    alt='Dealer Portal'
                  />
                </div>
              </div>
            : ''}
          </div>
          <Footer />
        </>
      }
    </div>
  );
};

export default Layout;
