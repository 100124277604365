import { useCart } from '../../context/CartContext';
import SkuItem from './SkuItem';

function MobileFreightQuote() {
  const { cart } = useCart();
  return (
    <div className='search-sku-div'>
      <div className='innerSkuSearch'>
        <h3>Freight Quote</h3>

        <div className='returned-sku-div'>
          {/* start of looping for cart */}
          {cart?.map((i, index) => {
            return (
              <div
                key={index}
                className='sku-list-item'
              >
                <SkuItem item={i} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MobileFreightQuote;
