import { useNavigate } from 'react-router-dom';
import '../../CSS/AboutDrop.css';
import '../../CSS/MediaCenterDrop.css';
import '../../CSS/AboutDrop.css';
import useGeneralImages from '../../hooks/useGeneralImages';

const AboutDrop = ({ data }) => {
  const { generalImages } = useGeneralImages();
  const logo = generalImages.find((i) => i.publicId === 'Logo_About_Nav_hzcykm_byzlqj')?.imageLink;
  const navigate = useNavigate();

  return (
    <div
      style={{ marginLeft: '-44rem' }}
      className='media-div about-drop-div'
    >
      <div className='media-title-div'>
        <h2>About Us</h2>
      </div>
      <div className='grid-container'>
        <div className='about-div'>
          {data?.map((e) => (
            <div
              role='button'
              aria-label={e.title}
              tabIndex={0}
              key={e.title}
              className='about-tile'
              onClick={() => {
                navigate(`/about/${e.link}`);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  navigate(`/about/${e.link}`);
                }
              }}
            >
              <h3>{e.title}</h3>
            </div>
          ))}
        </div>
        <div className='about-drop-text'>
          <h1>YOUR ONE-STOP SOURCE FOR QUICK SHIP, INSTITUTIONAL GRADE FURNITURE</h1>
          <h2>
            The National Public Seating® logo represents the Superior Strength, Quality, and Value
            our customers have come to expect of the NPS product line.
          </h2>
        </div>
        <img
          src={logo}
          alt='logo'
        ></img>
      </div>
    </div>
  );
};

export default AboutDrop;
