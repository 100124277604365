import React from 'react';

export default class MouseFollower extends React.Component {
  state = {
    left: 0,
    top: 0,
  };
  componentDidMount = () => {
    document.addEventListener('mousemove', this.updatePosition);
  };
  componentWillUnmount = () => {
    document.removeEventListener('mousemove', this.updatePosition);
  };
  updatePosition = (event) => {
    this.setState({
      left: event.clientX,
      top: event.clientY,
    });
  };

  render() {
    const { left, top } = this.props;
    const styles = {
      //   width: 10,
      //   height: 10,
      position: 'fixed',
      left: this.state.left + (left || 20),
      top: this.state.top - (top || 20),
      //   border: "1px solid red",
    };
    const { element } = this.props;
    return <div style={styles}>{element}</div>;
  }
}
