import EditTemplate from '../EditorPortal/EditTemplate';
const NewUsers = () => {
  //   const axiosJWT = useAxiosJWT();
  //   const [accountRoles, setAccountsRoles] = useState([]);

  //   const location = useLocation();
  //   const searchParams = new URLSearchParams(location.search);
  //   // Convert searchParams to string
  //   const queryString = searchParams.toString();
  //   var decoded;
  //   if (queryString) {
  //     decoded = decodeURIComponent(queryString)?.substring(
  //       queryString?.indexOf("=") + 1
  //     );
  //   }

  const columns = [
    {
      type: 'text',
      name: 'ID',
      searchAllowed: true,
      editable: true,
      display: false,
      table: 'users',
      fieldName: 'ID',
    },
    {
      type: 'text',
      name: 'name',
      searchAllowed: true,
      editable: true,
      display: true,
      table: 'users',
      fieldName: 'rep',
    },
    {
      type: 'text',
      name: 'company',
      searchAllowed: true,
      editable: true,
      display: true,
      table: 'users',
      fieldName: 'dealer',
    },
    {
      type: 'text',
      name: 'username',
      searchAllowed: true,
      editable: false,
      display: true,
      table: 'users',
      fieldName: 'username',
    },

    {
      type: 'manage',
      name: 'Manage Account',
      searchAllowed: true,
      editable: false,
      display: true,
      table: 'users',
      fieldName: 'manage',
    },
    {
      type: 'roles',
      name: 'internalRoles',
      searchAllowed: true,
      editable: true,
      display: true,
      table: 'roles_by_user',
      fieldName: 'internalRoles',
    },
  ];

  return (
    <EditTemplate
      columns={columns}
      getEndPoint={'getNewUsers'}
      title={'New Users'}
      table={'users'}
      exportAvailable={true}
    />
  );
};

export default NewUsers;
