/* eslint-disable no-alert */
import { useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { GoCheckCircle, GoClock } from 'react-icons/go';
import { IoSend } from 'react-icons/io5';
import { SiMicrosoftexcel } from 'react-icons/si';
import useAuth from '../../hooks/useAuth';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import UploadFiles from '../Service Portal/UploadFiles';
import ScheduleCalendarEvent from './ScheduleCalendarEvent';

function EditCalendarEvent({ event, setEditPopup, eventsList, setEventsList, setEditedCalendar }) {
  const axiosJWT = useAxiosJWT();
  const { auth } = useAuth();
  const [countFiles, setCountFiles] = useState([]);
  const [countHover, setCountHover] = useState(false);
  const [reschedule, setReschedule] = useState(false);
  const [warning, setWarning] = useState(false);
  const [comment, setComment] = useState();
  const [showComments, setShowComments] = useState(false);

  const entireTask = [];
  eventsList.forEach((e) => {
    if (e.title === event.title && e.taskID === event.taskID) {
      entireTask.push(e);
    }
  });

  const files =
    event.count ?
      event.count.map((uploadedCountFile, index) => (
        <div
          className='count-file-div'
          key={index}
        >
          <a
            href={uploadedCountFile.url}
            className='count-file'
            onMouseEnter={() => setCountHover(true)}
            onMouseLeave={() => setCountHover(false)}
          >
            <SiMicrosoftexcel style={{ height: '2em', width: '2em', color: 'green' }} />
            <span className='uploaded-file'>{uploadedCountFile.fileName}</span>
            <span className={countHover ? 'uploaded-date' : 'uploaded-date-hidden'}>
              {new Date(uploadedCountFile.date).toLocaleDateString('en-us', {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              })}
            </span>
          </a>
        </div>
      ))
    : [];

  async function handleEdit(e, editType) {
    e.preventDefault();
    if (countFiles.length) {
      await handleUploadCount();
    } else if (editType === 'addCount') {
      window.alert('No files were selected.');
      return;
    }

    if (editType === 'completeTask') {
      if (event.status === 'complete') {
        return;
      }
      event.color = '#D3D3D3';
      event.status = 'complete';
    } else if (editType === 'addComment') {
      if (!comment) {
        window.alert('Please add a comment.');
        return;
      } else {
        const completeComment = {
          name: auth.rep,
          date: new Date().toLocaleDateString('en-us', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          }),
          comment: comment,
        };
        event.comments = event.comments ? [...event.comments, completeComment] : [completeComment];
      }
    }
    await axiosJWT
      .post(`employeePortal/updateEvent`, { event })
      .then((response) => {
        setEventsList((eventsList) => {
          return eventsList.map((e) =>
            e.title === event.title && e.taskID === event.taskID ?
              {
                ...e,
                color: response.data.color,
                status: response.data.status,
                count: response.data.count,
                comments: response.data.comments,
              }
            : e
          );
        });
        setEditPopup(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async function handleUploadCount() {
    const formData = new FormData();
    if (countFiles) {
      Object.values(countFiles).forEach((file) => {
        formData.append('arrayOfFiles', file);
      });
    }
    await axiosJWT
      .post(`employeePortal/uploadCount`, formData)
      .then((response) => {
        event.count = event.count ? [...event.count, ...response.data] : response.data;
        setEditPopup(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async function handleDelete() {
    await axiosJWT
      .post(`employeePortal/deleteEvent`, { event })
      .then(() => {
        setEventsList((eventsList) => eventsList.filter((e) => e.taskID !== event.taskID));
        setEditPopup(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <div className='calendar-popup'>
      <AiOutlineCloseCircle
        className='close-button'
        onClick={() => setEditPopup(false)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setEditPopup(false);
          }
        }}
        style={{ top: '1rem', right: '1rem' }}
      />
      <h2 style={{ color: event.color }}>{event.title.toUpperCase()}</h2>
      <div className='status-div'>
        {event.status === 'active' ?
          <span>
            <GoClock style={{ height: '1em', width: '1em' }} /> Active
          </span>
        : <span>
            <GoCheckCircle style={{ height: '1em', width: '1em', color: 'green' }} /> Completed
          </span>
        }
        {event.comments && (
          <span
            role='button'
            aria-label='View Updates'
            tabIndex={0}
            className='view-updates-button'
            onClick={() => setShowComments(true)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setShowComments(true);
              }
            }}
          >
            - View Updates
          </span>
        )}
      </div>
      <h4 style={{ color: event.color }}>
        {entireTask[0]?.start.toLocaleDateString('en-us', {
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })}
        -
        {entireTask[entireTask.length - 1]?.end.toLocaleDateString('en-us', {
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })}
      </h4>

      <form className='event-comment-div'>
        <textarea
          className='event-comments'
          placeholder='Add updatees and comments...'
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <button
          className='event-comments-send'
          onClick={(e) => handleEdit(e, 'addComment')}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleEdit(e, 'addComment');
            }
          }}
        >
          <IoSend style={{ color: 'inherit' }} />
        </button>
      </form>
      <div className='edit-task-buttons'>
        <UploadFiles
          files={countFiles}
          setFiles={setCountFiles}
        />
        <button
          className='complete-task-button'
          onClick={(e) => handleEdit(e, 'addCount')}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleEdit(e, 'addCount');
            }
          }}
        >
          Upload Selected Files
        </button>
        <button
          className={`complete-task-button ${event.status === 'complete' ? 'disable' : ''}`}
          onClick={(e) => handleEdit(e, 'completeTask')}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleEdit(e, 'completeTask');
            }
          }}
        >
          Mark Task as Complete
        </button>
      </div>
      {files.map((file) => (
        <>{file}</>
      ))}
      <div className='edit-task-buttons'>
        <button
          className='edit-cal-button'
          onClick={() => setEditPopup(false)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setEditPopup(false);
            }
          }}
        >
          Cancel
        </button>
        <button
          className={`edit-cal-button ${event.status === 'complete' ? 'disable' : ''}`}
          onClick={() => {
            setReschedule(true);
            setWarning(false);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setReschedule(true);
              setWarning(false);
            }
          }}
        >
          Reschedule Task
        </button>
        <button
          className={`edit-cal-button warning ${event.status === 'complete' ? 'disable' : ''}`}
          onClick={() => {
            setWarning('delete');
            setReschedule(false);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setWarning('delete');
              setReschedule(false);
            }
          }}
        >
          Delete Task
        </button>
      </div>

      {showComments && (
        <div className='calendar-popup'>
          <AiOutlineCloseCircle
            className='close-button'
            onClick={() => setShowComments(false)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setShowComments(false);
              }
            }}
            style={{ top: '1rem', right: '1rem' }}
          />
          <h4 className='updates-popup-heading'>Updates</h4>
          <div className='comments-outer-div'>
            {event.comments.map((comment, index) => (
              <div
                className='comment-div'
                key={index}
              >
                <span>{comment.name} </span>
                <span>{comment.date}</span>
                <div>{comment.comment}</div>
              </div>
            ))}
          </div>
          <form className='event-comment-div'>
            <textarea
              className='event-comments popup-add-comment'
              placeholder='Add updates and comments...'
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <button
              className='event-comments-send'
              onClick={(e) => handleEdit(e, 'addComment')}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleEdit(e, 'addComment');
                }
              }}
            >
              <IoSend style={{ color: 'inherit' }} />
            </button>
          </form>
        </div>
      )}
      {warning === 'delete' && event.status === 'active' && (
        <div className='calendar-popup'>
          Are you sure you want to delete this task? It will permanently remove the task from the
          calendar.
          <div className='sub-cal-buttons'>
            <button
              className='edit-cal-button'
              onClick={() => setWarning(false)}
            >
              NO
            </button>
            <button
              className='edit-cal-button warning'
              onClick={handleDelete}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleDelete();
                }
              }}
            >
              YES
            </button>
          </div>
        </div>
      )}
      {reschedule && event.status === 'active' && (
        <ScheduleCalendarEvent
          type='reschedule'
          setShowAddEvent={setReschedule}
          setEditedCalendar={setEditedCalendar}
          event={event}
          setEditPopup={setEditPopup}
        />
      )}
    </div>
  );
}

export default EditCalendarEvent;
