import Axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { FaCheck } from 'react-icons/fa';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import MediaNavButtons from '../../Components/Media/MediaNavButtons';
import VideoCube from '../../Components/Media/VideoCube';
import VideoPlayer from '../../Components/Products/VideoPlayer';
import useGeneralImages from '../../hooks/useGeneralImages';
import TemplateTilePage from '../../Components/Shared/TemplateTilePage';

const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

const Videos = () => {
  const { generalImages } = useGeneralImages();
  const videoBanner = generalImages.find((i) => i.publicId === 'G_Videos_lfk1na')?.imageLink;
  const [elementsMapped, setElementsMapped] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState();
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState();
  const [openFilter, setOpenFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState();
  const [videos, setVideos] = useState();

  function getVideos() {
    Axios.get(`${apiAddress}/content/getVideos`).then((response) => {
      setVideos(response.data.videos);
    });
  }
  function getVideoElements() {
    var holderArray = [];
    videos
      ?.filter((i) => {
        if (search.length > 0 || !search) {
          return i.title.toLowerCase().includes(search.toLowerCase());
        }
        return true;
      })
      ?.filter((i) => {
        if (filters?.length) {
          return filters.includes(i.videoType);
        }
        return true;
      })
      ?.map((i) => {
        holderArray.push(
          <VideoCube
            videoData={i}
            setOpen={setOpen}
            setCurrentVideo={setCurrentVideo}
            currentVideo={currentVideo}
            key={i}
          />
        );
      });
    setElementsMapped(holderArray);
  }

  useEffect(() => {
    document.title = 'Videos - National Public Seating';
  }, []);

  useEffect(() => {
    getVideos();
  }, []);

  useEffect(() => {
    getVideoElements();
  }, [search, filters]);

  useEffect(() => {
    getVideoElements();
  }, [videos]);
  useEffect(() => {
    const categorySet = new Set(videos?.map((obj) => obj.videoType));
    setFilterOptions(Array.from(categorySet));
  }, [videos]);

  function setFilter(i) {
    if (!filters?.length) {
      setFilters([i]);
    } else if (filters?.includes(i)) {
      setFilters(filters.filter((j) => j !== i));
    } else {
      setFilters([...filters, i]);
    }
  }

  const filterContainer = useRef(null);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (
  //       filterContainer.current &&
  //       !filterContainer.current.contains(event.target) &&
  //       openFilter
  //     ) {
  //       setOpenFilter(false);
  //     }
  //   };

  //   document.addEventListener('click', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('click', handleClickOutside);
  //   };
  // }, [openFilter]);

  return (
    <div>
      <TemplateTilePage
        banner={
          <img
            src={videoBanner}
            alt='Videos Banner'
          ></img>
        }
        elements={elementsMapped}
        across={3}
        navigateBar={'Videos'}
        middleElement={
          <div>
            <MediaNavButtons current={'Videos'} />
            <div
              className='inner-padding'
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <h2>VIDEOS</h2>
                <div
                  className='underline'
                  style={{ marginLeft: '0', width: '67px' }}
                ></div>
              </div>
              <div
                className='flex-center'
                style={{ marginTop: '2rem' }}
              >
                <div style={{ marginRight: '18px' }}>
                  <div
                    className='select-cat h3'
                    role='button'
                    aria-label='Select Media Type'
                    tabIndex={0}
                    onClick={() => {
                      setOpenFilter(!openFilter);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setOpenFilter(!openFilter);
                      }
                    }}
                    style={{
                      cursor: 'pointer',
                      borderColor: openFilter ? '#01425A' : '#BBBBBA',
                      color: openFilter ? '#01425A' : '#707070',
                      marginTop: '0',
                    }}
                  >
                    Select media type
                    <div>
                      {openFilter ?
                        <FiChevronUp style={{ strokeWidth: '3px' }} />
                      : <FiChevronDown style={{ strokeWidth: '3px' }} />}
                    </div>
                  </div>

                  {openFilter ?
                    <div
                      className='filter-options-videos category-list-div'
                      style={{
                        height: '8rem ',
                        position: 'absolute',
                        zIndex: '10',
                      }}
                      ref={filterContainer}
                    >
                      <div className='category-list'>
                        {filterOptions?.map((i) => {
                          return (
                            <div
                              key={i}
                              style={{ display: 'flex', margin: '0' }}
                            >
                              <h3
                                role='button'
                                aria-label={i}
                                tabIndex={0}
                                onClick={() => {
                                  setFilter(i);
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    setFilter(i);
                                  }
                                }}
                                style={{ margin: '8px' }}
                              >
                                <div
                                  role='button'
                                  aria-label={i}
                                  tabIndex={0}
                                  onClick={() => {
                                    setFilter(i);
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      setFilter(i);
                                    }
                                  }}
                                  className={'selection-box-sku'}
                                  style={{
                                    borderColor:
                                      filters?.includes(i) ? 'var(--lightgreen)' : '#808080',
                                  }}
                                >
                                  {filters?.includes(i) ?
                                    <FaCheck />
                                  : ''}
                                </div>
                                {i}
                              </h3>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  : ''}
                </div>
                <div
                  className='search-video '
                  style={{ marginTop: '0' }}
                >
                  <div
                    className='select-cat'
                    style={{ marginTop: '0' }}
                  >
                    <input
                      className='select-cat'
                      style={{
                        border: 'none',
                        margin: '0',
                        boxShadow: 'none',
                        height: '40px',
                        textTransform: 'uppercase',
                      }}
                      name='search'
                      placeholder='SEARCH VIDEOS'
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    ></input>
                    <AiOutlineSearch
                      style={{
                        background: 'none',
                        color: '#707070',
                        height: '2rem',
                        width: '2rem',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
      {open ?
        <VideoPlayer
          videoSrc={currentVideo}
          setShow={setOpen}
        />
      : ''}
    </div>
  );
};

export default Videos;
