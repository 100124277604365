import { useEffect, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import '../../CSS/ManageAccounts.css';
import useAxiosJWT from '../../hooks/useAxiosJWT';

const ManageAccount = ({
  user,
  setUser,
  userAccounts,
  setUserAccounts,
  manageAccount,
  customerList,
  setOpenAccount,
  getData,
}) => {
  const [searchVal, setSearchVal] = useState('');
  const [accountChosen, setAccountChosen] = useState('');
  const [showDrop, setShowDrop] = useState();
  const [showRoles, setShowRoles] = useState('');
  const [pendingAccounts, setPendingAccounts] = useState([]);
  const [notification, setNotification] = useState();
  const [dropdown, setDropdown] = useState();
  const [accountRoles, setAccountRoles] = useState();
  const [checkedRoles, setCheckedRoles] = useState([]);
  const [addAccountDrop, setAddAccountDrop] = useState();
  const [roleActions, setRoleActions] = useState([]);
  const [removeActions, setRemoveActions] = useState([]);

  const axiosJWT = useAxiosJWT();
  const navigate = useNavigate();

  useEffect(() => {
    axiosJWT
      .post(`register/getAccountRoles`)
      .then((response) => {
        setAccountRoles(response.data);
      })
      .catch((err) => {
        console.error(err.sqlMessage);
      });
  }, []);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  function addAccount() {
    axiosJWT
      .post(`adminPortal/accountRequest`, {
        userID: user.ID,
        accountID: accountChosen.customerId,
        accountName: accountChosen.name,
        roles: checkedRoles.map((i) => i.ID),
      })
      .then((response) => {
        setPendingAccounts([...pendingAccounts, response.data]);
        setAccountChosen('');
        setCheckedRoles([]);
        setDropdown();
      })
      .catch((err) => {
        setNotification(err.response.data.error);
        setAccountChosen('');
        setCheckedRoles([]);
        setDropdown();
        console.error(err);
      });
  }

  function removeAccount(accountNum) {
    setRemoveActions([
      ...removeActions,
      {
        userID: user.ID,
        accountID: accountNum,
      },
    ]);
    setUserAccounts(userAccounts.filter((i) => i.account_num !== accountNum));
  }

  async function getPendingAccounts() {
    axiosJWT
      .post(`adminPortal/pendingAccountsForUser`, {
        userID: user.ID,
      })
      .then((response) => {
        setPendingAccounts(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function handleAccountRoles(role, checked) {
    if (checked) {
      setCheckedRoles([...checkedRoles, role]);
    } else {
      setCheckedRoles((prev) => prev.filter((i) => i !== role));
    }
  }

  function handleRoleChange(userID, accountNum, role, checked, type) {
    setRoleActions([
      ...roleActions,
      {
        userID: userID,
        accountNum: accountNum,
        accountName: customerList?.find((i) => i.customerId === accountNum)?.name,
        role: role,
        checked: checked,
      },
    ]);

    setUserAccounts((prevData) => {
      return prevData.map((obj) => {
        if (obj.user_ID.toString() + obj.account_num.toString() === userID + accountNum) {
          if (type === 'roles') {
            return {
              ...obj,
              AccountRoles: {
                ...obj.AccountRoles,
                [role]: checked ? 1 : 0,
              },
            };
          } else {
            return {
              ...obj,
              Permissions: {
                ...obj.Permissions,
                [role]: checked ? 1 : 0,
              },
            };
          }
        }
        return obj;
      });
    });
  }

  async function saveAccount() {
    if (roleActions.length > 0) {
      roleActions
        ?.filter(
          (item, index, array) =>
            index === array.findIndex((obj) => JSON.stringify(obj) === JSON.stringify(item))
        )
        ?.map(async (i) => await axiosJWT.post(`adminPortal/manageRoleForUserAccount`, i));
    }
    if (removeActions.length > 0) {
      removeActions.map(async (i) => {
        await axiosJWT.post(`adminPortal/removeAccountForUser`, i);
      });
    }
  }

  useEffect(() => {
    getPendingAccounts();
  }, []);
  useEffect(() => {
    setUser({ ...user, Accounts: userAccounts });
  }, [userAccounts]);

  return (
    <>
      <div className='ma popup'>
        <div className='overlay'></div>
        <div className='ma popup-accounts'>
          <AiOutlineCloseCircle
            className='close-button'
            onClick={() => manageAccount()}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                manageAccount();
              }
            }}
            style={{ top: '1rem', right: '1rem' }}
          />
          <h2>Manage Accounts</h2>
          <h4 style={{ margin: '0px', textAlign: 'center' }}>
            {user.username ?
              `${user?.username} is requesting access to ${user?.request}`
            : user?.Username}
          </h4>
          <div
            style={{ marginTop: '1rem' }}
            className='ma accounts-div'
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '520px',
                alignItems: 'center',
                height: 'fit-content',
              }}
              className='search-sku-div'
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <h3>Search by Account</h3>
                <div className='underline'></div>

                <div className='search'>
                  <div className='modelNum-input'>
                    <input
                      type='text'
                      name='searchVal'
                      placeholder='Search Account'
                      id='searchVal'
                      value={searchVal}
                      onChange={(e) => {
                        setSearchVal(e.target.value);
                      }}
                      onFocus={() => {
                        setShowDrop(true);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          // e.preventDefault();

                          const findSearchVal = customerList?.filter((i) => {
                            return (
                              i.name?.toString()?.toUpperCase() ===
                              searchVal?.toString()?.toUpperCase()
                            );
                          });
                          if (findSearchVal) {
                            setAccountChosen(findSearchVal[0]);
                            setShowDrop(false);
                          }
                        }
                        // if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                        //   if (showDrop) {
                        //     setIndexFocus(indexFocus + 1);
                        //   }
                        // }
                      }}
                      required
                    ></input>

                    {customerList && searchVal && showDrop ?
                      <div className='modelNum-drop-div'>
                        <div className='modelNum-dropdown'>
                          {customerList
                            ?.filter((i) => {
                              const searchUpperCase = searchVal?.toString()?.toUpperCase();
                              const nameUpperCase = i.name?.toString()?.toUpperCase();
                              const customerIdUpperCase = i.customerId?.toString()?.toUpperCase();

                              return (
                                (customerIdUpperCase && customerIdUpperCase === searchUpperCase) ||
                                (customerIdUpperCase &&
                                  customerIdUpperCase.includes(searchUpperCase)) ||
                                (nameUpperCase && nameUpperCase.includes(searchUpperCase))
                              );
                            })
                            ?.sort((a, b) => {
                              const searchUpperCase = searchVal?.toString()?.toUpperCase();
                              const nameUpperCaseA = a.name?.toString()?.toUpperCase();
                              const customerIdUpperCaseA = a.customerId?.toString()?.toUpperCase();
                              const nameUpperCaseB = b.name?.toString()?.toUpperCase();
                              const customerIdUpperCaseB = b.customerId?.toString()?.toUpperCase();
                              const isExactMatchA = customerIdUpperCaseA === searchUpperCase;
                              const isExactMatchB = customerIdUpperCaseB === searchUpperCase;

                              if (isExactMatchA && !isExactMatchB) {
                                return -1;
                              } else if (!isExactMatchA && isExactMatchB) {
                                return 1;
                              }

                              // If neither or both are exact matches, sort by proximity
                              const proximityA =
                                (isExactMatchA ? 0 : (
                                  customerIdUpperCaseA?.indexOf(searchUpperCase)
                                )) ||
                                (nameUpperCaseA && nameUpperCaseA.indexOf(searchUpperCase)) ||
                                9999;

                              const proximityB =
                                (isExactMatchB ? 0 : (
                                  customerIdUpperCaseB?.indexOf(searchUpperCase)
                                )) ||
                                (nameUpperCaseB && nameUpperCaseB.indexOf(searchUpperCase)) ||
                                9999;

                              return proximityA - proximityB;
                            })
                            ?.map((i, index) => {
                              return (
                                <div
                                  role='button'
                                  aria-label={i.name}
                                  tabIndex={0}
                                  key={index}
                                  className={`modelNum-list-item`}
                                  onClick={() => {
                                    setAccountChosen(i);
                                    setShowDrop(false);
                                    setSearchVal('');
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      setAccountChosen(i);
                                      setShowDrop(false);
                                      setSearchVal('');
                                    }
                                  }}
                                >
                                  {i.customerId} - {i.name}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    : ''}
                  </div>
                </div>
              </div>

              {accountChosen ?
                <div
                  style={{
                    width: '100%',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      marginTop: '1rem',
                    }}
                  >
                    <h4
                      style={{ fontSize: '17px' }}
                    >{`${accountChosen.customerId} - ${accountChosen.name}`}</h4>
                    <h3
                      style={{
                        fontSize: '14px',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        color: 'grey',
                      }}
                    >{`Level ${accountChosen?.priceLevelNumber}: ${accountChosen?.priceLevel}`}</h3>
                  </div>
                  <div style={{ margin: '5px 0px 16px 0px' }}>
                    <h4 style={{ fontSize: '14px', textAlign: 'center' }}>
                      Select Roles/Permissions:
                    </h4>
                    <div
                      style={{ justifyContent: 'start', position: 'relaive' }}
                      className='ma flex-styling'
                    >
                      <div
                        role='button'
                        aria-label='Roles'
                        tabIndex={0}
                        onClick={() => {
                          if (addAccountDrop === 'roles') {
                            setAddAccountDrop();
                          } else {
                            setAddAccountDrop('roles');
                          }
                        }}
                        className={`ma input-holder`}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            if (addAccountDrop === 'roles') {
                              setAddAccountDrop();
                            } else {
                              setAddAccountDrop('roles');
                            }
                          }
                        }}
                      >
                        <div className='select-down'>Roles</div>
                        {addAccountDrop === 'roles' ?
                          <BiChevronUp
                            onClick={() => {
                              setAddAccountDrop();
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                setAddAccountDrop();
                              }
                            }}
                            className='laminate-select-down laminates-svg'
                          />
                        : <BiChevronDown
                            onClick={() => {
                              setAddAccountDrop('roles');
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                setAddAccountDrop('roles');
                              }
                            }}
                            className='laminate-select-down laminates-svg'
                          />
                        }
                      </div>
                      {addAccountDrop === 'roles' ?
                        <div className='accountroles-dropdown'>
                          {accountRoles
                            ?.filter((i) => i.permission === 0)
                            ?.map((role, index) => (
                              <div
                                key={index}
                                style={{
                                  display: 'flex',
                                  cursor: 'default',
                                }}
                              >
                                <input
                                  type='checkbox'
                                  checked={checkedRoles.includes(role)}
                                  style={{ cursor: 'pointer' }}
                                  onChange={(event) =>
                                    handleAccountRoles(role, event.target.checked, 'roles')
                                  }
                                />
                                <div>{role.role}</div>
                              </div>
                            ))}
                        </div>
                      : ''}
                      <div
                        role='button'
                        aria-label='Permissions'
                        tabIndex={0}
                        onClick={() => {
                          if (addAccountDrop === 'permissions') {
                            setAddAccountDrop();
                          } else {
                            setAddAccountDrop('permissions');
                          }
                        }}
                        className={`ma input-holder`}
                        style={{ margin: '0px 0px 0px 4px' }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            if (addAccountDrop === 'permissions') {
                              setAddAccountDrop();
                            } else {
                              setAddAccountDrop('permissions');
                            }
                          }
                        }}
                      >
                        <div className='select-down'>Permissions</div>
                        {addAccountDrop === 'permissions' ?
                          <BiChevronUp
                            onClick={() => {
                              setAddAccountDrop();
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                setAddAccountDrop();
                              }
                            }}
                            className='laminate-select-down laminates-svg'
                          />
                        : <BiChevronDown
                            onClick={() => {
                              setAddAccountDrop('permissions');
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                setAddAccountDrop('permissions');
                              }
                            }}
                            className='laminate-select-down laminates-svg'
                          />
                        }
                      </div>
                      {addAccountDrop === 'permissions' ?
                        <div
                          style={{ marginLeft: '7.9rem' }}
                          className='accountroles-dropdown'
                        >
                          {accountRoles
                            ?.filter((i) => i.permission === 1)
                            ?.map((role, index) => (
                              <div
                                key={index}
                                style={{
                                  display: 'flex',
                                  cursor: 'default',
                                }}
                              >
                                <input
                                  type='checkbox'
                                  checked={checkedRoles.includes(role)}
                                  style={{ cursor: 'pointer' }}
                                  onChange={(event) =>
                                    handleAccountRoles(role, event.target.checked, 'permissions')
                                  }
                                />
                                <div>{role.role}</div>
                              </div>
                            ))}
                        </div>
                      : ''}
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={addAccount}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          addAccount();
                        }
                      }}
                      className='green-button'
                    >
                      Add
                    </button>
                  </div>
                </div>
              : ''}
            </div>
            {pendingAccounts.length > 0 ?
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '520px',
                  alignItems: 'center',
                  height: 'fit-content',
                }}
                className='search-sku-div'
              >
                <h3 style={{ margin: 'auto' }}>Pending Accounts:</h3>
                <div className='underline'></div>
                <div style={{ width: '100%' }}>
                  {pendingAccounts.map((account, index) => (
                    <div
                      key={index}
                      className='ma flex-styling'
                      style={{
                        margin: '8px',
                        width: '100%',
                      }}
                    >
                      <div>
                        {account.account_number} {' - '}
                        {account.account_name}
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  onClick={() =>
                    navigate(
                      `/admin/account-requests?searchInput=${user?.username || user?.Username}`
                    )
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      navigate(
                        `/admin/account-requests?searchInput=${user?.username || user?.Username}`
                      );
                    }
                  }}
                  style={{ width: '30rem' }}
                  className='green-button'
                >
                  Manage Pending Accounts
                </button>
              </div>
            : ''}

            {user?.Accounts?.length > 0 ?
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '520px',
                  alignItems: 'center',
                  height: 'fit-content',
                }}
                className='search-sku-div'
              >
                <h3 style={{ margin: 'auto' }}>Accounts:</h3>
                <div className='underline'></div>
                <div style={{ width: '100%' }}>
                  {user?.Accounts?.map((account, index) => (
                    <div
                      key={index}
                      className='ma flex-styling'
                      style={{
                        margin: '8px',
                        width: '100%',
                        position: 'relative',
                      }}
                    >
                      <div>
                        {account.account_num} {' - '}
                        {customerList?.find((i) => i.customerId === account.account_num)?.name}
                      </div>
                      <div className='ma flex-styling'>
                        <div
                          role='button'
                          aria-label='Roles'
                          tabIndex={0}
                          onClick={() => {
                            if (
                              showRoles ===
                                account.user_ID.toString() + account.account_num.toString() &&
                              dropdown === 'roles'
                            ) {
                              setShowRoles();
                              setDropdown();
                            } else {
                              setShowRoles(
                                account.user_ID.toString() + account.account_num.toString()
                              );
                              setDropdown('roles');
                            }
                          }}
                          className={`ma input-holder`}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              if (
                                showRoles ===
                                  account.user_ID.toString() + account.account_num.toString() &&
                                dropdown === 'roles'
                              ) {
                                setShowRoles();
                                setDropdown();
                              } else {
                                setShowRoles(
                                  account.user_ID.toString() + account.account_num.toString()
                                );
                                setDropdown('roles');
                              }
                            }
                          }}
                        >
                          <div className='select-down'>Roles</div>
                          {(
                            showRoles ===
                              account.user_ID.toString() + account.account_num.toString() &&
                            dropdown === 'roles'
                          ) ?
                            <BiChevronUp
                              onClick={() => {
                                setShowRoles();
                                setDropdown();
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  setShowRoles();
                                  setDropdown();
                                }
                              }}
                              className='laminate-select-down laminates-svg'
                            />
                          : <BiChevronDown
                              onClick={() => {
                                setShowRoles(
                                  account.user_ID.toString() + account.account_num.toString()
                                );
                                setDropdown('roles');
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  setShowRoles(
                                    account.user_ID.toString() + account.account_num.toString()
                                  );
                                  setDropdown('roles');
                                }
                              }}
                              className='laminate-select-down laminates-svg'
                            />
                          }
                        </div>
                        {(
                          showRoles ===
                            account.user_ID.toString() + account.account_num.toString() &&
                          dropdown === 'roles'
                        ) ?
                          <div className='accountroles-dropdown'>
                            {Object.keys(account.AccountRoles).map((role, index) => (
                              <div
                                key={index}
                                style={{
                                  display: 'flex',
                                  cursor: 'default',
                                }}
                              >
                                <input
                                  type='checkbox'
                                  checked={account.AccountRoles[role] === 1}
                                  style={{ cursor: 'pointer' }}
                                  onChange={(event) =>
                                    handleRoleChange(
                                      account.user_ID.toString(),
                                      account.account_num.toString(),
                                      role,
                                      event.target.checked,
                                      'roles'
                                    )
                                  }
                                />
                                <div>{role}</div>
                              </div>
                            ))}
                          </div>
                        : ''}
                        <div
                          role='button'
                          aria-label='Permissions'
                          tabIndex={0}
                          onClick={() => {
                            if (
                              showRoles ===
                                account.user_ID.toString() + account.account_num.toString() &&
                              dropdown === 'permissions'
                            ) {
                              setShowRoles();
                              setDropdown();
                            } else {
                              setShowRoles(
                                account.user_ID.toString() + account.account_num.toString()
                              );
                              setDropdown('permissions');
                            }
                          }}
                          className={`ma input-holder`}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              if (
                                showRoles ===
                                  account.user_ID.toString() + account.account_num.toString() &&
                                dropdown === 'permissions'
                              ) {
                                setShowRoles();
                                setDropdown();
                              } else {
                                setShowRoles(
                                  account.user_ID.toString() + account.account_num.toString()
                                );
                                setDropdown('permissions');
                              }
                            }
                          }}
                        >
                          <div className='select-down'>Permissions</div>
                          {(
                            showRoles ===
                              account.user_ID.toString() + account.account_num.toString() &&
                            dropdown === 'permissions'
                          ) ?
                            <BiChevronUp
                              onClick={() => {
                                setShowRoles();
                                setDropdown();
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  setShowRoles();
                                  setDropdown();
                                }
                              }}
                              className='laminate-select-down laminates-svg'
                            />
                          : <BiChevronDown
                              onClick={() => {
                                setShowRoles(
                                  account.user_ID.toString() + account.account_num.toString()
                                );
                                setDropdown('permissions');
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  setShowRoles(
                                    account.user_ID.toString() + account.account_num.toString()
                                  );
                                  setDropdown('permissions');
                                }
                              }}
                              className='laminate-select-down laminates-svg'
                            />
                          }
                        </div>
                        {(
                          showRoles ===
                            account.user_ID.toString() + account.account_num.toString() &&
                          dropdown === 'permissions'
                        ) ?
                          <div
                            style={{ marginLeft: '7.9rem' }}
                            className='accountroles-dropdown'
                          >
                            {Object.keys(account.Permissions).map((role, index) => (
                              <div
                                key={index}
                                style={{
                                  display: 'flex',
                                  cursor: 'default',
                                }}
                              >
                                <input
                                  type='checkbox'
                                  checked={account.Permissions[role] === 1}
                                  style={{ cursor: 'pointer' }}
                                  onChange={(event) =>
                                    handleRoleChange(
                                      account.user_ID.toString(),
                                      account.account_num.toString(),
                                      role,
                                      event.target.checked,
                                      'permissions'
                                    )
                                  }
                                />
                                <div>{role}</div>
                              </div>
                            ))}
                          </div>
                        : ''}
                        <AiOutlineCloseCircle
                          onClick={() => removeAccount(account.account_num)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              removeAccount(account.account_num);
                            }
                          }}
                          className='remove-button'
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            : ''}
          </div>
          <div style={{ padding: '0rem 19.78rem' }}>
            <button
              className='close-popup'
              onClick={() => {
                saveAccount();
                setOpenAccount(false);
                getData();
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  saveAccount();
                  setOpenAccount(false);
                  getData();
                }
              }}
            >
              SAVE & CLOSE
            </button>
          </div>
          {notification ?
            <div
              style={{
                fontSize: '16px',
                top: '47.7rem',
                marginLeft: '4.8rem',
                width: '520px',
              }}
              className='notice'
            >
              {notification}
            </div>
          : ''}
        </div>
      </div>
    </>
  );
};
export default ManageAccount;
