import { useEffect, useState } from 'react';
import useExportToExcel from '../../Components/Shared/useExportToExcel';
import useAxiosJWT from '../../hooks/useAxiosJWT';
const ExportForDesign = () => {
  const axiosJWT = useAxiosJWT();
  const [allData, setAllData] = useState();
  const [notification, setNotification] = useState();
  function getData() {
    axiosJWT.get(`adminPortal/getPricer`).then((response) => {
      setAllData(response.data);
    });
  }
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  const ExportToExcel = useExportToExcel();
  function exportForDesignHandler(tableName) {
    const data = allData[tableName];
    if (data) {
      ExportToExcel(
        data,

        tableName
      );
    } else {
      setNotification('No Data');
    }
  }
  return (
    <div className='adminDiv'>
      <h2>Export for Design</h2>

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '75rem',
          margin: 'auto',
          justifyContent: 'space-between',
        }}
      >
        {allData && allData.pricer && (
          <button
            className='editor-button'
            onClick={() => {
              exportForDesignHandler('pricer');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                exportForDesignHandler('pricer');
              }
            }}
          >
            Pricer
          </button>
        )}
        {allData && allData.quickship && (
          <button
            className='editor-button'
            onClick={() => {
              exportForDesignHandler('quickship');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                exportForDesignHandler('quickship');
              }
            }}
          >
            Quick Ship
          </button>
        )}
        {allData && allData.custom && (
          <button
            className='editor-button'
            onClick={() => {
              exportForDesignHandler('custom');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                exportForDesignHandler('custom');
              }
            }}
          >
            Custom
          </button>
        )}

        {allData && allData.newYearPricer && (
          <button
            className='editor-button'
            onClick={() => {
              exportForDesignHandler('newYearPricer');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                exportForDesignHandler('newYearPricer');
              }
            }}
          >
            New Year Pricer
          </button>
        )}
        {allData && allData.newYearQuickship && (
          <button
            className='editor-button'
            onClick={() => {
              exportForDesignHandler('newYearQuickship');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                exportForDesignHandler('newYearQuickship');
              }
            }}
          >
            New Year Quick Ship Pricer
          </button>
        )}
        {allData && allData.newYearCustom && (
          <button
            className='editor-button'
            onClick={() => {
              exportForDesignHandler('newYearCustom');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                exportForDesignHandler('newYearCustom');
              }
            }}
          >
            New Year Custom Pricer
          </button>
        )}
      </div>
      {notification ?
        <div className='notice'>{notification}</div>
      : ''}
    </div>
  );
};

export default ExportForDesign;
