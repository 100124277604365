import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { FaCheckCircle, FaEnvelope } from 'react-icons/fa';
import { FaEye, FaEyeSlash } from 'react-icons/fa6';
import { RiErrorWarningFill, RiLock2Fill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useAxiosJWT from '../../hooks/useAxiosJWT';

const LOGIN_URL = '/auth';
const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

const LoginForm = ({
  username,
  setUsername,
  password,
  setPassword,
  setDisplayLogin,
  setDisplayBoolean,
  setShowSignup,
  setShowHelp,
  requestSent,
  setRequestSent,
  errMsg,
  setErrMsg,
}) => {
  const navigate = useNavigate();
  const axiosJWT = useAxiosJWT();
  const errRef = useRef();
  const { setAuth } = useAuth();
  const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
  const EMAIL_REGEX = /\S+@\S+\.\S+/;
  const [validUser, setValidUser] = useState(false);
  const [validPass, setValidPass] = useState(false);
  const [userFocus, setUserFocus] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [toggleEye, setToggleEye] = useState(false);
  const gotoResetPassword = () => {
    setDisplayBoolean(false);
    navigate(`/reset-password`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${apiAddress}${LOGIN_URL}`,
        JSON.stringify({ username, password }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );
      const accessToken = response?.data?.accessToken;
      const rep = response?.data?.rep;
      const dealer = response?.data?.dealer;
      const phone = response?.data?.phone;
      const roles = response?.data?.roles;
      const accounts = response?.data?.accounts;
      setAuth({ rep, dealer, phone, roles, accessToken, accounts });
      setUsername('');
      setPassword('');
      setDisplayBoolean(false);
      setErrMsg('');
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username or Password');
      } else if (err.response?.status === 401) {
        setErrMsg('Incorrect Username or Password');
      } else if (err?.response?.data?.message) {
        setErrMsg(err?.response?.data?.message);
      } else {
        setErrMsg('Login Failed');
      }
      errRef?.current?.focus();
    }
  };

  function reset() {
    setDisplayLogin(false);
    setShowSignup(false);
    setShowHelp(false);
    setRequestSent(false);
    setErrMsg('');
  }

  function requestActivation() {
    axiosJWT
      .post(`auth/requestActivation`, {
        username: username,
      })
      .then(() => {
        setRequestSent(true);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    setValidUser(EMAIL_REGEX.test(username));
  }, [username]);

  useEffect(() => {
    setValidPass(PWD_REGEX.test(password));
  }, [password]);

  return (
    <>
      {!requestSent ?
        <div>
          <div
            style={{ marginTop: '10px' }}
            className={`${errMsg === 'Inactive User' ? 'error-msg-box' : ''}`}
          >
            <div className='error-msg-text'>{errMsg ? `${errMsg}` : ''}</div>
            {errMsg === 'Inactive User' && (
              <button
                onClick={() => requestActivation()}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    requestActivation();
                  }
                }}
                className='request-activation-button submit'
              >
                Request Activation
              </button>
            )}
          </div>
          <form className='login-form'>
            <div className={`login-input-holder`}>
              <div className={`dealer-icon ${validUser ? 'valid-icon' : ''}`}>
                <FaEnvelope />
              </div>
              <lable
                htmlFor='email'
                className='html-hidden-label'
              >
                Email
              </lable>
              <input
                type='email'
                name='email'
                placeholder='Email'
                id='email'
                autoComplete='email'
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                onFocus={() => {
                  setUserFocus(true);
                }}
                className={
                  userFocus && !validUser ? 'error-input'
                  : validUser ?
                    'valid-input'
                  : ''
                }
                required
              ></input>
              {validUser ?
                <div className='dealer-msg valid'>
                  <FaCheckCircle />
                </div>
              : userFocus ?
                <div className='dealer-msg error'>
                  <RiErrorWarningFill />
                </div>
              : <></>}
            </div>
            <div className='error-msg-text'>
              {userFocus && !validUser ? 'Required field is missing.' : ''}
            </div>
            <div className={`login-input-holder`}>
              <div className={`dealer-icon ${validPass ? 'valid-icon' : ''}`}>
                <RiLock2Fill />
              </div>
              <lable
                htmlFor='password'
                className='html-hidden-label'
              >
                Password
              </lable>
              <input
                // style={{ width: '220px' }}
                type={toggleEye ? 'text' : 'password'}
                name='password'
                placeholder='Password'
                id='password'
                autoComplete='current-password'
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                onFocus={() => {
                  setPwdFocus(true);
                }}
                className={
                  pwdFocus && !validPass ? 'error-input'
                  : validPass ?
                    'valid-input'
                  : ''
                }
                required
              ></input>
              <div className='show-password-icon-div'>
                {!toggleEye ?
                  <FaEye
                    className={validPass || pwdFocus ? 'shifted-password-eye' : 'password-eye'}
                    onClick={() => setToggleEye(true)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setToggleEye(true);
                      }
                    }}
                  />
                : <FaEyeSlash
                    className={validPass || pwdFocus ? 'shifted-password-eye' : 'password-eye'}
                    onClick={() => setToggleEye(false)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setToggleEye(true);
                      }
                    }}
                  />
                }
              </div>{' '}
              {validPass ?
                <div className='dealer-msg valid'>
                  <FaCheckCircle />
                </div>
              : pwdFocus ?
                <div className='dealer-msg error'>
                  <RiErrorWarningFill />
                </div>
              : <></>}
            </div>
            <div className='error-msg-text'>
              {pwdFocus && !validPass ? 'Required field is missing.' : ''}
            </div>
            <button
              type='submit'
              className='submit'
              onClick={(e) => handleSubmit(e)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  (e) => {
                    handleSubmit(e);
                  };
                }
              }}
            >
              SIGN IN
            </button>
          </form>
          <p className='need-account'>Forgot Your Password?</p>
          <h3
            role='button'
            aria-label='Reset Password'
            tabIndex={0}
            className='sign-up-link'
            onClick={gotoResetPassword}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                gotoResetPassword();
              }
            }}
          >
            Reset Password
          </h3>
          <p className='need-account'>Need an Account?</p>
          <h3
            role='button'
            aria-label='Sign Up'
            tabIndex={0}
            className='sign-up-link'
            onClick={reset}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                reset();
              }
            }}
          >
            SIGN UP
          </h3>
        </div>
      : <h3
          style={{ marginTop: '10px' }}
          className='need-account'
        >
          Request Sent!
        </h3>
      }
    </>
  );
};

export default LoginForm;
