import { useState } from 'react';
import '../../CSS/NPSportal/reusableComponents.css';

const BasicButton = ({
  text,
  type,
  onClick = false,
  disabled = false,
  icon = false,
  buttonStyle = false,
}) => {
  const [clicked, setClicked] = useState(false);

  return (
    <div
      role='button'
      aria-label={text}
      tabIndex={0}
      onClick={() => {
        setClicked(!clicked);
        if (onClick && !disabled) {
          onClick();
        }
      }}
      className={` ${disabled ? 'disabled-basic-button' : type} basic-button-design `}
      style={buttonStyle || {}}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setClicked(!clicked);
          if (onClick && !disabled) {
            onClick();
          }
        }
      }}
    >
      {icon ?
        <img
          className='button-icon'
          src={`${icon}`}
          alt={`Check out the ${text}`}
        />
      : ''}
      <div>{text}</div>
    </div>
  );
};

export default BasicButton;
