import { useNavigate } from 'react-router-dom';
import '../../CSS/DealerLogin.css';

const Registered = ({ setDisplayBoolean, setDisplayLogin, setShowRegistered, form }) => {
  const navigate = useNavigate();

  const clicked = () => {
    setDisplayBoolean(false);
    setShowRegistered(false);
    setDisplayLogin(true);

    navigate('/');
  };
  return (
    <div
      style={{ marginTop: '30px' }}
      className='register-div'
    >
      {form === 'showSignup' ?
        <p className='need-account'>
          Our team will review your application and send you an e-mail when your account has been
          approved
        </p>
      : form === 'showHelp' ?
        <p className='need-account'>
          Our team will review your request and get back to you as soon as possible
        </p>
      : ' '}
      <button
        onClick={clicked}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            clicked();
          }
        }}
        className='green-button-fill'
      >
        Go To Home Page
      </button>
    </div>
  );
};

export default Registered;
