import React, { useEffect, useState } from 'react';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import { AiOutlineCloseCircle } from 'react-icons/ai';

function EditCloudinaryLinks() {
  const axiosJWT = useAxiosJWT();
  const [tables, setTables] = useState();
  const [warning, setWarning] = useState();
  const [loading, setLoading] = useState();
  const [response, setResponse] = useState();

  async function getTables() {
    axiosJWT.get(`adminPortal/getTablesWithCloudinaryLinks`).then((response) => {
      setTables(response.data);
    });
  }

  function handleClick(table) {
    setLoading(true);
    if (table === 'all') {
      axiosJWT
        .post(`adminPortal/updateAllFileLinks`, {
          tables: tables,
        })
        .then((response) => {
          setResponse(response);
          setLoading();
        });
    } else {
      axiosJWT
        .post(`adminPortal/updateFileLinks`, {
          tableName: table.table_name,
          publicIdField: table.public_id_field,
          secureUrlField: table.secure_url_field,
        })
        .then((response) => {
          setResponse(response);
          setLoading();
        });
    }
  }

  useEffect(() => {
    getTables();
  }, []);

  useEffect(() => {
    setResponse();
    setLoading();
  }, [warning]);

  return (
    <div>
      <h2>Update Cloudinary Links</h2>
      <div className='editor-portal-buttons-wrapper'>
        <button
          className='editor-button green-editor-button'
          onClick={() => {
            setWarning('all');
          }}
        >
          Update All
        </button>
        {tables
          ?.sort((a, b) => a.table_name.localeCompare(b.table_name))
          .map((table, index) => (
            <button
              key={index}
              className='editor-button'
              style={{
                fontSize: '16px',
                margin: '6px 0px',
              }}
              onClick={() => {
                setWarning(table);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setWarning(table);
                }
              }}
            >
              {table.table_name}: {table.secure_url_field}
            </button>
          ))}
      </div>
      {warning && (
        <div className='popup'>
          <div className='overlay'></div>
          <div
            className='popup-element'
            style={{
              width: '50rem',
              left: '30%',
              borderColor: 'white',
              borderRadius: '2rem',
              boxShadow: '1px 5px 5px grey',
              position: 'fixed',
              height: '265px',
              marginTop: '15rem',
              padding: '0px 30px',
            }}
          >
            <AiOutlineCloseCircle
              className='close-button'
              onClick={() => {
                setWarning(false);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setWarning(false);
                }
              }}
              style={{ top: '1rem', right: '1rem' }}
            />
            <h2 style={{ color: 'red', textDecorationColor: 'black' }}>WARNING</h2>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {!response && !loading ?
                <>
                  {warning === 'all' ?
                    <p>
                      By clicking update, you will be erasing the cloudinary links in ALL tables and
                      updating it with new data from cloudinary. Are you sure you want to continue?
                    </p>
                  : <p>
                      By clicking update, you will be erasing the entire {warning.secure_url_field}{' '}
                      field in the {warning.table_name} table and updating it with new data from
                      cloudinary. Are you sure you want to continue?
                    </p>
                  }
                  <div className='basic-flex'>
                    <button
                      className='update-images-button'
                      style={{ marginTop: '2rem', cursor: 'pointer' }}
                      onClick={() => {
                        handleClick(warning);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleClick(warning);
                        }
                      }}
                    >
                      Continue
                    </button>
                    <button
                      className='update-images-button'
                      style={{ marginTop: '2rem', cursor: 'pointer' }}
                      onClick={() => {
                        setWarning(false);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setWarning(false);
                        }
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              : loading ?
                <p>Updating...</p>
              : <>
                  <p>
                    {response.statusText === 'OK' ? response.data.message : 'Error: Update failed!'}
                  </p>
                  <button
                    className='update-images-button'
                    style={{ marginTop: '2rem', cursor: 'pointer' }}
                    onClick={() => {
                      setWarning(false);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setWarning(false);
                      }
                    }}
                  >
                    Close
                  </button>
                </>
              }
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditCloudinaryLinks;
