import { useState } from 'react';
import { FaRegBell } from 'react-icons/fa';
import '../../CSS/NPSportal/reusableComponents.css';

const NotificationIcon = ({ texts }) => {
  const [clicked, setClicked] = useState(false);
  return (
    <div
      style={{ position: 'relative' }}
      className='basic-flex'
    >
      <div
        role='button'
        aria-label='Notification Icon'
        tabIndex={0}
        onClick={() => setClicked(!clicked)}
        className={`notification-icon-circle ${clicked ? 'clicked-notifications' : ''}`}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setClicked(!clicked);
          }
        }}
      >
        {texts ?
          <div className='notification-number'>{texts.length}</div>
        : ''}
        <FaRegBell className='bell-icon' />
      </div>
      {clicked && texts && (
        <div
          style={{
            zIndex: '2',
            marginTop: `${(texts.length + 1) * 4}rem`,
            marginLeft: '-13rem',
            padding: '0px',
            width: '265px',
          }}
          onMouseLeave={() => setClicked(false)}
          className='account-dropdown-list-NPS'
        >
          {texts.map((text, index) => (
            <div
              key={index}
              className='notification-text-div'
            >
              <p className='notification-chat-text'>{text.text}</p>
              <p className='notification-chat-hours'> {text.time}h</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NotificationIcon;
