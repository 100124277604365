import { useState } from 'react';
import '../../CSS/NPSportal/reusableComponents.css';

const Tabs = ({ tabs }) => {
  const [clicked, setClicked] = useState(tabs[0].title);

  return (
    <div className='basic-flex'>
      {tabs?.map((tab, index) => (
        <div
          role='button'
          aria-label={tab.title}
          tabIndex={0}
          key={index}
          onClick={() => {
            setClicked(tab.title);
            tab.action();
          }}
          className={` ${clicked === tab.title ? `tab-clicked` : ''} tab `}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setClicked(tab.title);
              tab.action();
            }
          }}
        >
          {tab.title}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
