import React, { useState } from 'react';

const SwatchSample = ({ swatch, name, code, option }) => {
  const [hover, setHover] = useState(false);
  return (
    <>
      <div
        className={`color-swatch ${option.toLowerCase().replace(' ', '-')}`}
        style={{
          backgroundImage: `url("${swatch}")`,
          backgroundSize: 'cover',
        }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        aria-label={`${name} color`}
      >
        {hover ?
          <div className='swatch-hover'>
            {`${option}:`}
            <br></br>
            {`${name} (${code})`}
          </div>
        : ''}
      </div>
    </>
  );
};

export default SwatchSample;
