import { useEffect, useState } from 'react';
import { SlArrowRight } from 'react-icons/sl';
import { useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import '../../CSS/Catalogs.css';
import '../../CSS/MobileApp/M_Catalogs.css';
import DiscoverProductsCard from '../../Components/HomePage/DiscoverProductsCard';
import useGeneralImages from '../../hooks/useGeneralImages';
import ImageSlider from '../../Components/Shared/ImageSlider';
import MediaNavButtons from '../../Components/Media/MediaNavButtons';
import useAxiosJWT from '../../hooks/useAxiosJWT';

const Catalog = () => {
  const { generalImages } = useGeneralImages();
  const [searchParams] = useSearchParams();
  const searchParamsUse = Object.fromEntries([...searchParams]);
  const [currentCatalog, setCurrentCatalog] = useState();
  const navigate = useNavigate();
  const [catalogs, setCatalogs] = useState();
  const [catalogCardsArray, setCatalogCardsArray] = useState([]);
  const [hasError, setHasError] = useState(false);
  const axiosJWT = useAxiosJWT();
  const mobileScreen = useSelector((state) => state.isMobile.value.isMobile);
  const catalogBanner =
    mobileScreen ?
      generalImages.find((i) => i.publicId === 'catalogs_banner')?.imageLink
    : generalImages.find((i) => i.publicId === 'G_NPS_Catalog_dliwdy')?.imageLink;

  useEffect(() => {
    document.title = 'Catalog - National Public Seating';
    setHasError(false);
    axiosJWT
      .get(`/content/getCatalogs`)
      .then((res) => {
        setCatalogs(res.data.catalogs);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (catalogs) {
      setCurrentCatalog(
        catalogs?.filter((i) => i.title === searchParamsUse.catalog).length ?
          catalogs?.filter((i) => i.title === searchParamsUse.catalog)[0]
        : catalogs[0]
      );
    }
  }, [catalogs]);
  useEffect(() => {
    if (catalogs && currentCatalog) {
      var catalogCardsHolder = [];
      catalogs?.map((c) => {
        if (c.title !== currentCatalog?.title) {
          catalogCardsHolder.push(
            <DiscoverProductsCard
              imageUrl={c.img.slice(64)}
              category={c.category}
              itemName={c.title}
              link={c.link}
              key={c.ID}
            />
          );
        }
      });
      setCatalogCardsArray(catalogCardsHolder);
    }
  }, [catalogs, currentCatalog]);

  useEffect(() => {
    if (catalogs) {
      setCurrentCatalog(
        catalogs?.filter((i) => i.title === searchParamsUse.catalog).length ?
          catalogs?.filter((i) => i.title === searchParamsUse.catalog)[0]
        : catalogs[0]
      );
    }
  }, [searchParamsUse.catalog]);

  return (
    <div className='catalogPage'>
      <img
        src={catalogBanner}
        className='banner'
        alt='NPS Catalogs Banner'
      ></img>
      <div className='mediaDownloads'>
        <div className='navigateBar inner-padding'>
          <a href='/media-center'>Media Center</a>
          <SlArrowRight />
          <div className='current'>Catalogs</div>
        </div>
      </div>
      <MediaNavButtons current={'Digital Catalogs'} />
      <div className='catalog inner-padding'>
        <h1>{currentCatalog?.title}</h1>
        <div className='underline'></div>
        <div className='catalog-iframe-outer-div'>
          <div className='catalog-iframe-div'>
            {!hasError ?
              <iframe
                allow={'clipboard-write'}
                sandbox={
                  'allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms'
                }
                allowFullScreen={true}
                style={{ border: 'none', width: '100%', height: '100%' }}
                src={currentCatalog?.link}
                onError={() => setHasError(true)}
                title='iframe catalog'
              ></iframe>
            : <div className='catalog-iframe-error'>
                <div className='catalog-content-unavailable'>CONTENT UNAVAILABLE.</div>
                <div className='catalog-try-again'>Please try again later.</div>
              </div>
            }
          </div>
          <div className='more-catalogs-div'>
            <h2>More Catalogs</h2>
            <div className='underline'></div>
            <div className='catalogs-list'>
              {mobileScreen ?
                <ImageSlider
                  images={catalogCardsArray}
                  numOfDisplay={catalogCardsArray.length + 1}
                />
              : catalogs?.map((i) => {
                  return (
                    <div
                      className='otherCatalogs catalog-tile'
                      key={i.img}
                    >
                      <img
                        src={i.img}
                        alt={i.title}
                      ></img>
                      <div className='catalog-details'>
                        <div className={`catalog-title ${i.title.length > 21 && 'long'}`}>
                          <h4>{i.category}</h4>
                          <h3>{i.title}</h3>
                        </div>
                        <button
                          onClick={() => {
                            navigate({
                              pathname: `/catalog`,
                              search: `?${createSearchParams({
                                catalog: i.title,
                              })}`,
                            });
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              navigate({
                                pathname: `/catalog`,
                                search: `?${createSearchParams({
                                  catalog: i.title,
                                })}`,
                              });
                            }
                          }}
                        >
                          View Now
                        </button>
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Catalog;
