import { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import Paginator from '../../Components/Media/Paginator';
import LoadingIcon from '../../Components/Shared/LoadingIcon';
import '../../CSS/ItemTable.css';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import ItemTableData from './ItemTableData';
import ItemTableHeader from './ItemTableHeader';
const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

function ItemsTable({
  getData,
  tableName,
  tableColumns,
  tableRows,
  allowAdd = false,
  setNotification,
  setActionData,
}) {
  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState();
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState();
  const [show, setShow] = useState(32);

  const axiosJWT = useAxiosJWT();

  async function getRows() {
    let newRows = [];
    tableRows?.map((row) => {
      if (Object.keys(filter).length) {
        let match = true;
        Object.keys(filter).forEach((fKey) => {
          if (
            !(
              Object.keys(row)?.includes(fKey) &&
              (tableColumns?.find((i) => i.value === fKey)?.type === 'boolean' ?
                filter[fKey].includes(row[fKey] === 1 ? 'true' : 'false')
              : filter[fKey].includes(row[fKey]) || filter[fKey].includes(row[fKey]?.name))
            )
          ) {
            match = false;
          }
        });
        if (match) {
          newRows.push(row);
        }
      } else {
        newRows.push(row);
      }
    });
    if (sort) {
      newRows = getSortedRows(newRows);
    }
    if (search) {
      newRows = getSearchRows(newRows);
    }
    setRows(newRows);
  }

  function getSortedRows(rows) {
    if (sort.column.includes('Date')) {
      rows.sort((a, b) => {
        const dateA = a[sort.column] ? new Date(a[sort.column]) : new Date(0);
        const dateB = b[sort.column] ? new Date(b[sort.column]) : new Date(0);
        if (sort.direction === 'Ascending') {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });
    } else if (typeof rows[0][sort.column] === 'number') {
      sort.direction === 'Ascending' ?
        rows.sort((a, b) => a[sort.column] - b[sort.column])
      : rows.sort((a, b) => b[sort.column] - a[sort.column]);
    } else if (typeof rows[0][sort.column] === 'object') {
      sort.direction === 'Ascending' ?
        rows.sort((a, b) => a[sort.column]?.name?.localeCompare(b[sort.column]?.name))
      : rows.sort((a, b) => b[sort.column]?.name?.localeCompare(a[sort.column]?.name));
    } else {
      sort.direction === 'Ascending' ?
        rows.sort((a, b) => a[sort.column].localeCompare(b[sort.column]))
      : rows.sort((a, b) => b[sort.column].localeCompare(a[sort.column]));
    }
    return rows;
  }

  function getSearchRows(rows) {
    rows = rows.filter((row) => {
      return Object.keys(row).some((key) => {
        const value = row[key];
        if (typeof value === 'string' && value.toLowerCase().includes(search.toLowerCase())) {
          return true;
        } else if (typeof value === 'number' && value.toString().includes(search)) {
          return true;
        } else if (typeof value === 'object' && value !== null) {
          // If the value is an object, check its sub values
          return Object.values(value).some((subValue) => {
            if (
              typeof subValue === 'string' &&
              subValue.toLowerCase().includes(search.toLowerCase())
            ) {
              return true;
            } else if (typeof subValue === 'number' && subValue.toString().includes(search)) {
              return true;
            }
            return false;
          });
        }
        return false;
      });
    });
    return rows;
  }

  function removeHandler(row) {
    axiosJWT
      .post(`${apiAddress}/itemTable/removeItemData`, {
        identifyingColumn:
          tableColumns?.find((i) => i.value === 'ID')?.value || tableColumns[0]?.value,
        identifyingValue:
          row[tableColumns?.find((i) => i.value === 'ID')?.value || tableColumns[0].value],
        table: tableColumns[0]?.db_table_name,
      })
      .then(() => {
        setNotification('Successfully Removed');
        getData();
      })
      .catch((err) => {
        setNotification('An Error Occured');
        console.error(err);
      });
  }

  useEffect(() => {
    document.title = `${tableName} - National Public Seating`;
  }, []);

  useEffect(() => {
    setFilter({});
    setSort();
    setSearch();
  }, []);

  useEffect(() => {
    getRows();
  }, [tableRows, filter, sort, search]);

  useEffect(() => {
    if (rows?.length > 0) {
      setLastPage(Math.ceil(rows.length / show));
    }
    setPage(0);
  }, [rows]);

  return (
    <div className='item-table'>
      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
            height: '3027px',
          }}
        >
          <LoadingIcon />
        </div>
      )}
      {tableRows?.length <= 0 && !loading && (
        <div className='no-rows-found'>We're sorry, there are no results for your search.</div>
      )}

      {tableRows?.length > 0 && (
        <table className={`item-table ${loading ? 'loading' : ''}`}>
          <thead>
            <ItemTableHeader
              columns={tableColumns}
              allRows={tableRows}
              filter={filter}
              setFilter={setFilter}
              sort={sort}
              setSort={setSort}
              setLoading={setLoading}
              allowAdd={allowAdd}
            />
          </thead>
          <tbody>
            {rows?.length ?
              rows?.slice(show * page, show * page + show).map((row, i) => {
                return (
                  <tr
                    key={i}
                    className={`${
                      Object.values(row)?.some((i) => i < 0) && tableName === 'Item Inventory' ?
                        'red-row'
                      : ''
                    } item-row`}
                  >
                    {tableColumns
                      ?.filter((i) => i.display === 1)
                      ?.map((column, index) => {
                        return (
                          <td
                            className={`style-${column.type}`}
                            key={index}
                          >
                            <ItemTableData
                              setRows={setRows}
                              table={tableName}
                              identifyingColumn={tableColumns[0]}
                              identifyingValue={row[tableColumns[0].value]}
                              row={row}
                              column={column}
                              allowAdd={allowAdd}
                              setActionData={setActionData}
                              setNotification={setNotification}
                            />
                          </td>
                        );
                      })}
                    {allowAdd && (
                      <td>
                        <FaTrash
                          className='garbage'
                          onClick={() => {
                            removeHandler(row);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              removeHandler(row);
                            }
                          }}
                        />
                      </td>
                    )}
                  </tr>
                );
              })
            : <tr>
                <td colSpan='8'>
                  <span className='no-rows-found'>
                    We're sorry, there are no results for your search.
                    <span
                      role='button'
                      aria-label='View All'
                      tabIndex={0}
                      onClick={() => {
                        setFilter({});
                        setSearch();
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setFilter({});
                          setSearch();
                        }
                      }}
                    >
                      View All
                    </span>
                  </span>
                </td>
              </tr>
            }
          </tbody>
        </table>
      )}
      {rows.length > 16 && (
        <Paginator
          currentPage={page}
          totalPages={lastPage}
          setCurrentPage={setPage}
          setShow={setShow}
          show={show}
        />
      )}
    </div>
  );
}

export default ItemsTable;
