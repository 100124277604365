import Axios from 'axios';
import { useEffect, useState } from 'react';
import '../../CSS/About.css';
import Symbol from '../../Components/About/Symbol';
import Value from '../../Components/About/Value';
import ImageSlider from '../../Components/Shared/ImageSlider';
import useGeneralImages from '../../hooks/useGeneralImages';
const About = () => {
  const { generalImages } = useGeneralImages();
  const [aboutValues, setAboutValues] = useState([]);
  const [symbolElements, setSymbolElements] = useState();
  const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;
  const barry = generalImages.find((i) => i.publicId === 'barry_kjptmw')?.imageLink;
  const banner = generalImages.find((i) => i.publicId === 'aboutBanner_ddikok')?.imageLink;

  function getAboutValues() {
    Axios.get(`${apiAddress}/content/getAboutValues`)
      .then((response) => {
        setAboutValues(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getAboutSymbols() {
    Axios.get(`${apiAddress}/content/getAboutSymbols`)
      .then((response) => {
        var holderArray = [];
        response.data?.map((i) => {
          holderArray.push(<Symbol data={i} />);
        });
        setSymbolElements(holderArray);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    document.title = `About Us - National Public Seating`;
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    getAboutValues();
    getAboutSymbols();
  }, []);

  return (
    <div className='about-us-page'>
      <div className='about-banner'>
        <img
          src={banner}
          alt='about us'
        ></img>
      </div>
      <div className='ourValues'>
        <h1>Our Values</h1>
        <div className='underline'></div>
        <div className='about-values'>
          {aboutValues?.map((item, index) => {
            return (
              <Value
                key={index}
                data={item}
              />
            );
          })}
        </div>
      </div>
      <div className='inner-padding symbols'>
        <h2>LOOK FOR THESE SYMBOLS THROUGHOUT THE WEBSITE</h2>
        <div className='underline'></div>
        <div className='symbol-slider'>
          {symbolElements ?
            <ImageSlider
              images={symbolElements}
              numOfDisplay={5}
            />
          : ''}
        </div>
      </div>
      <div className='barry'>
        <img
          src={barry}
          alt='Barry Stauber'
        />
        <div className='line'></div>
        <div className='details'>
          <h1>A Tribute to Our Founder</h1>
          <h2>
            <span className='bold'>Barry Stauber</span> built up National Public Seating with his
            bare hands and his whole heart.
          </h2>
          <div className='underline'></div>
          <p>
            He saw his customers as real people and felt personally responsible for providing them a
            seamless experience. To Barry, bumps in the road were golden opportunities to make
            things right, and he never let an opportunity pass him by. But it wasn't just consumers
            he cared for. Even as the team grew, he embraced every member as family. He invested in
            his employees the same way he invested in the business, teaching them to go above and
            beyond in their work with kindness, compassion and deep respect. <br></br>
            <br></br>On August 17th 2018, we were heartbroken to learn Barry had passed. Two years
            prior when preparing to retire, he had transitioned the staff and trained in a new
            president. In true Barry fashion, he had ensured every aspect of the operation could run
            according to plan without him. Now, every day at National Public Seating is a tribute to
            his legacy. The company he left behind is rooted in integrity, commitment and growth,
            and through it he lives on.
          </p>
          <button
            onClick={() => {
              generalImages.find((i) => i.publicId === 'Tribute_to_Barry_Stauber_1_sedjmf')
                ?.imageLink;
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                window.open(
                  generalImages.find((i) => i.publicId === 'Tribute_to_Barry_Stauber_1_sedjmf')
                    ?.imageLink
                );
              }
            }}
          >
            Barry's Tribute
          </button>
        </div>
        <div className='line'></div>
      </div>
      <div style={{ height: '80rem' }}></div>
    </div>
  );
};

export default About;
