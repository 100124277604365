import { useState } from 'react';
import { HiUserCircle } from 'react-icons/hi2';
import { MdKeyboardArrowDown } from 'react-icons/md';
import '../../CSS/NPSportal/reusableComponents.css';
import useAuth from '../../hooks/useAuth';

import useChangeAccount from '../../hooks/changeAccount';

const AccountDropdown = () => {
  const { auth } = useAuth();
  const changeAccount = useChangeAccount();
  const [openList, setOpenList] = useState(false);

  return (
    <div>
      <div
        role='button'
        aria-label='Account Number'
        tabIndex={0}
        onClick={() => {
          if (auth?.accounts?.length > 1) {
            setOpenList(!openList);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (auth?.accounts?.length > 1) {
              setOpenList(!openList);
            }
          }
        }}
        className={`account-dropdown-tab ${openList ? 'open-list' : ''}`}
      >
        <div className='basic-flex'>
          <HiUserCircle className='account-dropdown-user' />
          <div className='account-dropdown-chosen'>
            Account #{auth?.accounts?.find((i) => i.last_login === 1)?.account_num}
          </div>
        </div>

        {auth?.accounts?.length > 1 && (
          <MdKeyboardArrowDown
            className={`account-dropdown-arrow ${openList ? 'rotate-up' : 'rotate-down'}`}
          />
        )}
      </div>
      {openList && (
        <div className='account-dropdown-list-NPS'>
          {auth?.accounts
            ?.filter((i) => i.last_login !== 1)
            ?.map((i, index) => (
              <div
                role='button'
                aria-label={`Account #${i.account_num}`}
                tabIndex={0}
                key={index}
                onClick={() => changeAccount(i.account_num)}
                className='listed-account'
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    changeAccount(i.account_num);
                  }
                }}
              >
                Account #{i.account_num}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default AccountDropdown;
