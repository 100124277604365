import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import '../../CSS/Calendar.css';
import '../../CSS/DealerCenter.css';
import DealerCube from '../../Components/Dealer Portal/DealerCube';
import DealerBanner from '../../assets/SVG/ReactSvg/DealerBanner';
import useAuth from '../../hooks/useAuth';

const DealerPortal = () => {
  const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;
  const { auth } = useAuth();
  const [data, setData] = useState();
  useEffect(() => {
    document.title = 'Dealer Portal - National Public Seating';
  }, []);
  const navigate = useNavigate();
  function clickHandler(i) {
    navigate(`/${i.link}`);
  }
  function getData() {
    Axios.post(`${apiAddress}/content/getDropDownTabs`, {
      tabName: 'Dealer',
    }).then((response) => {
      (
        auth?.accounts
          ?.find((i) => i.last_login === 1)
          ?.AccountRoles?.some((role) => [1100, 1200].includes(role))
      ) ?
        setData(response.data.dropdown.filter((i) => i.tab.toLowerCase() === 'dealer'))
      : setData(
          response.data.dropdown
            .filter((i) => i.tab.toLowerCase() === 'dealer')
            .filter((i) => i.link !== 'account-users')
        );
    });
  }
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className='mediaCenter dealerPortal'>
      <div className='banner'>
        <DealerBanner />
      </div>

      <div
        style={{
          background: 'rgb(112, 112, 112, .06)',
          width: '100%',
          position: 'absolute',
          paddingTop: '80px',
          paddingBottom: '80px',
          left: '0',
          marginTop: '-2.5rem',
        }}
      >
        <div
          style={{ maxWidth: '1065px' }}
          className='inner-padding mediaCenterButtons'
        >
          {data?.map((i, index) => {
            return (
              <DealerCube
                key={index}
                data={i}
                clickHandler={clickHandler}
              />
            );
          })}
        </div>
      </div>
      <div
        className='placeholder'
        style={{ height: '1520px' }}
      ></div>
    </div>
  );
};

export default DealerPortal;
