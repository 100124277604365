import { FaCircle, FaRegCircle } from 'react-icons/fa';

function CarouselDots({ images, index, setIndex }) {
  return (
    <div className='carouselDots'>
      {images?.length > 1 ?
        <div className='imagePointer'>
          {images?.map((i, indexIn) =>
            index === indexIn ?
              <FaCircle
                key={indexIn}
                className='circle'
                onClick={() => {
                  setIndex(indexIn);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setIndex(indexIn);
                  }
                }}
              />
            : <FaRegCircle
                key={indexIn}
                className='circle'
                onClick={() => {
                  setIndex(indexIn);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setIndex(indexIn);
                  }
                }}
              />
          )}
        </div>
      : ''}
    </div>
  );
}

export default CarouselDots;
