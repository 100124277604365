import { useStageInfo } from '../../context/StageProvider';

function SidebarHeader() {
  const { buildStep, setBuildStep, stageBuild, guardrails, cartProducts, setWarning } =
    useStageInfo();
  const steps = [
    {
      id: 1,
      text: '1. CREATE BUILD',
    },
    {
      id: 2,
      text: '2. ADD ACCESSORIES',
    },
    {
      id: 3,
      text: '3. ADD TO CART',
    },
  ];
  return (
    <div className='stage-sidebar-header'>
      {steps?.map((s, index) => (
        <div
          role='button'
          aria-label={s.text}
          tabIndex={0}
          key={index}
          className={`sidebar-step-title step${s.id} ${buildStep === s.id ? 'current-step' : ''}`}
          style={{
            cursor:
              (
                (stageBuild?.length === 0 && s.id > 1) ||
                (((guardrails?.length && !guardrails[0]?.product) || !cartProducts?.length) &&
                  s.id === 3)
              ) ?
                'not-allowed'
              : 'pointer',
          }}
          onClick={() => {
            if (s.id === 1 && buildStep !== 1) {
              setWarning(true);
            } else if (
              stageBuild.length > 0 &&
              (s.id < 3 || ((!guardrails.length || guardrails[0]?.product) && cartProducts?.length))
            ) {
              setBuildStep(s.id);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (s.id === 1 && buildStep !== 1) {
                setWarning(true);
              } else if (
                stageBuild.length > 0 &&
                (s.id < 3 ||
                  ((!guardrails.length || guardrails[0]?.product) && cartProducts?.length))
              ) {
                setBuildStep(s.id);
              }
            }
          }}
        >
          {s.text}
        </div>
      ))}
    </div>
  );
}

export default SidebarHeader;
