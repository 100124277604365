import useGeneralImages from '../hooks/useGeneralImages';

const CatalogsPricer = () => {
  const { generalImages } = useGeneralImages();
  return (
    <div className='inner-padding'>
      <div
        style={{
          position: 'relative',
          paddingTop: 'max(60%,326px)',
          height: '0',
          width: '100%',
        }}
      >
        <iframe
          allow='clipboard-write'
          sandbox='allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms'
          allowFullScreen={true}
          style={{
            position: 'absolute',
            border: 'none',
            width: '100%',
            height: '100%',
            left: '0',
            right: '0',
            top: '0',
            bottom: '0',
          }}
          src={'https://e.issuu.com/embed.html?d=nps_catalog_2022-2023&u=npscorp'}
          title='catalog'
        ></iframe>
      </div>
      <div
        role='button'
        aria-label='Click here for pricer'
        tabIndex={0}
        onClick={() => {
          window.open(
            generalImages.find((i) => i.publicId === 'NPS_2023_Price_List_DI_vebaql')?.imageLink
          );
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            window.open(
              generalImages.find((i) => i.publicId === 'NPS_2023_Price_List_DI_vebaql')?.imageLink
            );
          }
        }}
        style={{
          textAlign: 'center',
          fontSize: '24px',
          textTransform: 'uppercase',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
      >
        Click here for pricer
      </div>
    </div>
  );
};

export default CatalogsPricer;
