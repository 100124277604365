import { IoCloseCircleOutline } from 'react-icons/io5';
import ReactPlayer from 'react-player';
import '../../CSS/VideoPlayer.css';
import BasicButton from '../NPSportal/basicButton';
const VideoPlayer = ({ videoSrc, setShow, autoPlay = false, actionButton = undefined }) => {
  return (
    <div className='video-popup'>
      <div className='video-player'>
        <IoCloseCircleOutline
          className='closeButton'
          onClick={() => {
            setShow(false);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setShow(false);
            }
          }}
        />
        <ReactPlayer
          url={videoSrc}
          // className="react-player"
          width={actionButton ? '1230px' : '1314px'}
          height={'738px'}
          controls
          playing={autoPlay}
        />
        {actionButton && (
          <BasicButton
            text={actionButton.title}
            buttonStyle={{ height: '3rem', marginBottom: '1rem' }}
            type={'white-bordered-button'}
            onClick={actionButton.action}
          />
        )}
      </div>
      <div className='modal'></div>
    </div>
  );
};

export default VideoPlayer;
