import { useState } from 'react';
import { PiDotOutlineFill } from 'react-icons/pi';

const SkuHover = ({ skuComponent, skuStatus }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      key={skuComponent.hover}
      className={`sku-hover-div ${skuStatus === 'Not Completed' ? 'not-completed' : 'completed'}`}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      {skuComponent.value}
      {hover ?
        <div className='hover-on'>
          <div className='hover-on-div'>
            <PiDotOutlineFill className='sku-hover-dot' />
            <div className='sku-hover-property'>{skuComponent.hover}</div>
          </div>
        </div>
      : ''}
    </div>
  );
};

export default SkuHover;
