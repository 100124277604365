import decodeCloudinary from '../../hooks/decodeCloudinary';
import useExportToExcel from '../Shared/useExportToExcel';

const DownloadExcel = ({ mediaList, imageEffects, applyEffects }) => {
  const decodeCloudinaryURL = decodeCloudinary();
  const ExportToExcel = useExportToExcel();
  const createFilters = async () => {
    if (
      Object.keys(imageEffects)
        .filter((i) => i !== 'type')
        .filter((i) => imageEffects[i] !== '').length > 0
    ) {
      let string = `/${Object.keys(imageEffects)
        .filter((i) => i !== 'type')
        .filter((i) => imageEffects[i] !== '')
        .map((key) => {
          return `${key}_${imageEffects[key]}`;
        })
        .join(',')}`;
      if (!Object.keys(imageEffects).includes('q')) {
        string = `${string},q_auto`;
      }
      return string;
    }
    return '';
  };

  async function mapMediaList() {
    var mappedMediaList = [];
    let filterString = '';

    if (applyEffects) {
      filterString = await createFilters();
    }
    for (var i in mediaList) {
      var holderObject = { SKU: i };
      for (var j = 0; j < mediaList[i].imageLinks.length; j++) {
        let newImage;
        if (Object.keys(imageEffects).includes('type') && imageEffects.type !== '') {
          newImage =
            mediaList[i].imageLinks[j].imageLink.substring(
              0,
              mediaList[i].imageLinks[j].imageLink.lastIndexOf('.') + 1
            ) + imageEffects.type;
        } else {
          newImage = mediaList[i].imageLinks[j].imageLink;
        }
        holderObject[`Image${j + 1}`] =
          `https://res.cloudinary.com/da3rom333/image/upload${filterString}/${decodeCloudinaryURL(
            newImage
          )}`;
      }

      for (let k = 0; k < mediaList[i]?.pdfLinks?.length; k++) {
        holderObject[`PDF${k + 1}`] = `${mediaList[i].pdfLinks[k].imageLink}`;
      }
      for (let l = 0; l < mediaList[i]?.videoLinks?.length; l++) {
        holderObject[`Video${l + 1}`] = `${
          mediaList[i].videoLinks[l][Object.keys(mediaList[i].videoLinks[l])[0]]
        }`;
      }
      mappedMediaList.push(holderObject);
    }
    return mappedMediaList;
  }

  async function downloadLinks() {
    var mappedMediaList = await mapMediaList();
    ExportToExcel(mappedMediaList, 'media list');
  }

  return (
    <button
      onClick={() => {
        downloadLinks();
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          downloadLinks();
        }
      }}
    >
      Download List of All Media Links
    </button>
  );
};

export default DownloadExcel;
