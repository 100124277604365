import React from 'react';
import useGeneralImages from '../../hooks/useGeneralImages';

const InstantFreightLoading = () => {
  const { generalImages } = useGeneralImages();
  const truckAnimation = generalImages.find(
    (i) => i.publicId === 'truck-animation_miwg1h'
  )?.imageLink;
  return (
    <>
      <div
        className='form'
        style={{
          backgroundImage: `url(${truckAnimation})`,
        }}
        aria-label='Animated GIF of a truck driving across the screen'
      ></div>
      <h4 className='load-f-qoute'>Generating Freight Quote</h4>
    </>
  );
};

export default InstantFreightLoading;
