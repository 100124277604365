import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import RequireAuth from '../Login/RequireAuth';
import EditTemplate from './EditTemplate';

const EditTable = () => {
  const [editTemplateParams, setEditTemplateParams] = useState({});
  const { tableName } = useParams();
  const auth = useAuth();
  const axiosJWT = useAxiosJWT();

  async function getEditTableData() {
    axiosJWT
      .post('adminPortal/getTableParams', { table: tableName })
      .then((res) => {
        var { table_name: table, ...rest } = res.data;
        setEditTemplateParams({ table, ...rest });
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    getEditTableData();
  }, []);

  return (
      (tableName === 'users' || tableName === 'nondealer_requests') &&
        (!auth?.rep || !auth?.roles?.includes(5150))
    ) ?
      <RequireAuth allowedRoles={[5150]} />
    : Object.keys(editTemplateParams).length > 0 && <EditTemplate {...editTemplateParams} />;
};

export default EditTable;
