import './App.css';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { configureStore } from '@reduxjs/toolkit';
import ScrollToTop from './Components/Shared/ScrollToTop';
import { AuthProvider } from './context/AuthProvider';
import { UserProvider } from './context/UserProvider';
import isMobileReducer from './ReduxSlices/isMobile';
import productStateReducer from './ReduxSlices/productState';
import reportWebVitals from './reportWebVitals';
import App from './App.jsx';
import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'simplebar-react/dist/simplebar.min.css';

const store = configureStore({
  reducer: {
    isMobile: isMobileReducer,
    productState: productStateReducer,
  },
});

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

//clear the console on each load
if (import.meta.hot) {
  import.meta.hot.on('vite:beforeUpdate', () => {
    // eslint-disable-next-line no-console
    console.clear();
  });
}

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <AuthProvider>
          <UserProvider>
            <ScrollToTop />
            <Routes>
              <Route
                path='/*'
                element={<App />}
              />
            </Routes>
          </UserProvider>
        </AuthProvider>
      </Provider>
    </BrowserRouter>
  </StrictMode>
);

reportWebVitals();
