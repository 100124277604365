import axios from 'axios';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useGeneralImages from '../../hooks/useGeneralImages';
import Popup from './Popup';
import useAuth from '../../hooks/useAuth';

const Unauthorized = ({ popupParent }) => {
  const { generalImages } = useGeneralImages();
  const { auth } = useAuth();
  const navigate = useNavigate();

  const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;
  const [requested, setRequested] = useState(false);
  const location = useLocation();

  async function requestAccess() {
    const response = await axios.post(
      `${apiAddress}/auth/accessRequest`,
      JSON.stringify({
        user: auth?.rep,
        pageRequest:
          popupParent ?
            `${popupParent.productInfo.Series}- stock check and freight quote`
          : location.pathname,
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      }
    );

    if (response.status === 200) {
      setRequested(true);
    }
  }
  return (
    <div className='unauthorized-popup unauthorized'>
      <Popup
        popupParent={popupParent}
        element={
          auth?.roles?.includes(9999) ?
            <div className='background'>
              <img
                className='unauth-icon'
                src={
                  generalImages.find((i) => i.publicId === '401Option_1_REQUEST_SENT_3_wlj970')
                    ?.imageLink
                }
                alt='lock'
              ></img>
              <h2>RESTRICTED ACCESS</h2>
              <h4>Access to this area is allowed only to authorized users.</h4>
              <button
                onClick={() => {
                  navigate('/');
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    navigate('/');
                  }
                }}
              >
                Go Home
              </button>
            </div>
          : auth?.roles?.length === 0 ?
            <div className='background'>
              <img
                className='unauth-icon'
                src={
                  generalImages.find((i) => i.publicId === '401Option_1_REQUEST_SENT_3_wlj970')
                    ?.imageLink
                }
                alt='lock'
              ></img>
              <h2>RESTRICTED ACCESS</h2>
              <h4>
                Thank you for your patience while your NPS dealer account request is being reviewed.
                We will get back to you shortly!
              </h4>
              <button
                onClick={() => {
                  navigate('/');
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    navigate('/');
                  }
                }}
              >
                Go Home
              </button>
            </div>
          : !requested ?
            <div className='background'>
              <img
                className='unauth-icon'
                src={
                  generalImages.find((i) => i.publicId === '401Option_1_REQUEST_SENT_3_wlj970')
                    ?.imageLink
                }
                alt='lock'
              ></img>
              <h2>RESTRICTED ACCESS</h2>
              <h4>Access to this area is allowed only to authorized users.</h4>
              <button
                onClick={requestAccess}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    requestAccess();
                  }
                }}
              >
                Request Access
              </button>
            </div>
          : <div className='background'>
              <div className='border'>
                <img
                  className='success-icon'
                  src={
                    generalImages.find((i) => i.publicId === '401Option_1_REQUEST_SENT_2_cedth7')
                      ?.imageLink
                  }
                  alt='success'
                ></img>
                <h2 className='success'>Request sent!</h2>
                <h4>Our team will review your request and contact back.</h4>
                <div className='underline'></div>
              </div>
            </div>

        }
      />
    </div>
  );
};

export default Unauthorized;
