import { useStageInfo } from '../../context/StageProvider';
import LoadingIcon from '../Shared/LoadingIcon';

function PrebuiltPackages({
  editPackageData = false,
  selectedPackage = undefined,
  setSelectedPackage,
}) {
  const {
    packages,
    setStageBuild,
    setConfigure,
    uniqueIdTracker,
    setUniqueIdTracker,
    handleSelectAll,
    packageShapes,
    resetZoom,
  } = useStageInfo();

  function handleAddPackage(stagePackage) {
    let uidTracker = 0;
    handleSelectAll();
    setStageBuild([
      ...stagePackage.items.map((item) => {
        return {
          ...item,
          uid: uidTracker++,
        };
      }),
    ]);
    setUniqueIdTracker(uniqueIdTracker + stagePackage.items.length);
    resetZoom(stagePackage.items);
    setConfigure();
  }

  return (
    <div className='prebuilt-packages-wrapper'>
      {!packages.length || !packageShapes.length ?
        <div className='loading-wrapper'>
          <LoadingIcon />
        </div>
      : packageShapes.map((shape, index) => {
          return (
            <div key={index}>
              <h4>
                {shape.shape} - {shape.capacityType}
              </h4>
              <div className='prebuild-options-div'>
                {packages
                  .filter((i) => i.iconLink !== null)
                  .filter((p) => p.shape === shape.shape && p.capacityType === shape.capacityType)
                  .sort((a, b) => {
                    if (a.letterCode.length === 1 && b.letterCode.length > 1) {
                      return -1;
                    }
                    if (a.letterCode.length > 1 && b.letterCode.length === 1) {
                      return 1;
                    }
                    return a.letterCode.toLowerCase().localeCompare(b.letterCode.toLowerCase());
                  })
                  .map((p) => (
                    <div
                      className={`img-wrapper ${selectedPackage?.id === p.id ? 'selected' : ''}`}
                      role='button'
                      aria-label={p.name}
                      tabIndex={0}
                      key={p.id}
                      onClick={() => {
                        if (editPackageData) {
                          setSelectedPackage(p);
                        } else {
                          handleAddPackage(p);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          if (editPackageData) {
                            setSelectedPackage(p);
                          } else {
                            handleAddPackage(p);
                          }
                        }
                      }}
                    >
                      <span>
                        <span>{p.letterCode}</span> ({Math.floor(p.width / 12)}'
                        {p.width % 12 ? ` ${p.width % 12}"` : ''} x {Math.floor(p.length / 12)}'
                        {p.length % 12 ? ` ${p.length % 12}"` : ''})
                      </span>
                      <img
                        src={p.iconLink}
                        alt='package setup'
                      ></img>
                      <span className='package-capacity'>Capacity: {p.capacity}</span>
                    </div>
                  ))}
              </div>
            </div>
          );
        })
      }
    </div>
  );
}

export default PrebuiltPackages;
