import { useEffect, useState } from 'react';
import { useStageInfo } from '../../context/StageProvider';
import { findExposedSides } from './StageFunctions';

function SelectStageItems({ type = 'stage', selected, setSelected, disable }) {
  const { stageBuild, steps, guardrails, skirting, setHover, surface, accessoryPage, zoom } =
    useStageInfo();
  const [exposedSides, setExposedSides] = useState();

  function handleSelect(itemUid) {
    if (selected.find((i) => i === itemUid) !== undefined) {
      setSelected((sel) => sel.filter((uid) => uid !== itemUid));
    } else {
      setSelected([...selected, itemUid]);
    }
  }

  function handleSelectSide(e, side) {
    if (accessoryPage !== 1 && steps?.find((s) => s.id === side.id)) {
      setHover({
        text: 'There are already steps on this side.',
        color: 'var(--darkgrey)',
        top: e.clientY,
        left: e.clientX,
      });
    } else {
      if (selected.find((i) => i.id === side.id)) {
        setSelected(selected.filter((i) => i.id !== side.id));
      } else {
        setSelected([...selected, side]);
      }
    }
  }

  function handleHover(e, side) {
    if (type === 'cart') {
      setHover({
        text:
          steps?.find((s) => s.id === side.id) ? 'Steps'
          : guardrails.find((s) => s.id === side.id) && skirting.find((s) => s.id === side.id) ?
            'Guardrails and Skirting'
          : (
            guardrails.find(
              (s) =>
                s.id === side.id ||
                (typeof s.id === 'string' && s.id.split('_').includes(side.id.toString()))
            )
          ) ?
            'Guardrails'
          : skirting.find((s) => s.id === side.id) ? 'Skirting'
          : '',
        color:
          getStrokeColor(side).startsWith('url') ?
            'linear-gradient(321deg, rgba(101,141,192,1) 46%, rgba(229,167,3,1) 54%)'
          : getStrokeColor(side),
        top: e.clientY,
        left: e.clientX,
      });
    }
  }

  function getSurfaceColor() {
    switch (surface) {
      case 'Black Carpet':
        return 'black';
      case 'Blue Carpet':
        return '#3D4C69';
      case 'Grey Carpet':
        return '#665E5D';
      case 'Hardboard':
        return '#9C684C';
      case 'Red Carpet':
        return '#6B3637';
    }
  }

  function getStrokeColor(item) {
    if (type === 'cart') {
      if (steps?.find((s) => s.id === item.id)) {
        return '#73D262';
      } else if (
        guardrails.find((s) => s.id === item.id) &&
        skirting.find((s) => s.id === item.id)
      ) {
        return `url(#stripe-pattern-${item.id})`;
      } else if (
        guardrails.find(
          (s) =>
            s.id === item.id ||
            (typeof s.id === 'string' && s.id.split('_').includes(item.id.toString()))
        )
      ) {
        return '#E5A703';
      } else if (skirting.find((s) => s.id === item.id)) {
        return '#658DCC';
      }
    } else if (steps?.find((s) => s.id === item.id) && type !== 'steps') {
      return 'var(--darkgrey)';
    } else if (selected?.find((s) => s.id === item.id)) {
      switch (type) {
        case 'steps':
          return '#73D262';
        case 'guardrails':
          return '#E5A703';
        case 'skirting':
          return '#658DCC';
        default:
          return 'black';
      }
    } else {
      return 'var(--lightgrey)';
    }
  }

  useEffect(() => {
    setExposedSides(findExposedSides(stageBuild, type === 'guardrails'));
  }, [stageBuild]);

  return (
    <>
      {type === 'middle' ?
        <svg
          id='stage-svg'
          xmlns='http://www.w3.org/2000/svg'
          width={type === 'middle' ? '500px' : '750px'}
          height={type === 'middle' ? '350' : '525'}
        >
          <g>
            {stageBuild.map((item) => {
              return (
                <path
                  d={`M ${item.dimensions?.topLeft.x} ${item.dimensions?.topLeft.y} L ${item.dimensions?.topRight.x} ${item.dimensions?.topRight.y} L ${item.dimensions?.bottomRight.x} ${item.dimensions?.bottomRight.y} L ${item.dimensions?.bottomLeft.x} ${item.dimensions?.bottomLeft.y} z`}
                  fill='var(--darkblue)'
                  fillOpacity={
                    item.level === 4 ? 1
                    : item.level === 3 ?
                      0.8
                    : item.level === 2 ?
                      0.6
                    : item.level === 1 ?
                      0.4
                    : ''
                  }
                  stroke={selected.includes(item.uid) ? '#c0e66c' : 'white'}
                  strokeOpacity={selected.includes(item.uid) ? '1' : '0.3'}
                  strokeWidth='5px'
                  className={`uid-${item.uid}	'select-stage'`}
                  key={item.uid}
                  onMouseDown={() => {
                    if (type === 'middle') {
                      handleSelect(item.uid);
                    }
                  }}
                  transform={type === 'middle' ? 'scale(0.5)' : ''}
                ></path>
              );
            })}
            {type !== 'middle' &&
              exposedSides?.map((item, index) => {
                if (!disable?.find((i) => i.id === item.id)) {
                  return (
                    <line
                      x1={item.corner1.x}
                      y1={item.corner1.y}
                      x2={item.corner2.x}
                      y2={item.corner2.y}
                      stroke={
                        steps?.find((s) => s.id === item.id) ? 'var(--darkgrey)'
                        : selected?.find((s) => s.id === item.id) ?
                          'var(--lightgreen)'
                        : 'var(--lightgrey)'
                      }
                      strokeWidth='8'
                      transform={type === 'middle' ? 'scale(0.5)' : ''}
                      className='select-stage-side'
                      key={index}
                      onClick={(e) => handleSelectSide(e, item)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleSelectSide(e, item);
                        }
                      }}
                    ></line>
                  );
                }
              })}
          </g>
        </svg>
      : <g transform={`scale(${zoom})`}>
          {stageBuild.map((item) => {
            return (
              <path
                d={`M ${item.dimensions?.topLeft.x} ${item.dimensions?.topLeft.y} L ${item.dimensions?.topRight.x} ${item.dimensions?.topRight.y} L ${item.dimensions?.bottomRight.x} ${item.dimensions?.bottomRight.y} L ${item.dimensions?.bottomLeft.x} ${item.dimensions?.bottomLeft.y} z`}
                fill={surface ? getSurfaceColor() : 'var(--darkblue)'}
                fillOpacity={
                  item.level === 4 ? 1
                  : item.level === 3 ?
                    0.8
                  : item.level === 2 ?
                    0.6
                  : item.level === 1 ?
                    0.4
                  : ''
                }
                stroke={selected.includes(item.uid) ? '#c0e66c' : 'white'}
                strokeOpacity={selected.includes(item.uid) ? '1' : '0.3'}
                strokeWidth='5px'
                className={`uid-${item.uid}	'select-stage'`}
                key={item.uid}
              ></path>
            );
          })}
          {type !== 'stage' && (
            <g>
              {exposedSides?.map((item, index) => {
                if (!disable?.find((i) => i.id === item.id)) {
                  // Calculate the angle of the line
                  const dx = item.corner2.x - item.corner1.x;
                  const dy = item.corner2.y - item.corner1.y;
                  const angle = Math.atan2(dy, dx) * (180 / Math.PI); // Convert radians to degrees
                  return (
                    <defs key={index}>
                      <pattern
                        id={`stripe-pattern-${item.id}`}
                        patternUnits='userSpaceOnUse'
                        width='24'
                        height='24'
                        patternTransform={`rotate(${angle})`}
                      >
                        <rect
                          width='12'
                          height='24'
                          fill='#E5A703'
                        ></rect>
                        <rect
                          x='12'
                          width='12'
                          height='24'
                          fill='#658DCC'
                        ></rect>
                      </pattern>
                    </defs>
                  );
                }
              })}
              {exposedSides?.map((item, index) => {
                if (!disable?.find((i) => i.id === item.id)) {
                  return (
                    <line
                      x1={item.corner1.x}
                      y1={item.corner1.y}
                      x2={item.corner2.x}
                      y2={item.corner2.y}
                      stroke={getStrokeColor(item)}
                      strokeWidth='8'
                      className={type === 'cart' ? 'display-selected-sides' : 'select-stage-side'}
                      id={`side-${index}`}
                      key={index}
                      onClick={(e) => handleSelectSide(e, item)}
                      onMouseEnter={(e) => handleHover(e, item)}
                    ></line>
                  );
                }
              })}
            </g>
          )}
        </g>
      }
    </>
  );
}

export default SelectStageItems;
