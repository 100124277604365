import { createContext, useContext, useEffect, useState } from 'react';
import useAxiosJWT from '../hooks/useAxiosJWT';
import useAuth from '../hooks/useAuth';
const ContentContext = createContext({});

export const ContentProvider = ({ children }) => {
  const axiosJWT = useAxiosJWT();
  const { auth } = useAuth();
  const [customers, setCustomers] = useState([]);
  const [chosenAccount, setChosenAccount] = useState();

  useEffect(() => {
    getCustomers();
  }, [auth]);

  async function getCustomers() {
    if (auth?.roles?.includes(3500)) {
      axiosJWT
        .post(`adminPortal/getCustomers`, {
          search: '',
          active: 'A',
          limit: 99999,
        })
        .then((response) => {
          setCustomers(response.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  return (
    <ContentContext.Provider
      value={{
        customers,
        chosenAccount,
        setChosenAccount,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};

export const useContent = () => {
  return useContext(ContentContext);
};
