import { AiOutlineCloseCircle } from 'react-icons/ai';
import FormInput from '../NPSportal/FormInput';
import BasicButton from '../NPSportal/basicButton';

const EditableFormAddNew = ({
  setOpenAdd,
  setAddValues,
  columns,
  addValues,
  addHandler,
  getData,
}) => {
  const handleInputChangeAdd = (name, value) => {
    if (addValues) {
      setAddValues((addValues) => ({
        ...addValues,
        [name]: value,
      }));
    } else {
      setAddValues({
        [name]: value,
      });
    }
  };

  return (
    <div className='popup'>
      <div className='overlay'></div>
      <div className='popup-element overlay-popup'>
        <AiOutlineCloseCircle
          className='close-button'
          onClick={() => {
            setOpenAdd(false);
            setAddValues();
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setOpenAdd(false);
              setAddValues();
            }
          }}
        />
        <div className='add-new-header'>
          <h2 className='add-new-text'>Add New</h2>
          <div className='underline'></div>
        </div>
        <div className='form-popup-container'>
          {columns
            ?.filter((i) => {
              return i.display;
            })
            ?.filter((i) => i.fieldName !== 'ID')
            ?.map((i, index) => {
              return (
                <FormInput
                  key={index}
                  inputType={i?.type?.toLowerCase()}
                  name={i.name}
                  value={addValues?.[i.name] || ''}
                  addValues={addValues}
                  setAddValues={setAddValues}
                  columns={columns}
                  onClick={(value) => {
                    handleInputChangeAdd(i.name, value);
                  }}
                  onChange={(value) => {
                    handleInputChangeAdd(i.name, value);
                  }}
                  placeholder={`Enter ${i.name}`}
                />
              );
            })}
        </div>
        <div className='popup-submit-button'>
          <BasicButton
            text={'Add'}
            buttonStyle={{ width: '30%' }}
            type={'white-bordered-button'}
            onClick={() => {
              if (Object.keys(addValues)?.length > 0) {
                setOpenAdd(false);
                addHandler(addValues);
                setAddValues();
                getData();
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (Object.keys(addValues)?.length > 0) {
                  setOpenAdd(false);
                  addHandler(addValues);
                  setAddValues();
                  getData();
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EditableFormAddNew;
