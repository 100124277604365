import { useState } from 'react';
import { useSelector } from 'react-redux';
import '../../CSS/HeaderNav.css';
import '../../CSS/MobileApp/M_HeaderNav.css';
import HeaderNavLinks from './HeaderNavLinks';
import Search from './Search.jsx';

const HeaderNav = () => {
  const [searchText, setSearchText] = useState('');
  const mobileScreen = useSelector((state) => state.isMobile.value);

  return (
    <nav
      className='header-nav padding'
      aria-label='Header Navigation'
    >
      <ul className='header-nav-list-items'>
        {!mobileScreen.isMobile ?
          <HeaderNavLinks></HeaderNavLinks>
        : ''}
        <Search
          searchText={searchText}
          setSearchText={setSearchText}
        />
      </ul>
    </nav>
  );
};

export default HeaderNav;
