const excelClipboardCopy = () => {
  const copyToClipboard = (text) => {
    // Try using the Clipboard API if available
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text).catch((err) => {
        console.error('Failed to copy text: ', err);
      });
    } else {
      // Fallback method using a temporary textarea element
      const textarea = document.createElement('textarea');
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
      } catch (err) {
        console.error('Failed to copy text (fallback): ', err);
      }
      document.body.removeChild(textarea);
    }
  };

  return copyToClipboard;
};

export default excelClipboardCopy;
