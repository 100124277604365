import { AdvancedImage } from '@cloudinary/react';
import { CgSoftwareDownload } from 'react-icons/cg';
import { AiOutlineLink } from 'react-icons/ai';
import { scale } from '@cloudinary/url-gen/actions/resize';
import clipboardCopy from 'clipboard-copy';
import FormatImageLink from '../Components/Shared/FormatImageLink';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAxiosJWT from '../hooks/useAxiosJWT';
import { BiLeftArrow } from 'react-icons/bi';

const EmployeePortalIcons = () => {
  const navigate = useNavigate();
  const axiosJWT = useAxiosJWT();
  const decodeAndFormat = FormatImageLink();
  const [icons, setIcons] = useState();
  function getData() {
    axiosJWT
      .get(`cloudinary/getAllIcons`)
      .then((response) => {
        setIcons(response.data.resources);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    getData();
  }, []);
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 400);
  }, [copied]);
  return (
    <div className='adminDiv'>
      <div
        role='button'
        aria-label='Go to Employee Portal'
        tabIndex={0}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          left: '23rem',
          top: '18rem',
          cursor: 'pointer',
        }}
        onClick={() => {
          navigate('/employee-portal');
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            navigate('/employee-portal');
          }
        }}
      >
        <BiLeftArrow style={{ marginRight: '.5rem' }} /> BACK
      </div>

      <h2>All Icons</h2>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '75rem',
          margin: 'auto',
          justifyContent: 'space-between',
        }}
      >
        {icons?.map((i, index) => {
          return (
            <div
              key={index}
              className='mediaTile'
              style={{
                cursor: 'pointer',
              }}
            >
              <div className='tile-img'>
                <AdvancedImage cldImg={decodeAndFormat(i.public_id)?.resize(scale().width(250))} />
              </div>

              <div className='mediaTile-buttons'>
                <div
                  role='button'
                  aria-label='Download'
                  tabIndex={0}
                  className='download-img'
                  onClick={() => {
                    window.open(
                      i.secure_url
                        .replace('svg', 'jpg')
                        .replace('/upload', '/upload/fl_attachment'),
                      '_blank'
                    );
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      window.open(
                        i.secure_url
                          .replace('svg', 'jpg')
                          .replace('/upload', '/upload/fl_attachment'),
                        '_blank'
                      );
                    }
                  }}
                >
                  <CgSoftwareDownload />
                </div>
                <div
                  role='button'
                  aria-label='Copy to Clipboard'
                  tabIndex={0}
                  className='download-img'
                  onClick={() => {
                    setCopied(true);
                    clipboardCopy(i.secure_url.replace('svg', 'jpg'));
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setCopied(true);
                      clipboardCopy(i.secure_url.replace('svg', 'jpg'));
                    }
                  }}
                >
                  <AiOutlineLink />
                  {copied ?
                    <div
                      className='notice'
                      style={{ left: '37.5%' }}
                    >
                      Link Copied
                    </div>
                  : ''}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EmployeePortalIcons;
