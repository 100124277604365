import { useNavigate } from 'react-router-dom';

const FilterButtons = ({ list, envFilter }) => {
  const navigate = useNavigate();
  return (
    <div className='envFilters'>
      <div
        role='button'
        aria-label='View all the Environments'
        tabIndex={0}
        className={`envFilter-button ${envFilter === 'all' ? 'selected-env-button' : ''}`}
        onClick={() => {
          navigate(
            {
              pathname: `/environments`,
            },
            { replace: true }
          );
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            navigate(
              {
                pathname: `/environments`,
              },
              { replace: true }
            );
          }
        }}
      >
        All
      </div>

      {list &&
        [...new Set(Object.values(list)?.flat())]?.map((i) => {
          return (
            <div
              role='button'
              aria-label={`View the ${i} Environments`}
              tabIndex={0}
              key={i}
              className={`envFilter-button ${
                i.toLowerCase() === envFilter.toLowerCase() ? 'selected-env-button' : ''
              }`}
              onClick={() => {
                navigate(
                  {
                    pathname: `/environments`,
                    search: `?filter=${i}`,
                  },
                  { replace: true }
                );
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  navigate(
                    {
                      pathname: `/environments`,
                      search: `?filter=${i}`,
                    },
                    { replace: true }
                  );
                }
              }}
            >
              {i}
            </div>
          );
        })}
    </div>
  );
};

export default FilterButtons;
