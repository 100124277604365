const TicketType = ({ ticket }) => {
  return (
    <div
      style={{
        width: ticket.icon ? '220px' : '100px',
        justifyContent: ticket.icon ? 'start' : 'center',
        borderRadius: ticket.response_status ? '0px' : '',
      }}
      className={`ticket-type-div ${ticket.color}`}
    >
      {ticket.icon && (
        <img
          style={{
            width: '1.3rem',
            marginRight: '4px',
          }}
          src={`${ticket.icon}`}
          alt={ticket.title}
        />
      )}
      <div className='ticket-text'>{ticket.title}</div>
    </div>
  );
};

export default TicketType;
