import Axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../CSS/AboutMenu.css';

const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

function AboutMenu() {
  const navigate = useNavigate();
  const [aboutMenu, setAboutMenu] = useState();

  function getData() {
    Axios.post(`${apiAddress}/content/getDropDownTabs`, { tabName: 'About' }).then((response) => {
      setAboutMenu(response.data.dropdown);
    });
  }
  useEffect(() => {
    document.title = 'About Us - National Public Seating';
    getData();
  }, []);
  return (
    <div className='about-menu'>
      <h2>ABOUT US</h2>
      <div className='underline' />
      <div className='template-tile-div inner-padding'>
        {aboutMenu?.map((item, index) => (
          <div
            role='button'
            aria-label={item.title}
            tabIndex={0}
            key={index}
            className='template-tile'
            onClick={() => navigate(`${item.link}`)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                navigate(`${item.link}`);
              }
            }}
          >
            <div className='template-img'>
              <img
                src={item.image}
                alt={`${item.title} icon`}
              />
            </div>
            <h2>{item.title}</h2>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AboutMenu;
