import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import ImageWithHover from '../EditorPortal/ImageWithHover';
const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

function ItemTableData({
  setRows,
  identifyingColumn,
  identifyingValue,
  row,
  column,
  setActionData,
  setNotification,
}) {
  const [topUse, setTopUse] = useState();
  const [rightUse, setRightUse] = useState();
  const [imageHover, setImageHover] = useState();
  const axiosJWT = useAxiosJWT();

  function changeCheckbox(value, row, column) {
    axiosJWT
      .post(`${apiAddress}/itemTable/setItemData`, {
        value,
        table: column.db_table_name,
        column: column.value,
        identifyingColumn: identifyingColumn.value,
        identifyingValue: identifyingValue,
      })
      .then(() => {
        setRows((prevRows) =>
          prevRows.map((r) => (r === row ? { ...r, [column.value]: value } : r))
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function rowAction() {
    axiosJWT
      .post(`${apiAddress}/itemTable/${column.value}`, {
        row,
      })
      .then((response) => {
        if (response.data?.length) {
          setActionData({ popup: column.title?.includes('View'), data: response.data, row: row });
        } else {
          setNotification('No orders for this item');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    if (imageHover) {
      setTopUse(document?.getElementById(imageHover)?.getBoundingClientRect().top - 260);
      setRightUse(
        document?.getElementById(imageHover)?.clientWidth > 100 ?
          document?.getElementById(imageHover)?.getBoundingClientRect().left - 55
        : document?.getElementById(imageHover)?.getBoundingClientRect().left - 95
      );
    } else {
      setTopUse();
      setRightUse();
    }
  }, [imageHover]);

  function getCellData() {
    switch (column.type) {
      case 'image':
        return typeof row[column.value] !== 'object' || row[column.value].swatch ?
            <div
              onMouseEnter={() =>
                setImageHover(
                  row.ID + (row[column.value]?.swatch + column.value || row[column.value])
                )
              }
              onMouseLeave={() => setImageHover()}
              id={row.ID + (row[column.value]?.swatch + column.value || row[column.value])}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '100%',
                flexDirection: 'column',
                fontSize: '11px',
              }}
            >
              <ImageWithHover
                topUse={topUse}
                rightUse={rightUse}
                src={
                  typeof row[column.value] === 'object' ?
                    row[column.value]?.swatch || ''
                  : row[column.value]
                }
                swatch={row[column.value]?.swatch ? row[column.value] : ''}
              />
              {row[column.value]?.swatch ?
                <div>{`${row[column.value]?.name}`}</div>
              : ''}
            </div>
          : '';
      case 'boolean':
        return (
          <input
            onChange={() => {
              changeCheckbox(row[column.value] === 1 ? 0 : 1, row, column);
            }}
            type='checkbox'
            className='checkbox'
            checked={row[column.value] === 1}
          />
        );
      case 'datetime':
        return (
          <span
            role='button'
            aria-label='datetime'
            tabIndex={0}
            onClick={() => {
              if (column.link) {
                window.open(`${row[column.link]}`);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                window.open(`${row[column.link]}`);
              }
            }}
            className={column.link ? 'link' : ''}
          >
            {dayjs(row[column.value]).isValid() ?
              dayjs(row[column.value]).format('MM/DD/YYYY')
            : ''}
          </span>
        );
      case 'action':
        return (
          <button
            aria-label='action'
            tabIndex={0}
            onClick={() => {
              if (column.value) {
                rowAction();
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (column.value) {
                  rowAction();
                }
              }
            }}
            className='basic-blue-button'
          >
            {column.title}
          </button>
        );
      default:
        return (
          <span
            role='button'
            aria-label={row[column.value]}
            tabIndex={0}
            onClick={() => {
              if (column.link) {
                window.open(`${row[column.link]}`);
              }
            }}
            className={column.link ? 'link' : ''}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (column.link) {
                  window.open(`${row[column.link]}`);
                }
              }
            }}
          >
            {row[column.value]}
          </span>
        );
    }
  }
  return getCellData() || '';
}

export default ItemTableData;
