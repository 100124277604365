import { AdvancedImage } from '@cloudinary/react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import '../../CSS/ShowcaseItem.css';
import FormatImageLink from '../Shared/FormatImageLink';

function ShowcaseItem({ item }) {
  const decodeAndFormat = FormatImageLink();
  const navigate = useNavigate();

  function clickHandler(item) {
    navigate({
      pathname: `/products/${item.series}`,
      search: `?${createSearchParams(item.configOptions)}`,
    });
  }

  return (
    <div
      role='button'
      aria-label={item.sku}
      tabIndex={0}
      className='env-item'
      onClick={() => clickHandler(item)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          clickHandler(item);
        }
      }}
    >
      <div className='img-div'>
        <AdvancedImage cldImg={decodeAndFormat(item.img)} />
      </div>
      <div className='item-details'>
        <div className='series'>{item.series}</div>
        <div className='skuName'>{item.sku}</div>
      </div>
    </div>
  );
}

export default ShowcaseItem;
