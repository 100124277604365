import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../CSS/NotFound.css';

const NotFound = () => {
  useEffect(() => {
    document.title = `Not Found - National Public Seating`;
    window.scrollTo(0, 0);
  });
  const navigate = useNavigate();
  return (
    <div>
      <div className='banner not-found'>
        {/* <img
					src={banner}
					style={{ marginTop: "0" }}
				></img> */}
        <div className='go-home-button-div'>
          <button
            className='go-home-button'
            onClick={() => {
              navigate('/');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                navigate('/');
              }
            }}
          >
            Go Home
          </button>
        </div>
      </div>
      <div style={{ height: '54rem' }}></div>
    </div>
  );
};

export default NotFound;
