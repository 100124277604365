import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
const CalculateShipping = () => {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  return (
    <div className='calculate-shipping'>
      <div
        role='button'
        aria-label='Calcuate Shipping Costs'
        tabIndex={0}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        onClick={() => {
          navigate(`/cart`);
        }}
        className='calculate-button-div'
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            navigate(`/cart`);
          }
        }}
      >
        <Link
          className={`${
            hover ? ' calculate-now-button calculate-now-button-hover' : 'calculate-now-button'
          }`}
          to={`/cart`}
        >
          CALCULATE NOW!
        </Link>
      </div>
    </div>
  );
};

export default CalculateShipping;
