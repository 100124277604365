import { AdvancedImage } from '@cloudinary/react';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { useState } from 'react';
import '../../CSS/TemplateTile.css';
import '../../CSS/MobileApp/M_TemplateTile.css';
import FormatImageLink from './FormatImageLink';

const TemplateTile = ({
  item,
  clickHandler,
  empty = false,
  onlyStrings = false,
  statusIcons = false,
  laminates = false,
}) => {
  const decodeAndFormat = FormatImageLink();

  const [hoverProduct, setHoverProduct] = useState();
  const [hoverSwatch, setHoverSwatch] = useState();
  const comingSoon =
    'https://res.cloudinary.com/da3rom333/image/upload/v1729007847/DONT%20MOVE/image-coming-soon_lzhzdk_sm6cln.jpg';

  return (
    <>
      {empty ?
        <div className='template-tile-empty'></div>
      : onlyStrings ?
        <div className='template-tile string-tile'>
          <p style={{ padding: '2rem' }}>{item}</p>
        </div>
      : <div
          role='button'
          tabIndex={0}
          className='template-tile'
          onClick={() => {
            clickHandler(item);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              clickHandler(item);
            }
          }}
        >
          <div
            style={{ position: 'relative' }}
            className='template-img'
          >
            <AdvancedImage
              cldImg={decodeAndFormat(
                item.coverImage ? item.coverImage
                : item.image ? item.image
                : comingSoon
              )?.resize(scale().width(250))}
              alt={`Check out the ${
                item.catName ? item.catName
                : item.display_sku ? item.display_sku
                : item.title ? item.title
                : item.itemName ? item.itemName
                : item.modelNum
              }`}
            />
            {item?.status !== 'A' && statusIcons ?
              <div className='search-template-status-icon'>
                <AdvancedImage
                  cldImg={decodeAndFormat(
                    statusIcons?.find((i) => i.status === item.status)?.icon_link
                  )?.resize(scale().width(170))}
                  alt='Status Icon'
                />
              </div>
            : ''}

            {item?.configOptions && laminates ?
              <>
                <div className='stock-and-lam-container search-template-status-icon'>
                  <div className='item-stock-container'>
                    Stock: <span className='item-stock-square'>{item.stock['NJ']}</span>
                  </div>
                  <div className='basic-flex'>
                    {Object.entries(item?.configOptions).map(([key, value]) => {
                      if (
                        key === 'Bench Color' ||
                        key === 'TableTop Color' ||
                        key === 'Back Color'
                      ) {
                        const foundItem = laminates?.find((j) => j.config_value === value);
                        if (foundItem) {
                          return (
                            <div
                              className='laminate-item basic-flex'
                              key={key}
                            >
                              <img
                                src={`${foundItem?.swatch}`}
                                style={{ width: '2.5rem', height: '2.5rem', borderRadius: '10px' }}
                                alt={`${foundItem.selection_name} Swatch`}
                                onMouseEnter={() => {
                                  setHoverProduct(item);
                                  setHoverSwatch(key);
                                }}
                                onMouseLeave={() => {
                                  setHoverProduct();
                                  setHoverSwatch();
                                }}
                              />
                              {hoverProduct === item && hoverSwatch === key ?
                                <div className='hover-laminate-type'>{`${key}: ${foundItem.selection_name}(${foundItem?.config_value}) `}</div>
                              : ''}
                            </div>
                          );
                        }
                      }
                      return null;
                    })}
                  </div>
                </div>
              </>
            : ''}
          </div>
          <h2>
            {item.catName ?
              item.catName
            : item.display_sku ?
              item.display_sku
            : item.title ?
              item.title
            : item.itemName ?
              item.itemName
            : item.modelNum}
          </h2>
        </div>
      }
    </>
  );
};

export default TemplateTile;
