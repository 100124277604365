import { useState } from 'react';
import { AiFillCheckSquare } from 'react-icons/ai';
import { IoMdCheckmark } from 'react-icons/io';
import { useSelector } from 'react-redux';
import LaminateHover from './LaminateHover';
const LaminateCube = ({ colorData, clicked, selectedColor }) => {
  const { isMobile } = useSelector((state) => state.isMobile.value);
  const [hover, setHover] = useState(false);

  function renderPopUp() {
    setHover(colorData);
  }

  return (
    <div
      role='button'
      aria-label={colorData.selectionName}
      tabIndex={0}
      style={{
        backgroundImage: `url(${colorData.Swatch})`,
        backgroundSize: 'cover',
      }}
      className={`laminate-cube ${
        selectedColor === colorData.selectionName ? 'laminate-cube-selected' : ''
      }`}
      id={`${colorData.selectionName}`}
      onClick={() => {
        setHover(false);
        clicked(colorData);
      }}
      onMouseEnter={() => {
        renderPopUp();
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setHover(false);
          clicked(colorData);
        }
      }}
    >
      {hover && (
        <LaminateHover
          hover={hover}
          colorData={colorData}
        />
      )}
      {selectedColor === colorData.selectionName ?
        isMobile ?
          <IoMdCheckmark className='mobile-selected-color-check' />
        : <AiFillCheckSquare className='selected-color-check' />
      : ''}
    </div>
  );
};

export default LaminateCube;
