import '../../CSS/Brochures.css';
const BrochureCube = ({ frameData }) => {
  return (
    <div
      // style={{ height: "20.625rem", width: "20.625rem" }}
      className='brochure-tile'
    >
      <div style={{ height: '339px', width: '425px' }}>
        <iframe
          allow={'clipboard-write'}
          sandbox={
            'allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms'
          }
          allowFullScreen={true}
          style={{ border: 'none', width: '100%', height: '100%' }}
          src={frameData.src}
          title={frameData.src}
        ></iframe>
      </div>

      <div className='brochure-title'>{frameData.title}</div>
    </div>
  );
};

export default BrochureCube;
