import Axios from 'axios';
import { useEffect, useState } from 'react';
import { MdNavigateNext } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import '../../CSS/Navigatebar.css';
import '../../CSS/MobileApp/M_Navigatebar.css';
import { updateProductInfo } from '../../ReduxSlices/productState';
import BasicButton from '../NPSportal/basicButton';
import useGeneralImages from '../../hooks/useGeneralImages';

const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

const NavigateBar = ({ series, isProductPage = false }) => {
  const { generalImages } = useGeneralImages();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [navList, setNavList] = useState([]);
  const navigate = useNavigate();

  function getCategories() {
    Axios.get(`${apiAddress}/products/getCategories`).then((response) => {
      setCategories(response.data);
    });
  }
  useEffect(() => {
    const typeString = navList[0]?.catName.slice(0, -1).toLowerCase();
    if (isProductPage) {
      if (typeString?.includes('benche')) {
        dispatch(updateProductInfo({ type: 'bench' }));
      } else if (typeString === 'dollie') {
        dispatch(updateProductInfo({ type: 'dolly' }));
      } else {
        dispatch(updateProductInfo({ type: navList[0]?.catName.slice(0, -1) }));
      }
    }
    //dollies issue
  }, [navList]);

  function findCategory() {
    var trail = [];
    let found = false;

    function recurse(categoryAry) {
      for (let i = 0; i < categoryAry.length; i++) {
        trail.push(categoryAry[i]);
        // Found the category!
        if (categoryAry[i].catName === series) {
          found = true;
          break;
          // Did not match...
        } else {
          // Are there sub-categories? YES
          if (categoryAry[i].hasOwnProperty('subCats')) {
            recurse(categoryAry[i].subCats);
            if (found) {
              break;
            }
          }
        }
        trail.pop();
      }
    }
    recurse(categories.productList);
    return trail;
  }

  useEffect(() => {
    if (categories?.productList !== undefined) {
      setNavList(findCategory());
    }
  }, [categories]);

  useEffect(() => {
    if (navList && isProductPage) {
      dispatch(updateProductInfo({ parentCat: navList[0] }));
    }
  }, [navList]);
  useEffect(() => {
    getCategories();
  }, [series]);

  return (
    <div className='navigate-bar inner-padding'>
      <Link
        to='/categories'
        // state={{ selectedCat: categories.productList }}
        className='poducts-link'
      >
        Products
      </Link>
      <MdNavigateNext />
      {
        categories?.productList ?
          navList.map((cat) => (
            <div
              className='cat-nav'
              key={cat.catName}
            >
              <Link
                to={
                  cat.subCats ?
                    `/categories/${encodeURIComponent(cat.catName)}`
                  : `/products/${encodeURIComponent(cat.catName)}`
                }
                state={{ selectedCat: cat }}
              >
                {cat.catName}
              </Link>
              {navList.indexOf(cat) + 1 === navList.length ? '' : <MdNavigateNext />}
            </div>
          ))
        : ''
        // <p>loading...</p>
      }
      {/* link to stage configurator */}
      {navList[0]?.catName === 'Stages & Risers' && !navList[1] && (
        <div className='navigate-to-stage-configurator'>
          Start customizing with out new tool:
          <BasicButton
            icon={
              generalImages.find((i) => i.publicId === 'Icon_Stage_Configurator_eadkxb')?.imageLink
            }
            text={'Stage Configurator'}
            type={'white-bordered-button'}
            onClick={() => {
              navigate('/stage-configurator');
            }}
          ></BasicButton>
        </div>
      )}
    </div>
  );
};

export default NavigateBar;
