import React, { useState } from 'react';
import { IconContext } from 'react-icons';
import { BsPersonCircle } from 'react-icons/bs';
import useAuth from '../../../hooks/useAuth';
import { HiUserCircle } from 'react-icons/hi2';

const LoginButton = ({ displayLoginBox, loggedIn, name, logOut }) => {
  const [hover, setHover] = useState(false);
  const { auth } = useAuth();
  return (
    <div
      role='button'
      aria-label='log in'
      tabIndex={0}
      className='svg-div'
      style={{ zIndex: 16, position: 'relative' }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={
        loggedIn && !auth?.accounts?.length > 0 ? logOut
        : !loggedIn ?
          displayLoginBox
        : null
      }
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          loggedIn && !auth?.accounts?.length > 0 ? logOut
          : !loggedIn ? displayLoginBox
          : null;
        }
      }}
    >
      <svg
        id='Capa_2'
        className='dealer-login-svg'
        data-name='Capa 2'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 161.75 49.75'
      >
        <defs>
          <style>
            {`.cls-1-login{fill:none;stroke:#bed46e;stroke-width:2px;stroke-miterlimit:10;${
              loggedIn ? 'transform: rotate(180deg) translate(-63px, -49px);' : ''
            }}`}
          </style>
        </defs>
        <g
          id='Capa_1-2'
          data-name='Capa 1'
        >
          <rect
            x={0.38}
            y={0.37}
            width={161}
            height={49}
            rx={24.5}
            ry={24.5}
            style={{
              fill: '#fff',
              stroke: `${hover ? 'var(--darkblue)' : '#b3b3b3'}`,
              strokeMiterlimit: 10,
              strokeWidth: `${hover ? '1px' : '.75px'}`,
              cursor: `${hover ? 'pointer' : 'default'}`,
            }}
          />
          <text
            transform='translate(58.27 20.87)'
            style={{
              fontFamily: 'Poppins-SemiBold,Poppins',
              fontSize: '13.98px',
              fontWeight: 600,
              fill: '#00435b',
            }}
          >
            <tspan
              x={0}
              y={0}
              dy={0}
            >
              {loggedIn ?
                `Hi ${name.length > 8 ? `${name.substring(0, 7)}...` : `${name}!`}`
              : 'Dealer Login'}
            </tspan>
          </text>
          <text
            transform='translate(59.27 38.52)'
            style={{
              fill: 'gray',
              fontFamily: 'Poppins-SemiBold,Poppins',
              fontSize: '13.98px',
              fontWeight: 600,
            }}
          >
            <tspan
              x={0}
              y={0}
              style={{
                fontSize:
                  loggedIn && auth?.accounts?.find((i) => i.last_login === 1) ? '11px' : 'inherit',
              }}
            >
              {loggedIn ?
                auth?.accounts?.length > 0 ?
                  `Account #${auth?.accounts?.find((i) => i.last_login === 1)?.account_num}`
                : ' Log Out'
              : 'Go'}
            </tspan>
          </text>

          {loggedIn && auth?.accounts?.length > 0 ?
            <HiUserCircle
              style={{
                fontSize: '49px',
                strokeWidth: '0px',
                fill: '#bed46e',
              }}
            />
          : <>
              <path
                className='cls-1-login'
                d='M16.37 17.89v-3.51c0-2.72 2.21-4.93 4.93-4.93h20.14c2.72 0 4.93 2.21 4.93 4.93v20.14c0 2.72-2.21 4.93-4.93 4.93H21.3c-2.72 0-4.93-2.21-4.93-4.93M16.37 24.45h21'
              />
              <path
                className='cls-1-login'
                d='m31.37 18.45 5.84 6-5.84 6'
              />
            </>
          }
          {!loggedIn && (
            <path
              className='cls-1-login'
              d='m31.37 18.45 5.84 6-5.84 6'
            />
          )}
        </g>
      </svg>
      <IconContext.Provider value={{ color: '#03425A', size: '4.5em' }}>
        <div className='dealer-login-icon'>
          <BsPersonCircle />
        </div>
      </IconContext.Provider>
    </div>
  );
};

export default LoginButton;
