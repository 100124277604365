import OptionsCube from './OptionsCube';
const OptionsBar = ({ configSelected, optionsSelected, setOptionsSelected, finishTypes }) => {
  return (
    <div className='options-bar'>
      {finishTypes.map((i) => {
        return (
          <OptionsCube
            key={i}
            option={i}
            optionType={configSelected}
            optionsSelected={optionsSelected}
            setOptionsSelected={setOptionsSelected}
            setConfigSelected={false}
            availableOptions={false}
          />
        );
      })}
    </div>
  );
};

export default OptionsBar;
