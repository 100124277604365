import { useSelector } from 'react-redux';
import FreightCartForm from '../../Components/Shared/FreightCartForm';

const FreightQuestions = ({
  setShowFreightForm,
  setShowPriceForm,
  showFreightForm,
  showPriceForm,
  quoteError,
  mobileCurrentPage,
  setMobileCurrentPage,
  cart,
  setHeader,
  setNotice,
}) => {
  const mobileScreen = useSelector((state) => state.isMobile.value.isMobile);

  return (
    <div
      className='cartButtons'
      id='contact'
    >
      {(!mobileScreen || mobileCurrentPage === 'pageThree') && (
        <div className='mobile-question'>
          <div>
            <h2>Need help with your Freight?</h2>
            <button
              onClick={() => {
                if (mobileScreen) {
                  setMobileCurrentPage('pageFour');
                  setHeader('freight questions');
                }
                setShowFreightForm(true);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (mobileScreen) {
                    setMobileCurrentPage('pageFour');
                    setHeader('freight questions');
                  }
                  setShowFreightForm(true);
                }
              }}
            >
              Freight Questions
            </button>
          </div>
          <div>
            <h2>Looking for a Price Quote?</h2>
            <button
              onClick={() => {
                if (cart?.length > 0) {
                  setShowPriceForm(true);
                  setShowFreightForm(false);
                  if (mobileScreen) {
                    setMobileCurrentPage('pageFour');
                    setHeader('request a price quote');
                  }
                } else {
                  setNotice('Cart Is Empty');
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (cart?.length > 0) {
                    setShowPriceForm(true);
                    setShowFreightForm(false);
                    if (mobileScreen) {
                      setMobileCurrentPage('pageFour');
                      setHeader('request a price quote');
                    }
                  } else {
                    setNotice('Cart Is Empty');
                  }
                }
              }}
            >
              Request a Price Quote
            </button>
          </div>
        </div>
      )}
      {(!mobileScreen || mobileCurrentPage === 'pageFour') &&
        (showFreightForm || showPriceForm ?
          <FreightCartForm
            showFreightForm={showFreightForm}
            setShowFreightForm={setShowFreightForm}
            showPriceForm={showPriceForm}
            setShowPriceForm={setShowPriceForm}
            quoteError={quoteError}
            setMobileCurrentPage={setMobileCurrentPage}
          />
        : '')}
    </div>
  );
};

export default FreightQuestions;
