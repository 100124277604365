import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';

const useExportToExcel = () => {
  function ExportToExcel(dataObjects, fileName) {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    if (Array.isArray(dataObjects)) {
      const headers = Object.keys(dataObjects[0] || {});
      worksheet.columns = headers.map((header) => ({
        header: header,
        key: header,
        width: 20,
      }));

      dataObjects.forEach((row) => {
        worksheet.addRow(row);
      });

      workbook.xlsx
        .writeBuffer()
        .then((buffer) => {
          const blob = new Blob([buffer], { type: EXCEL_TYPE });

          saveAs(blob, fileName + EXCEL_EXTENSION);
        })
        .catch((err) => {
          console.error('Error writing Excel file', err);
        });
    } else {
      console.error('dataObjects is not an array of objects');
    }
  }

  return ExportToExcel;
};

export default useExportToExcel;
