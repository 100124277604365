import Axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { HiDotsHorizontal } from 'react-icons/hi';
import { TbFileDescription } from 'react-icons/tb';
import useAuth from '../../hooks/useAuth';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import FreightCartForm from '../Shared/FreightCartForm';
import QuoteDetails from './QuoteDetails';

const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;
const SavedQuote = ({ order, open, setOpenItem, getQuotes }) => {
  // const [open, setOpen] = useState(false);
  const [openDots, setOpenDots] = useState(false);
  const [notice, setNotice] = useState(false);
  const [email, setEmail] = useState('');
  const [openRequestForm, setOpenRequestForm] = useState(false);
  const [admin, setAdmin] = useState(false);
  const { auth } = useAuth();

  useEffect(() => {
    setAdmin(
      auth?.accounts
        ?.find((i) => i.last_login === 1)
        ?.AccountRoles?.find((i) => [1100, 1200].includes(i))
    );
  }, []);

  useEffect(() => {
    if (notice !== 'Resubmiting quote...') {
      const timer = setTimeout(() => {
        setNotice(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [notice]);
  const axiosJWT = useAxiosJWT();
  function getFreight() {
    setOpenDots(false);
    setNotice('Resubmiting quote...');
    axiosJWT
      .post(`${apiAddress}/NPS/freightCart`, {
        zipCode: order.zipcode,
        options: order.accesorials,
        itemQty: order.items.length,
        items: [...order.items],
      })
      .then((response) => {
        getQuotes();
        setNotice(`Quote #${response.data[0]} added`);
      })
      .catch(() => {
        setNotice('Error running quote');
      });
  }

  useEffect(() => {
    if (!open) {
      setOpenDots(false);
    }
  }, [open]);
  function formatDateToTwoDigitsYear(dateString) {
    const date = new Date(dateString);
    const year = date.toLocaleDateString(undefined, { year: '2-digit' });
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${year}`;

    return formattedDate;
  }
  const containerRef = useRef(null);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (containerRef.current && !containerRef.current.contains(event.target) && openDots) {
  //       setOpenDots(false);
  //     }
  //   };

  //   document.addEventListener('click', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('click', handleClickOutside);
  //   };
  // }, [openDots]);

  function validateEmail() {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }
  function sendEmail() {
    if (validateEmail()) {
      setEmail('');
      setOpenDots(false);
      setNotice('Sending email...');
      Axios.post(`${apiAddress}/products/emailQuote`, {
        email: email,
        quoteNum: order.quoteNum,
      })
        .then((response) => {
          setNotice(response.data.message);
        })
        .catch(() => {
          setNotice('Error sending email. Please try again');
        });
    } else {
      setNotice('Invalid email address');
    }
  }

  function requestQuote() {
    setOpenDots(false);
    //setNotice("Requesting quote...");
    setOpenRequestForm(true);
  }

  return (
    <div
      className={` saved-quote order quote ${
        open === order.quoteNum ? 'open-order' : 'closed-order'
      }`}
    >
      <div
        className={`order-header`}
        style={{ position: 'relative' }}
      >
        <TbFileDescription className='pin' />

        <div>
          Quote# <span className='orderNum'>{order.quoteNum}</span>
        </div>
        {admin && (
          <div>
            User: <span className='orderNum'>{order.rep}</span>
          </div>
        )}
        <div>
          Submitted: <span className='orderNum'>{formatDateToTwoDigitsYear(order.quotedOn)}</span>
        </div>
        {!admin && (
          <div>
            Valid thru:
            <span className='orderDate'>{formatDateToTwoDigitsYear(order.validUntil)}</span>
          </div>
        )}
        <div>
          Cost: <span className='orderDeliver'>${order.total}</span>
        </div>
        <div className={`status-${order.status}`}>{order.status}</div>
        {open === order.quoteNum ?
          <FiChevronUp
            className='open'
            onClick={() => {
              setOpenItem(order.quoteNum);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setOpenItem(order.quoteNum);
              }
            }}
          />
        : <FiChevronDown
            className='open'
            onClick={() => {
              setOpenItem(order.quoteNum);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setOpenItem(order.quoteNum);
              }
            }}
          />
        }
        <div className='flex-center'>
          <HiDotsHorizontal
            onClick={() => {
              setOpenDots(!openDots);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setOpenDots(!openDots);
              }
            }}
            className='dots'
          />
          {openDots ?
            <div
              className='dotsMenu'
              ref={containerRef}
            >
              <button
                onClick={() => {
                  window.open(order.pdf);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    window.open(order.pdf);
                  }
                }}
              >
                Download PDF
              </button>
              <button
                onClick={getFreight}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    getFreight();
                  }
                }}
              >
                Resubmit
              </button>
              <button
                onClick={requestQuote}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    requestQuote();
                  }
                }}
              >
                Request a Price Quote
              </button>
              <div>
                <h2>Send this Quote:</h2>
                <input
                  placeholder='Enter Email'
                  required
                  name='email'
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                ></input>
                <button
                  onClick={sendEmail}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      sendEmail();
                    }
                  }}
                >
                  Send
                </button>
              </div>
            </div>
          : ''}
        </div>
      </div>
      {open === order.quoteNum ?
        <div className='order-details'>
          <QuoteDetails order={order} />
        </div>
      : ''}
      {notice ?
        <div className='notice'>{notice}</div>
      : ''}
      {openRequestForm ?
        <div>
          <div
            className='modal'
            style={{ opacity: '0', zIndex: '11' }}
          ></div>
          <div
            style={{
              position: 'fixed',
              backgroundColor: 'white',
              zIndex: '12',
              boxShadow: '1px 1px 5px #e1e1e1',
              left: '35rem',
              top: '15rem',
              borderRadius: '2rem',
            }}
          >
            <FreightCartForm
              showPriceForm={true}
              items={order.items}
              setShowPriceForm={setOpenRequestForm}
            />
          </div>
        </div>
      : ''}
    </div>
  );
};

export default SavedQuote;
