import { useEffect, useState } from 'react';
import { BiLeftArrow } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';
import useAxiosJWT from '../hooks/useAxiosJWT';

const EmployeePortal = () => {
  const navigate = useNavigate();
  const axiosJWT = useAxiosJWT();
  const [buttons, setButtons] = useState();
  const { parentTab } = useParams();
  const adminDivStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: '23rem',
    top: '18rem',
    cursor: 'pointer',
  };
  const EmployeePortalStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    width: '75rem',
    margin: 'auto',
    justifyContent: 'space-between',
  };

  function getData() {
    axiosJWT
      .get(`adminPortal/getEmployeeButtons`)
      .then((response) => {
        setButtons(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className='adminDiv'>
      {parentTab ?
        <div
          role='button'
          aria-label='Go Back to Employee Portal'
          tabIndex={0}
          style={adminDivStyle}
          onClick={() => {
            navigate('/employee-portal');
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              navigate('/employee-portal');
            }
          }}
        >
          <BiLeftArrow style={{ marginRight: '.5rem' }} /> BACK
        </div>
      : ''}
      <h2>{parentTab ? parentTab : 'Employee Portal'}</h2>
      <div style={EmployeePortalStyle}>
        {buttons
          ?.filter((i) => {
            return parentTab ?
                i.parent_tab === parentTab
              : !i.parent_tab || i.parent_tab?.toLowerCase() === 'null';
          })
          ?.map((i, index) => {
            return (
              <div
                key={index}
                style={{ margin: '1rem' }}
              >
                <button
                  className='editor-button'
                  onClick={() => {
                    if (i.link_type?.toLowerCase() === 'internal') {
                      navigate(`/employee-portal/${i.link}`);
                    } else {
                      window.open(i.link);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (i.link_type?.toLowerCase() === 'internal') {
                        navigate(`/employee-portal/${i.link}`);
                      } else {
                        window.open(i.link);
                      }
                    }
                  }}
                >
                  {i.text}
                </button>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default EmployeePortal;
