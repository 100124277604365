import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import BasicButton from '../NPSportal/basicButton';
import FormInput from '../NPSportal/FormInput';
const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

const TableAddNew = ({ columns, getData, setOpenAdd, setNotification }) => {
  const [addValues, setAddValues] = useState({});
  const [selectOption, setSelectOption] = useState('');
  const axiosJWT = useAxiosJWT();

  function handleInputChangeAdd(name, value) {
    if (addValues) {
      setAddValues((addValues) => ({
        ...addValues,
        [name]: value,
      }));
    } else {
      setAddValues({
        [name]: value,
      });
    }
  }

  function addHandler() {
    if (Object.keys(addValues)?.length > 0) {
      axiosJWT
        .post(`${apiAddress}/itemTable/addItemData`, {
          addValues,
          columns,
          table: columns[0].db_table_name,
        })
        .then(() => {
          setNotification('Successfully Added');
          getData();
        })
        .catch((err) => {
          setNotification('An Error Occured');
          console.error(err);
        });
    } else {
      setNotification('No Data');
    }
  }

  return (
    <div className='popup'>
      <div className='overlay'></div>
      <div className='popup-element overlay-popup'>
        <AiOutlineCloseCircle
          className='close-button'
          onClick={() => {
            setOpenAdd(false);
            setAddValues();
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setOpenAdd(false);
              setAddValues();
            }
          }}
        />
        <div className='add-new-header'>
          <h2 className='add-new-text'>Add New</h2>
          <div className='underline'></div>
        </div>
        <div className='form-popup-container'>
          {columns
            ?.filter((i) => {
              return i.display;
            })
            ?.filter((i) => i.value !== 'ID')
            ?.map((i, index) => {
              return (
                <FormInput
                  key={index}
                  inputType={i?.type?.toLowerCase()}
                  name={i.title}
                  value={addValues?.[i.title] || ''}
                  addValues={addValues}
                  columns={columns}
                  setSelectOption={setSelectOption}
                  selectOption={selectOption}
                  onClick={(value) => {
                    handleInputChangeAdd(i.title, value);
                  }}
                  onChange={(value) => {
                    handleInputChangeAdd(i.title, value);
                  }}
                  placeholder={`Enter ${i.title}`}
                />
              );
            })}
        </div>
        <div className='popup-submit-button'>
          <BasicButton
            text={'Add'}
            buttonStyle={{ width: '30%' }}
            type={'white-bordered-button'}
            onClick={() => {
              setOpenAdd(false);
              addHandler(addValues);
              setAddValues();
              getData();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TableAddNew;
