import { AdvancedImage } from '@cloudinary/react';
import { scale } from '@cloudinary/url-gen/actions/resize';
import FormatImageLink from '../../Components/Shared/FormatImageLink';

const EnvImage = ({ i, clickHandler, filter }) => {
  const decodeAndFormat = FormatImageLink();
  return (
    <div
      role='button'
      aria-label={i.category}
      tabIndex={0}
      className='envImage'
      onClick={() => {
        clickHandler(i);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          clickHandler(i);
        }
      }}
    >
      <div className='container'>
        <div className='child'>
          <AdvancedImage
            cldImg={decodeAndFormat(i.image)?.resize(scale().width(800))}
            alt={`Check out the ${i.category[0]}`}
          />
        </div>
        <span className='envImage-text'>
          {filter !== 'all' ?
            i.category.find((i) => i.toLowerCase() === filter.toLowerCase())
          : i.category[0]}
        </span>
      </div>
    </div>
  );
};

export default EnvImage;
