import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InfoPopup from '../../Components/Products/InfoPopup';
import ToggleButton from './ToggleButton';
const Options = ({
  insideDelivery,
  setInsideDelivery,
  liftgate,
  setLiftgate,
  limitedAccess,
  setLimitedAccess,
  residentialDelivery,
  setResidentialDelivery,
  zipCode,
  setZipCode,
  refNum,
  setRefNum,
  getFreight,
  quoteLoading,
  setMobileCurrentPage,
  instantFreight = false,
}) => {
  const navigate = useNavigate();
  const mobileScreen = useSelector((state) => state.isMobile.value.isMobile);
  return (
    <>
      <div className='options'>
        <h3>Please choose from the following options:</h3>
        <div className='toggle-div'>
          <div className='toggle-button'>
            <ToggleButton
              state={insideDelivery}
              setState={setInsideDelivery}
            />
            <div className='option-title'>Inside Delivery</div>
          </div>
          <div className='toggle-button'>
            <ToggleButton
              state={liftgate}
              setState={setLiftgate}
            />
            <div className='option-title'>Liftgate</div>
          </div>
          <div className='toggle-button'>
            <ToggleButton
              state={limitedAccess}
              setState={setLimitedAccess}
            />
            <div className='option-title'>
              Limited Access
              <span className='helper-text'>(Example: Church, School, Country Club)</span>
            </div>
          </div>
          <div className='toggle-button'>
            <ToggleButton
              state={residentialDelivery}
              setState={setResidentialDelivery}
            />
            <div className='option-title'>Residential Delivery</div>
          </div>
        </div>
      </div>
      {!instantFreight && (
        <div className='dealer-info'>
          <div className='zip'>
            <h3>Please enter the Zip Code:</h3>
            <div
              className='input'
              style={{ marginLeft: `${mobileScreen ? '103px' : '53px'}` }}
            >
              <input
                type='text'
                name='zipCode'
                placeholder='ZIP CODE'
                id='zipCode'
                value={zipCode}
                onChange={(e) => {
                  setZipCode(e.target.value);
                }}
                required
              ></input>
            </div>
          </div>
          <div className='zip'>
            <h3>Dealer Reference Number</h3>
            <div style={{ marginTop: '5px' }}>
              <InfoPopup
                message={'Optional field. Enter custom dealer reference number to add to quote'}
                color={'grey'}
              />
            </div>
            <div className='input'>
              <input
                type='text'
                name='refNum'
                placeholder='REF NUM'
                id='refNum'
                value={refNum}
                onChange={(e) => {
                  setRefNum(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    getFreight();
                  }
                }}
              ></input>
            </div>
          </div>
        </div>
      )}
      {!instantFreight && (
        <div className={mobileScreen ? 'cartButtons' : ''}>
          <div className='calculate-div'>
            <button
              className={`calculate ${quoteLoading ? 'active' : ''}`}
              onClick={() => {
                getFreight();
                if (mobileScreen) {
                  setMobileCurrentPage('pageThree');
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  getFreight();
                  if (mobileScreen) {
                    setMobileCurrentPage('pageThree');
                  }
                }
              }}
            >
              Calculate Freight Quote
            </button>
            <button
              className={`calculate`}
              onClick={() => {
                navigate('/saved-quotes');
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  navigate('/saved-quotes');
                }
              }}
            >
              View Saved Quotes
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Options;
