import Axios from 'axios';
import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useAxiosJWT from '../../hooks/useAxiosJWT';
const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

const RequireAuth = () => {
  const { auth, setAuth } = useAuth();
  const axiosJWT = useAxiosJWT();
  const location = useLocation();
  const [allowed, setAllowed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState({});

  async function getRoles() {
    const response = await Axios.post(`${apiAddress}/content/getRouteRoles`, {
      route:
        location.pathname === '/catalog' ? location.pathname + location.search : location.pathname,
    });
    const routes = response.data?.map((i) => i.permission_role);

    setAllowed(
      auth?.roles?.some((i) => routes?.includes(i.toString())) ||
        auth?.accounts
          ?.find((account) => account.last_login === 1)
          ?.AccountRoles?.some((i) => routes?.includes(i.toString()))
    );

    if (routes.length <= 0) {
      console.error(`no routes in route_permissions table for ${location.pathname}`);
    }

    setLoading(false);
  }

  async function checkToken() {
    var accessToken = await axiosJWT.get(`content/checkAccessToken`);
    setAccessToken(accessToken?.data?.message);
    if (!accessToken?.data?.message) {
      setAuth({});
    }
  }

  useEffect(() => {
    getRoles();
  }, [auth?.rep, auth?.roles]);

  useEffect(() => {
    checkToken();
  }, [location.pathname]);

  if (loading) {
    return null;
  }

  return (
    allowed ? <Outlet />
    : auth?.rep && accessToken ?
      <Navigate
        to='/unauthorized'
        state={{ from: location }}
        replace
      />
    : <Navigate
        to='/requestlogin'
        state={{ from: location }}
        replace
      />
  );
};

export default RequireAuth;
