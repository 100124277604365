import { useCart } from '../../context/CartContext';

const ToggleButton = ({ state, setState, updateDatabase, updateLocalVariable, sku }) => {
  const { updateNB } = useCart();

  return (
    <div
      role='button'
      aria-label={`Turn Toggle ${!state ? 'On' : 'off'}`}
      tabIndex={0}
      className={`${state ? 'toggle-button-on' : 'toggle-button-off'}`}
      onClick={() => {
        setState(!state);
        if (updateDatabase) {
          updateNB(!state, sku);
        }
        if (updateLocalVariable) {
          updateLocalVariable(!state);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setState(!state);
          if (updateDatabase) {
            updateNB(!state, sku);
          }
          if (updateLocalVariable) {
            updateLocalVariable(!state);
          }
        }
      }}
    >
      <div className={`${state ? 'toggle-icon-on' : 'toggle-icon-off'}`}></div>
    </div>
  );
};

export default ToggleButton;
