import EditTemplate from '../EditorPortal/EditTemplate';

const NondealerRequests = () => {
  const columns = [
    {
      type: 'text',
      name: 'ID',
      searchAllowed: true,
      editable: false,
      display: false,
      table: 'nondealer_requests',
      fieldName: 'ID',
    },
    {
      type: 'text',
      name: 'name',
      searchAllowed: true,
      editable: true,
      display: true,
      table: 'nondealer_requests',
      fieldName: 'name',
    },
    {
      type: 'text',
      name: 'company',
      searchAllowed: true,
      editable: true,
      display: true,
      table: 'nondealer_requests',
      fieldName: 'company',
    },
    {
      type: 'text',
      name: 'phone',
      searchAllowed: true,
      editable: true,
      display: true,
      table: 'nondealer_requests',
      fieldName: 'phone',
    },
    {
      type: 'text',
      name: 'email',
      searchAllowed: true,
      editable: true,
      display: true,
      table: 'nondealer_requests',
      fieldName: 'email',
    },
    {
      type: 'text',
      name: 'selection',
      searchAllowed: true,
      editable: false,
      display: false,
      table: 'nondealer_requests',
      fieldName: 'selection',
    },
    {
      type: 'text',
      name: 'comments',
      searchAllowed: true,
      editable: true,
      display: true,
      table: 'nondealer_requests',
      fieldName: 'comments',
    },
    {
      type: 'datetime',
      name: 'Date Submitted',
      searchAllowed: true,
      editable: true,
      display: true,
      table: 'nondealer_requests',
      fieldName: 'date_submitted',
    },
    {
      type: 'boolean',
      name: 'Response Sent',
      searchAllowed: true,
      editable: true,
      display: true,
      table: 'nondealer_requests',
      fieldName: 'responded',
    },
  ];

  return (
    <EditTemplate
      columns={columns}
      getEndPoint={'getGenericData'}
      title={'Nondealer Requests'}
      table={'nondealer_requests'}
      exportAvailable={true}
      // importBatch={true}
      // exportTemplate={true}
      // importUpdate={true}
      addHandlerIncoming={null}
    />
  );
};

export default NondealerRequests;
