import { Cloudinary } from '@cloudinary/url-gen';
import { quality, format } from '@cloudinary/url-gen/actions/delivery';

const cld = new Cloudinary({
  cloud: {
    cloudName: 'da3rom333',
  },
});

const FormatImageLink = () => {
  function decodeAndFormat(imgLink, noFormat) {
    if (!imgLink) {
      return null;
    }

    let publicId = imgLink;

    if (publicId.toLowerCase().includes('/upload/')) {
      const uploadIndex = publicId.indexOf('/upload/');
      const trimmedURL = publicId.substring(uploadIndex + '/upload/'.length);
      publicId = decodeURIComponent(trimmedURL);
    }

    const cldImage = cld.image(publicId);

    if (!imgLink.toLowerCase().endsWith('.gif') && !noFormat) {
      cldImage.delivery(quality('auto')).delivery(format('auto'));
    }

    return cldImage;
  }

  return decodeAndFormat;
};

export default FormatImageLink;
