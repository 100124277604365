import { useEffect, useState } from 'react';
import { useStageInfo } from '../../context/StageProvider';

function StageGrid() {
  const { spaceArea, zoom, maxAreaWidth, maxAreaLength, buildDims, setHover } = useStageInfo();
  const unitSize = 24;
  const verticalLines = [];
  const horizontalLines = [];
  const numbers = [];
  const [marginTop, setMarginTop] = useState();
  const [marginBottom, setMarginBottom] = useState();
  const [marginLeft, setMarginLeft] = useState();
  const [marginRight, setMarginRight] = useState();

  for (let x = 0; x <= maxAreaLength; x += unitSize) {
    if (x !== 0) {
      verticalLines.push(
        <line
          key={x}
          x1={x}
          y1='0'
          x2={x}
          y2={maxAreaWidth}
          stroke='#B3B3B3'
          strokeWidth='0.5'
          transform={`scale(${zoom})`}
        />
      );
      if (x % 10 === 0) {
        numbers.push(
          <text
            key={`tx-${x}`}
            x={x}
            y={12}
            fill='black'
            fontSize='12'
            textAnchor='middle'
            transform={`scale(${zoom})`}
            className='no-select'
          >
            {(x / unitSize) * 2}'
          </text>
        );
      }
    }
  }

  for (let y = 0; y <= maxAreaWidth; y += unitSize) {
    if (y !== 0) {
      horizontalLines.push(
        <line
          key={y}
          x1='0'
          y1={y}
          x2={maxAreaLength}
          y2={y}
          stroke='#B3B3B3'
          strokeWidth='0.5'
          transform={`scale(${zoom})`}
        />
      );
      if (y % 10 === 0) {
        numbers.push(
          <text
            key={`ty-${y}`}
            x={maxAreaLength - 12}
            y={y + 5}
            fill='black'
            fontSize='12'
            textAnchor='middle'
            transform={`scale(${zoom})`}
            className='no-select'
          >
            {(y / unitSize) * 2}'
          </text>
        );
      }
    }
  }

  useEffect(() => {
    if (spaceArea?.width && spaceArea?.length) {
      setMarginTop(
        (maxAreaWidth - spaceArea.width) / 2 - (((maxAreaWidth - spaceArea.width) / 2) % 24)
      );
      setMarginBottom(
        (maxAreaWidth - spaceArea.width) / 2 +
          Number(spaceArea.width) -
          (((maxAreaWidth - spaceArea.width) / 2) % 24)
      );
      setMarginLeft(
        (maxAreaLength - spaceArea.length) / 2 - (((maxAreaLength - spaceArea.length) / 2) % 24)
      );
      setMarginRight(
        (maxAreaLength - spaceArea.length) / 2 +
          Number(spaceArea.length) -
          (((maxAreaLength - spaceArea.length) / 2) % 24)
      );
    }
  }, [spaceArea]);

  return (
    <>
      <g>
        {verticalLines}
        {horizontalLines}
        {numbers}
      </g>
      {spaceArea?.width > 0 && spaceArea?.length > 0 && (
        <>
          <path
            d={`M ${marginLeft} ${marginTop} L ${marginRight} ${marginTop} L ${marginRight} ${marginBottom} L ${marginLeft} ${marginBottom} z`}
            fill='var(--lightgrey)'
            opacity={0.5}
            transform={`scale(${zoom})`}
          ></path>
          <text
            x={marginLeft}
            y={maxAreaWidth / 2}
            fontSize='14'
            textAnchor='end'
            transform={`scale(${zoom})`}
          >
            {Math.floor(spaceArea.width / 12)}' {spaceArea.width % 12}"
          </text>
          <text
            x={maxAreaLength / 2}
            y={marginTop}
            fontSize='14'
            textAnchor='middle'
            transform={`scale(${zoom})`}
          >
            {Math.floor(spaceArea.length / 12)}' {spaceArea.length % 12}"
          </text>
        </>
      )}
      {buildDims && (
        <>
          <line
            x1={buildDims.left.x}
            y1={buildDims.top.y - 15}
            x2={buildDims.left.x}
            y2={buildDims.top.y - 5}
            stroke='black'
            strokeWidth='1'
            transform={`scale(${zoom})`}
          />
          <line
            x1={buildDims.left.x}
            y1={buildDims.top.y - 10}
            x2={buildDims.right.x}
            y2={buildDims.top.y - 10}
            stroke='black'
            strokeWidth='1'
            transform={`scale(${zoom})`}
          />
          <line
            x1={buildDims.right.x}
            y1={buildDims.top.y - 15}
            x2={buildDims.right.x}
            y2={buildDims.top.y - 5}
            stroke='black'
            strokeWidth='1'
            transform={`scale(${zoom})`}
          />
          <text
            x={buildDims.left.x + (buildDims.right.x - buildDims.left.x) / 2}
            y={buildDims.top.y - 16}
            fill='black'
            fontSize='12'
            textAnchor='middle'
            transform={`scale(${zoom})`}
            onMouseEnter={(event) =>
              setHover({
                text: 'Displayed dimensions are an estimate and may not reflect exact measurements.',
                color: 'darkgrey',
                top: event.clientY,
                left: event.clientX,
              })
            }
          >
            {Math.floor(parseFloat(buildDims.right.x - buildDims.left.x).toFixed(1) / 12)}'
            {Math.round(parseFloat(buildDims.right.x - buildDims.left.x).toFixed(1) % 12)}"
          </text>
          <line
            x1={buildDims.left.x - 15}
            y1={buildDims.top.y}
            x2={buildDims.left.x - 5}
            y2={buildDims.top.y}
            stroke='black'
            strokeWidth='1'
            transform={`scale(${zoom})`}
          />
          <line
            x1={buildDims.left.x - 15}
            y1={buildDims.bottom.y}
            x2={buildDims.left.x - 5}
            y2={buildDims.bottom.y}
            stroke='black'
            strokeWidth='1'
            transform={`scale(${zoom})`}
          />
          <line
            x1={buildDims.left.x - 10}
            y1={buildDims.top.y}
            x2={buildDims.left.x - 10}
            y2={buildDims.bottom.y}
            stroke='black'
            strokeWidth='1'
            transform={`scale(${zoom})`}
          />
          <text
            x={buildDims.left.x - 16}
            y={buildDims.top.y + (buildDims.bottom.y - buildDims.top.y) / 2}
            fill='black'
            fontSize='12'
            textAnchor='end'
            transform={`scale(${zoom})`}
            onMouseEnter={(event) =>
              setHover({
                text: 'Displayed dimensions are an estimate and may not reflect exact measurements.',
                color: 'darkgrey',
                top: event.clientY,
                left: event.clientX,
              })
            }
          >
            {Math.floor(parseFloat(buildDims.bottom.y - buildDims.top.y).toFixed(1) / 12)}'
            {Math.round(parseFloat(buildDims.bottom.y - buildDims.top.y).toFixed(1) % 12)}"
          </text>
        </>
      )}
    </>
  );
}

export default StageGrid;
