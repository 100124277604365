import { useEffect, useState } from 'react';
import { HiOutlineDocumentDownload } from 'react-icons/hi';
import useExportToExcel from '../Shared/useExportToExcel';
import Spreadsheet from 'react-spreadsheet';

const OrderInventoryPopup = ({ incomingData }) => {
  const [groupedData, setGroupedData] = useState();
  const ExportToExcel = useExportToExcel();

  function organizeData() {
    const { data, row } = incomingData;

    const groups = [];

    data.forEach((item) => {
      if (!item.po_num) {
        let onHandGroup = groups.find((i) => i.title === 'On Hand');

        if (!onHandGroup) {
          onHandGroup = {
            title: 'On Hand',
            originalQuantity: row.on_hand_quantity,
            currentQuantity: row.available_now,
            items: [],
          };
          groups.push(onHandGroup);
        }

        onHandGroup.items.push(item);
      } else if (item.po_num === row.incoming_po) {
        let incomingGroup = groups.find((i) => i.title === row.incoming_po);

        if (!incomingGroup) {
          incomingGroup = {
            title: row.incoming_po,
            date: row.incoming_po_date,
            originalQuantity: row.incoming_original_quantity,
            currentQuantity: row.incoming_quantity,
            items: [],
          };
          groups.push(incomingGroup);
        }

        incomingGroup.items.push(item);
      } else if (item.po_num === row.future_po) {
        let futureGroup = groups.find((i) => i.title === row.future_po);

        if (!futureGroup) {
          futureGroup = {
            title: row.future_po,
            date: row.future_po_date,
            originalQuantity: row.future_original_quantity,
            currentQuantity: row.future_quantity,
            items: [],
          };
          groups.push(futureGroup);
        }

        futureGroup.items.push(item);
      }
    });

    setGroupedData(
      groups?.sort((a, b) => {
        const aHasData = Boolean(a.date);
        const bHasData = Boolean(b.date);

        if (!aHasData && bHasData) {
          return -1;
        }
        if (aHasData && !bHasData) {
          return 1;
        }

        if (aHasData && bHasData) {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateA - dateB;
        }

        return 0;
      })
    );
  }

  function handleExport(group) {
    ExportToExcel(group.items, group.title);
  }

  const formatForSpreadsheet = (items) => {
    return items.map((item) => {
      // eslint-disable-next-line no-unused-vars
      const { component_sku, po_num, line_number, location, unique_id, ...rest } = item;
      const values = Object.values(rest);

      return values.map((value) => ({
        value,
        readOnly: true,
      }));
    });
  };

  const headers = (items) => {
    // eslint-disable-next-line no-unused-vars
    const { component_sku, po_num, line_number, location, unique_id, ...rest } = items[0];
    return Object.keys(rest);
  };

  useEffect(() => {
    organizeData();

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className='inner-padding'>
      {groupedData?.map((group) => (
        <div
          key={group.title}
          style={{ marginBottom: '20px' }}
        >
          <>
            <div
              style={{ marginTop: '10px' }}
              className={`in-stock-table-header`}
            >
              <div
                style={{ alignItems: 'end' }}
                className='basic-flex'
              >
                <h2>{Number(group.title) ? `PO #${group.title}` : group.title}</h2>

                <>
                  {group.date && <div className='header-subtext'>{group.date}</div>}
                  <div className='header-subtext'>Original Quantity: {group.originalQuantity}</div>
                  <div className='header-subtext'>Current Quantity: {group.currentQuantity}</div>
                </>
              </div>

              <div>
                <HiOutlineDocumentDownload
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => handleExport(group)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleExport(group);
                    }
                  }}
                />
              </div>
            </div>
          </>
          <Spreadsheet
            columnLabels={headers(group.items)}
            data={formatForSpreadsheet(group.items)}
          />
        </div>
      ))}
    </div>
  );
};

export default OrderInventoryPopup;
