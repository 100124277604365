import { useState } from 'react';
import AccountDropdown from '../../Components/NPSportal/AccountDropdown';
import BasicDropdown from '../../Components/NPSportal/BasicDropdown';
import BasicSearchBar from '../../Components/NPSportal/BasicSearchBar';
import ChatIcon from '../../Components/NPSportal/ChatIcon';
import FormInput from '../../Components/NPSportal/FormInput';
import NotificationIcon from '../../Components/NPSportal/NotificationIcon';
import ReusablePopup from '../../Components/NPSportal/ReusablePopup';
import Tabs from '../../Components/NPSportal/Tabs';
import TicketType from '../../Components/NPSportal/TicketType';
import BasicButton from '../../Components/NPSportal/basicButton';
import UploadFiles from '../../Components/Service Portal/UploadFiles';
import useGeneralImages from '../../hooks/useGeneralImages';

const ReusableComponents = () => {
  const { generalImages } = useGeneralImages();
  const [openPopup, setOpenPopup] = useState(false);
  // const [chatOpen, setChatOpen] = useState(false);
  const [inputValue, setInputValue] = useState({
    name: '',
    email: '',
    message: '',
  });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100rem',
        justifyContent: 'space-between',
        alignItems: 'start',
        marginBottom: '10rem',
      }}
      className='inner-padding'
    >
      <h2
        style={{
          alignSelf: 'center',
          marginBottom: '2rem',
          textDecorationColor: '#c0e66c',
          textDecorationLine: 'underline',
          textUnderlineOffset: '15px',
        }}
      >
        Reusable Components and Styles
      </h2>
      <div style={{ width: '8.4rem' }}>
        <h3>Notification Icon</h3>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <NotificationIcon
            // setChatOpen={setChatOpen}
            texts={[
              {
                ID: 63,
                date: 'Wed May 15 2024 14:48:30 GMT-0400 (Eastern Daylight Time)',
                text: 'more ',
                name: 'Hadassa Rosenthal',
                seen: 0,
                ticket_ID: 2,
                username: 'hrosenthal@nationalpublicseating.com',
              },
            ]}
          />
          <NotificationIcon />
        </div>
      </div>
      <div>
        <h3>Account Dropdown</h3>
        <AccountDropdown />
      </div>
      <div>
        <h3>Ticket Types</h3>
        <div
          style={{ width: '70rem' }}
          className='basic-flex'
        >
          <TicketType
            ticket={{
              title: 'Warranty',
              icon: generalImages.find((i) => i.publicId === 'Warranty_hkasxq')?.imageLink,
              color: 'green',
            }}
          />
          <TicketType
            ticket={{
              title: 'Missing Parts',
              icon: generalImages.find((i) => i.publicId === 'Missing_Parts_izclp7')?.imageLink,
              color: 'lightyellow',
            }}
          />
          <TicketType
            ticket={{
              title: 'Returns & Cancellations',
              icon: generalImages.find((i) => i.publicId === 'Returns_efn2ua')?.imageLink,
              color: 'darkyellow',
            }}
          />
          <TicketType
            ticket={{
              title: 'Freight Damage',
              icon: generalImages.find((i) => i.publicId === 'Freight_Damage_eiksby')?.imageLink,
              color: 'blue',
            }}
          />
        </div>
      </div>
      <div>
        <h3>Status</h3>
        <div
          style={{ width: '40rem' }}
          className='basic-flex'
        >
          <TicketType
            ticket={{
              title: 'In Production',
              color: 'lightyellow',
            }}
          />
          <TicketType
            ticket={{
              title: 'Ready to Ship',
              color: 'green',
            }}
          />
          <TicketType
            ticket={{
              title: 'Shipped',
              color: 'blue',
            }}
          />
        </div>
      </div>
      <div>
        <h3>Response Status</h3>

        <div
          style={{ width: '40rem' }}
          className='basic-flex'
        >
          <TicketType
            ticket={{
              title: 'Rejected',
              color: 'red',
              response_status: true,
            }}
          />
          <TicketType
            ticket={{
              title: 'Credit Issued',
              color: 'blue',
              response_status: true,
            }}
          />
          <TicketType
            ticket={{
              title: 'Approved',
              color: 'green',
              response_status: true,
            }}
          />
        </div>
      </div>
      <div>
        <h3>Chat Icon</h3>
        <div
          style={{ width: '10rem' }}
          className='basic-flex'
        >
          <ChatIcon
            // setChatOpen={setChatOpen}
            texts={[
              {
                ID: 63,
                date: 'Wed May 15 2024 14:48:30 GMT-0400 (Eastern Daylight Time)',
                text: 'more ',
                name: 'Hadassa Rosenthal',
                seen: 0,
                ticket_ID: 2,
                username: 'hrosenthal@nationalpublicseating.com',
              },
            ]}
          />
          <ChatIcon
            texts={[
              {
                ID: 63,
                date: 'Wed May 15 2024 14:48:30 GMT-0400 (Eastern Daylight Time)',
                text: 'more ',
                name: 'Hadassa Rosenthal',
                seen: 1,
                ticket_ID: 2,
                username: 'hrosenthal@nationalpublicseating.com',
              },
            ]}
          />
          <ChatIcon texts={[]} />
        </div>
      </div>
      <div>
        <h3>Basic Dropdown</h3>

        <div
          style={{ width: '40rem' }}
          className='basic-flex'
        >
          <BasicDropdown
            list={[
              {
                text: 'Freight Damage',
                icon: generalImages.find((i) => i.publicId === 'Freight_Damage_eiksby')?.imageLink,
              },
              {
                text: 'Returns & Cancellations',
                icon: generalImages.find((i) => i.publicId === 'Returns_efn2ua')?.imageLink,
              },
              {
                text: 'Missing Parts',
                icon: generalImages.find((i) => i.publicId === 'Missing_Parts_izclp7')?.imageLink,
              },
              {
                text: 'Warranty',
                icon: generalImages.find((i) => i.publicId === 'Warranty_hkasxq')?.imageLink,
              },
            ]}
            title={'How can we help you?'}
          />
          <BasicDropdown
            list={[
              {
                text: 'Approve',
              },
              {
                text: 'Reject',
              },
              {
                text: 'Issue a Credit',
              },
              {
                text: 'Send an Alternative',
              },
              {
                text: 'Approve Replacement',
              },
            ]}
            title={'Next step'}
          />
        </div>
      </div>
      <div>
        <h3>Button Options</h3>

        <div
          style={{ width: '50rem' }}
          className='basic-flex'
        >
          <BasicButton
            text={'White Bordered Button'}
            type={'white-bordered-button'}
          />

          <BasicButton
            text={'Basic Green Button'}
            type={'basic-green-button'}
          />

          <BasicButton
            text={'Basic White Button'}
            type={'basic-white-button'}
          />
        </div>
      </div>
      <div>
        <h3>Tabs</h3>

        <div
          style={{ width: '50rem' }}
          className='basic-flex'
        >
          <Tabs
            tabs={[
              { title: 'All Orders', action: () => setOpenPopup(false) },
              { title: 'Active', action: () => setOpenPopup(true) },
              { title: 'Completed', action: () => setOpenPopup(false) },
              { title: 'On-Hold', action: () => setOpenPopup(false) },
              { title: 'Support Desk', action: () => setOpenPopup(false) },
            ]}
          />
        </div>
      </div>
      <div>
        <h3>Basic Search Bar</h3>
        <div
          style={{ width: '50rem' }}
          className='basic-flex'
        >
          <BasicSearchBar />
        </div>
      </div>
      <div>
        <h3>Basic Popup</h3>

        <div
          style={{ width: '50rem' }}
          className='basic-flex'
        >
          <BasicButton
            onClick={() => setOpenPopup(true)}
            text={'Open Popup'}
            type={'white-bordered-button'}
          />
          <ReusablePopup
            innerComponent={
              <div
                style={{ flexDirection: 'column', justifyContent: 'center' }}
                className='basic-flex'
              >
                <h2>This is a popup example</h2>
                <div
                  style={{ width: '55%' }}
                  className='basic-flex'
                >
                  <h3>Components</h3>
                  <BasicDropdown
                    list={[
                      {
                        text: 'Freight Damage',
                        icon: generalImages.find((i) => i.publicId === 'Freight_Damage_eiksby')
                          ?.imageLink,
                      },
                      {
                        text: 'Returns & Cancellations',
                        icon: generalImages.find((i) => i.publicId === 'Returns_efn2ua')?.imageLink,
                      },
                      {
                        text: 'Missing Parts',
                        icon: generalImages.find((i) => i.publicId === 'Missing_Parts_izclp7')
                          ?.imageLink,
                      },
                      {
                        text: 'Warranty',
                        icon: generalImages.find((i) => i.publicId === 'Warranty_hkasxq')
                          ?.imageLink,
                      },
                    ]}
                    title={'Missing Elements'}
                  />
                </div>
                <UploadFiles />
                <div>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                  nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                  voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                  est laborum Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                  veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                  sunt in culpa qui officia deserunt mollit anim id est laborum Lorem ipsum dolor
                  sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                  et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                  reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                  ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                  proident, sunt in culpa qui officia deserunt mollit anim id est laborum Lorem
                  ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum
                </div>
              </div>
            }
            title={'Reusable Popup'}
            open={openPopup}
            setOpen={setOpenPopup}
            continueAction={() => setOpenPopup(false)}
          />
        </div>
      </div>
      <div>
        <h3>Form Input</h3>
        <form style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <FormInput
            legend='Name'
            name={'name'}
            type='text'
            value={inputValue.name}
            onChange={(event) => setInputValue({ ...inputValue, name: event.target.value })}
            placeholder='Enter name'
          />
          <FormInput
            legend='Email'
            name='email'
            asterisk={true}
            type='email'
            value={inputValue.email}
            onChange={(event) => setInputValue({ ...inputValue, email: event.target.value })}
            placeholder='Enter email'
            required={true}
          />
          <FormInput
            legend='Message'
            name='message'
            type='text'
            inputType='textArea'
            value={inputValue.message}
            onChange={(event) => setInputValue({ ...inputValue, message: event.target.value })}
            placeholder='Write message here...'
          />
        </form>
      </div>
      <div>
        <h3>Class Names</h3>
        <div
          style={{ flexWrap: 'wrap' }}
          className='basic-flex'
        >
          <div className='percent-text'>percent-text</div>
        </div>
        <div style={{ marginTop: '1rem' }}>
          checkbox:
          <input
            className='checkbox'
            type='checkbox'
          ></input>
        </div>
      </div>
      <div>
        <h3>Color Library</h3>

        <div
          style={{ flexWrap: 'wrap' }}
          className='basic-flex'
        >
          <h4 style={{ color: 'var(--darkblue)' }}>{'var(--darkblue)'}</h4>
          <h4 style={{ color: 'var(--faded-blue)' }}>{'var(--faded-blue)'}</h4>
          <h4 style={{ color: 'var(--darkgreen)' }}>{'var(--darkgreen)'}</h4>
          <h4 style={{ color: 'var(--darkgrey)' }}>{'var(--darkgrey)'}</h4>
          <h4 style={{ color: 'var(--lightgrey)' }}>{'var(--lightgrey)'}</h4>
          <h4 style={{ color: 'var(--lightgrey-50)' }}>{'var(--lightgrey-50)'}</h4>
          <h4 style={{ color: 'var(--lightgrey-30)' }}>{'var(--lightgrey-30)'}</h4>
          <h4 style={{ color: 'var(--lightgreen)' }}>{'var(--lightgreen)'}</h4>
          <h4 style={{ color: 'var(--darker-light-green)' }}>{'var(--darker-light-green)'}</h4>
          <h4 style={{ color: 'var(--status-blue)' }}>{'var(--status-blue)'}</h4>
          <h4 style={{ color: 'var(--status-blue-hover)' }}>{'var(--status-blue-hover)'}</h4>
          <h4 style={{ color: 'var(--status-green)' }}>{'var(--status-green)'}</h4>
          <h4 style={{ color: 'var(--status-green-hover' }}>{'var(--status-green-hover)'}</h4>
          <h4 style={{ color: 'var(--status-lightyellow)' }}>{'var(--status-lightyellow)'}</h4>
          <h4 style={{ color: 'var(--status-lightyellow-hover)' }}>
            {'var(--status-lightyellow-hover)'}
          </h4>
          <h4 style={{ color: 'var(--status-darkyellow)' }}>{'var(--status-darkyellow)'}</h4>
          <h4 style={{ color: 'var(--status-darkyellow-hover)' }}>
            {'var(--status-darkyellow-hover)'}
          </h4>
          <h4 style={{ color: 'var(--status-darkyellow)' }}>{'var(--status-darkyellow)'}</h4>
          <h4 style={{ color: 'var(--status-darkyellow-hover)' }}>
            {'var(--status-darkyellow-hover)'}
          </h4>
          <h4 style={{ color: 'var(--status-red)' }}>{'var(--status-red)'}</h4>
          <h4 style={{ color: 'var(--status-red-hover)' }}>{'var(--status-red-hover)'}</h4>
          <h4 style={{ color: 'var(--status-red)' }}>{'var(--status-red)'}</h4>
          <h4 style={{ color: 'var(--status-red-hover)' }}>{'var(--status-red-hover)'}</h4>
        </div>
        {/* {chatOpen && <Chat setChatOpen={setChatOpen} />} */}
      </div>
    </div>
  );
};

export default ReusableComponents;
