import { useEffect } from 'react';
import { useStageInfo } from '../../context/StageProvider';
import SelectStageItems from './SelectStageItems';
import { calculateDistance, findExposedSides } from './StageFunctions';

function AddSkirting() {
  const {
    getStageData,
    stageBuild,
    skirting,
    setSkirting,
    skirtingData,
    setSkirtingData,
    selectedColor,
    setSelectedColor,
    selectedStyle,
    setSelectedStyle,
  } = useStageInfo();

  function handleSelect(selectedSides) {
    const holderArray = [];
    removeDuplicates(selectedSides).forEach((side) => {
      const item = stageBuild.find((i) => i.uid === side.itemUid);
      //dont add skirting for risers
      if (item.capacityType !== 'Standing') {
        const skirtingLengths = findBestCombination(side.corner1, side.corner2);
        skirtingLengths.forEach((length) => {
          if (length) {
            holderArray.push({
              ...side,
              product: skirtingData?.skuList.find(
                (sku) =>
                  sku.configOptions.Color.selectionName === selectedColor &&
                  sku.configOptions.Style.selectionName === selectedStyle &&
                  sku.configOptions.Height.value ===
                    item?.productList[0].configOptions.Height.value &&
                  Math.abs(Number(sku.configOptions.Length.value)) === length
              ),
            });
          }
        });
      }
    });
    setSkirting(holderArray);
  }

  function findBestCombination(corner1, corner2) {
    //finds skirting that fits and then calls helper function recursively with remaining length to find another skirting that fits,
    //	until there is no remianing length. saves the combination in memo. returns combination that has the least extra skirting.

    const stageWidth = Math.round(calculateDistance(corner1.x, corner1.y, corner2.x, corner2.y));
    const skirtingLengths = skirtingData.configOptions.config.Size.Length.values.map((length) =>
      Number(length.selectionName.replace(/"$/, ''))
    );
    const memo = {};
    function helper(remainingWidth) {
      if (remainingWidth <= 0) {
        return { combination: [], extra: -remainingWidth };
      }
      if (remainingWidth in memo) {
        return memo[remainingWidth];
      }

      let bestCombination = { combination: null, extra: Infinity };

      for (const length of skirtingLengths) {
        const result = helper(remainingWidth - length);
        const newExtra = result.extra;

        if (newExtra < bestCombination.extra) {
          bestCombination = {
            combination: [length, ...result.combination],
            extra: newExtra,
          };
        }
      }

      memo[remainingWidth] = bestCombination;
      return bestCombination;
    }

    return helper(stageWidth).combination;
  }

  function removeDuplicates(array) {
    const seenIds = new Set();
    return array.filter((item) => {
      if (seenIds.has(item.id)) {
        return false;
      } else {
        seenIds.add(item.id);
        return true;
      }
    });
  }

  useEffect(() => {
    getStageData('Skirting', skirtingData, setSkirtingData);
    if (skirting.length) {
      setSelectedColor(skirting[0].product.configOptions.Color.selectionName);
      setSelectedStyle(skirting[0].product.configOptions.Style.selectionName);
    }
  }, []);
  useEffect(() => {
    if (skirtingData && !skirting.length) {
      setSelectedColor(selectedColor || 'Black');
      setSelectedStyle(selectedStyle || 'Box Pleat');
    }
  }, [skirtingData]);
  useEffect(() => {
    if (selectedStyle && selectedColor && skirtingData) {
      handleSelect(skirting.length ? skirting : findExposedSides(stageBuild));
    }
  }, [selectedColor, selectedStyle]);

  return (
    <SelectStageItems
      type='skirting'
      stageBuild={stageBuild}
      selected={skirting}
      setSelected={handleSelect}
      disable={findExposedSides(stageBuild).filter(
        (side) =>
          side.itemUid.toString().includes('_') ||
          stageBuild.find((i) => i.uid === side.itemUid)?.productList[0].productInfo.Series ===
            'Risers'
      )}
    />
  );
}

export default AddSkirting;
