import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../CSS/DealerDrop.css';

const DealerPortalDrop = ({ data }) => {
  const navigate = useNavigate();
  const [link, setLink] = useState('');

  return (
    <div className='dealer-div'>
      <div className='dealer-title-div'>
        <h2>Dealer Portal</h2>
      </div>
      <div className='grid-container'>
        {data?.map((e, index) => (
          <div
            role='button'
            aria-label={e.title}
            tabIndex={0}
            key={e.link + index}
            className='dealer-tile'
            onClick={() => {
              if (link) {
                navigate(`/${link}`);
              } else {
                navigate(`/${e.link}`);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (link) {
                  navigate(`/${link}`);
                } else {
                  navigate(`/${e.link}`);
                }
              }
            }}
          >
            <img
              src={e.image}
              alt=''
            ></img>
            <div>
              <h3>{e.title}</h3>
              {e.sublinks.map((s, index) => {
                return (
                  <h4
                    role='button'
                    aria-label={s.title}
                    tabIndex={0}
                    key={s.title + index}
                    onMouseEnter={() => {
                      setLink(s.link);
                    }}
                    onMouseLeave={() => {
                      setLink(null);
                    }}
                    onClick={() => {
                      navigate(`/${link}`);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        navigate(`/${link}`);
                      }
                    }}
                  >
                    {s.title}
                  </h4>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DealerPortalDrop;
