import Axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../../CSS/EnvironmentsSlider.css';
import '../../CSS/MobileApp/M_EnvironmentsSlider.css';
import ImageSlider from '../Shared/ImageSlider';
import EnvironmentCard from './EnvironmentCard';

const EnvironmentsSlider = () => {
  const [images, setImages] = useState();
  const [imageElementsMapped, setImageElementsMapped] = useState();
  const navigate = useNavigate();
  const mobileScreen = useSelector((state) => state.isMobile.value);

  const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;
  function getData() {
    Axios.get(`${apiAddress}/content/getSliderEnvironments`).then((response) => {
      setImages(response.data);
    });
  }

  useEffect(() => {
    getData();
  }, []);
  const imageElements = [];
  useEffect(() => {
    images?.forEach((i) => {
      imageElements.push(
        <EnvironmentCard
          src={i.imageLink}
          title={i.environment}
          key={i.key}
        />
      );
    });
    setImageElementsMapped(imageElements);
  }, [images]);

  function imageClickHandler(item) {
    navigate(`/environments?filter=${item.props.title[0]}`);
  }
  return (
    <div className='grid-container-env'>
      <div className='background-swoosh'></div>
      <div className='env-front'>
        <div className='env-slider-title'>
          <h1 className='env-title-1'>VIEW PRODUCTS</h1>
          <h1 className='env-title-2'>BY ENVIRONMENTS</h1>
        </div>

        <button
          className='view-all-button'
          onClick={() => {
            navigate(`/Environments`);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              navigate(`/Environments`);
            }
          }}
        >
          VIEW ALL
        </button>
      </div>
      <div className='env-slider'>
        {imageElementsMapped?.length ?
          <ImageSlider
            images={imageElementsMapped}
            imageClickHandler={imageClickHandler}
            intervalMove={4000}
            numOfDisplay={mobileScreen.isMobile ? 3 : 4}
          />
        : ''}
      </div>
    </div>
  );
};

export default EnvironmentsSlider;
