import Axios from 'axios';
import { useEffect, useState } from 'react';
import { SlArrowRight } from 'react-icons/sl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useGeneralImages from '../hooks/useGeneralImages';
import TemplateTilePage from '../Components/Shared/TemplateTilePage';

const NewProducts = () => {
  const navigate = useNavigate();
  const { generalImages } = useGeneralImages();
  const [products, setProducts] = useState();
  const [currentYear, setCurrentYear] = useState();
  const mobileScreen = useSelector((state) => state.isMobile.value);
  const banner =
    mobileScreen.isMobile ?
      generalImages.find((i) => i.publicId === 'new_products_banner')?.imageLink
    : generalImages.find((i) => i.publicId === 'NewProducts_qqpec6')?.imageLink;
   
  const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

  useEffect(() => {
    document.title = `New Products - National Public Seating`;
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    Axios.get(`${apiAddress}/content/getNewProducts`).then((response) => {
      setProducts(response.data.newProductsByYear);
    });
  }

  function clickHandler(s) {
    navigate(`/products/${s.link}`);
  }

  return (
    <div>
      <div className={'banner'}>
        <img
          src={banner}
          alt='New Product Banner'
        ></img>
      </div>
      <div className='mediaDownloads'>
        <div className='navigateBar inner-padding'>
          <a href='/categories'>Products</a>
          <SlArrowRight />
          <div className='current'>New Products</div>
        </div>
      </div>
      {
        //render the products in descending order
        products &&
          Object.keys(products)
            ?.reverse()
            .map((year) => {
              if (!currentYear || year > currentYear) {
                setCurrentYear(year);
              }
              return (
                <TemplateTilePage
                  key={year}
                  list={products[year]}
                  clickHandler={clickHandler}
                  year={year}
                  currentYear={currentYear}
                />
              );
            })
      }
    </div>
  );
};

export default NewProducts;
