import { createSlice } from '@reduxjs/toolkit';
import { isMobile } from 'react-device-detect';

export const isMobileSlice = createSlice({
  name: 'isMobile',
  initialState: {
    value: { isMobile: isMobile },
  },
  reducers: {
    setScreenType: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { setScreenType } = isMobileSlice.actions;
export default isMobileSlice.reducer;
