import Axios from 'axios';
import { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import AboutDrop from '../Dropdown/AboutDrop';
import DealerPortalDrop from '../Dropdown/DealerPortalDrop';
import Environmentsdrop from '../Dropdown/Environmentsdrop';
import MediaCenterDrop from '../Dropdown/MediaCenterDrop';
import ProductsDrop from '../Dropdown/ProductsDrop';
import HeaderItem from './HeaderItem';

const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;
const HeaderNavLinks = () => {
  const [categories, setCategories] = useState([]);
  const [envDrop, setEnvDrop] = useState();
  const [mediaDrop, setMediaDrop] = useState();
  const [dealerDrop, setDealerDrop] = useState();
  const [aboutDrop, setAboutDrop] = useState();

  const [productCategories, setProductCategories] = useState([]);
  const { auth } = useAuth();
  function getCategories() {
    Axios.get(`${apiAddress}/products/getCategories`).then((response) => {
      setCategories(response.data);
    });
  }

  function getData() {
    Axios.post(`${apiAddress}/content/getDropDownTabs`, {}).then((response) => {
      setEnvDrop(response.data.dropdown.filter((i) => i.tab.toLowerCase() === 'environment'));
      setMediaDrop(response.data.dropdown.filter((i) => i.tab.toLowerCase() === 'media'));

      (
        auth?.accounts
          ?.find((i) => i.last_login === 1)
          ?.AccountRoles?.some((role) => [1100, 1200].includes(role))
      ) ?
        setDealerDrop(response.data.dropdown.filter((i) => i.tab.toLowerCase() === 'dealer'))
      : setDealerDrop(
          response.data.dropdown
            .filter((i) => i.tab.toLowerCase() === 'dealer')
            .filter((i) => i.ID !== 50)
        );
      setAboutDrop(response.data.dropdown.filter((i) => i.tab.toLowerCase() === 'about'));
      setProductCategories(
        response.data.dropdown.filter((i) => i.tab.toLowerCase() === 'products')
      );
    });
  }
  useEffect(() => {
    getCategories();
    getData();
    return () => {
      setCategories([]);
    };
  }, []);

  useEffect(() => {
    getData();
  }, [auth?.rep]);

  return (
    <li className='header-nav-links'>
      <HeaderItem
        itemText={"WHAT'S NEW"}
        link={'/whats-new'}
        // dropdownObject={false}
      />

      <HeaderItem
        itemText={'PRODUCTS'}
        dropdownObject={
          <ProductsDrop
            categories={categories}
            productCategories={productCategories}
          />
        }
        categories={categories}
        link={'/categories'}
      />

      <HeaderItem
        itemText={'ENVIRONMENTS'}
        dropdownObject={<Environmentsdrop data={envDrop} />}
        // openDefault={true}
        link={'/environments'}
      />
      <HeaderItem
        itemText={'MEDIA CENTER'}
        dropdownObject={
          <MediaCenterDrop
            data={mediaDrop}
            categories={categories.productList}
          />
        }
        // openDefault={true}
        link={'/media-center'}
      />
      <HeaderItem
        itemText={'DEALER PORTAL'}
        dropdownObject={<DealerPortalDrop data={dealerDrop} />}
        // openDefault={true}
        link={'/dealer-portal'}
      />
      <HeaderItem
        itemText={'ABOUT'}
        dropdownObject={<AboutDrop data={aboutDrop} />}
        // openDefault={true}
        link={'/about'}
      />
    </li>
  );
};

export default HeaderNavLinks;
