import EditTemplate from '../EditorPortal/EditTemplate';

const EditCalendarTasks = () => {
  return (
    <div>
      <EditTemplate
        title={'Cycle Calendar Tasks'}
        getEndPoint={'getGenericData'}
        table={'cycle_calendar_tasks'}
      />
    </div>
  );
};

export default EditCalendarTasks;
