import Axios from 'axios';
import { useState } from 'react';
import { VscFilePdf } from 'react-icons/vsc';
import { useSelector } from 'react-redux';
const Quote = ({ quote, setNotice }) => {
  const [email, setEmail] = useState('');
  var date = new Date();
  date.setDate(date.getDate() + 30);

  const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;
  const mobileScreen = useSelector((state) => state.isMobile.value.isMobile);

  var dd = String(date.getDate()).padStart(2, '0');
  var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = date.getFullYear();
  var validDate = `${mm}/${dd}/${yyyy}`;

  function validateEmail() {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  function sendEmail(email) {
    if (validateEmail()) {
      setEmail('');
      setNotice('Sending email...');
      Axios.post(`${apiAddress}/products/emailQuote`, {
        email: email,
        quoteNum: quote[0],
      })
        .then((response) => {
          setNotice(response.data.message);
        })
        .catch((err) => {
          setNotice('Error sending email. Please try again');
          console.error(err);
        });
    } else {
      setNotice('Invalid email address');
    }
  }

  function savePDF() {
    window.open(quote[3]);
  }

  return (
    <div className='quote'>
      {mobileScreen && <div className='quote-result'>results</div>}
      <div className='header'>
        <div>
          Quote #<b>{quote[0]}:</b>
        </div>
        <div>${quote[1]}</div>
      </div>
      <div className='details'>{quote[2]}</div>
      <div className='valid'>
        <div>
          This quote is valid until <b>{validDate}</b>
        </div>
        {mobileScreen && (
          <button
            className='savepdf'
            onClick={savePDF}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                savePDF();
              }
            }}
          >
            Save as PDF <VscFilePdf />
          </button>
        )}
      </div>
      <div className='send-quote'>Send a copy of the quote:</div>
      <div className='send-email-div'>
        <div className='send-quote-input-div'>
          <input
            type='email'
            name='email'
            placeholder='Enter email address'
            id='email'
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          ></input>
        </div>
        <button
          className='send-quote-button'
          onClick={() => {
            sendEmail(email);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              sendEmail(email);
            }
          }}
        >
          Send
        </button>
      </div>
      {!mobileScreen && (
        <button
          className='savepdf'
          onClick={savePDF}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              savePDF();
            }
          }}
        >
          Save as PDF <VscFilePdf />
        </button>
      )}
    </div>
  );
};

export default Quote;
