import FreigtButton from '../../assets/SVG/ReactSvg/FreigtButton';
import SellsheetButton from '../../assets/SVG/ReactSvg/SellsheetButton';
import StockCheckButton from '../../assets/SVG/ReactSvg/StockCheckButton';
// import ViewMedia from "../../Components/Products/ViewMedia";
import InstantFreight from '../../Components/Products/InstantFreight';
import StockCheck from '../../Components/Products/StockCheck';
// import StockTable from '../../Components/Products/StockTable';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../../hooks/useAuth';
import useUserInfo from '../../hooks/useUserInfo';
import { updateProductInfo } from '../../ReduxSlices/productState';

const ProductInfoButtons = () =>
  //   {seriesID}
  {
    const { displayLoginBox, displayBoolean } = useUserInfo();
    const { auth } = useAuth();
    const { product, skuCompleted, skuStatus } = useSelector((state) => state.productState.value);
    const dispatch = useDispatch();
    const [popupTried, setPopupTried] = useState();
    // const [stockShowing, setStockShowing] = useState(false);
    // const [downloadMedia, setDownloadMedia] = useState(false);
    const [stockShow, setStockShow] = useState(false);
    const [freightShow, setFreightShow] = useState(false);
    // const [stockData, setstockData] = useState({});

    useEffect(() => {
      if (!displayBoolean && auth?.rep) {
        switch (popupTried) {
          case 'freight':
            setFreightShow(true);
            break;
          case 'stock':
            setStockShow(true);
            break;
          default:
            break;
        }
      } else if (!displayBoolean) {
        setPopupTried();
      }
    }, [displayBoolean]);

    return (
      <>
        {/* {downloadMedia ? (
        <ViewMedia
          sku={product?.modelNum}
          setDownloadMedia={setDownloadMedia}
        />
      ) : (
        ""
      )} */}
        {freightShow ?
          <InstantFreight setFreightShow={setFreightShow} />
        : ''}
        {stockShow ?
          <StockCheck setStockShow={setStockShow} />
        : ''}

        <div className='products-buttons'>
          <StockCheckButton
            clickHandler={() => {
              if (auth?.roles?.includes(3001) || auth?.roles?.includes(3500)) {
                setStockShow(true);
              } else if (auth?.rep) {
                dispatch(updateProductInfo({ unauthorized: true }));
              } else {
                setPopupTried('stock');
                displayLoginBox();
              }
            }}
          />
          <FreigtButton
            clickHandler={() => {
              if (auth?.roles?.includes(3001) || auth?.roles?.includes(3500)) {
                if (skuCompleted || skuStatus === 'Completed') {
                  setFreightShow(true);
                } else {
                  dispatch(
                    updateProductInfo({
                      notification: 'Complete sku before running freight quote',
                    })
                  );
                }
              } else if (auth?.rep) {
                dispatch(updateProductInfo({ unauthorized: true }));
              } else {
                setPopupTried('freight');
                displayLoginBox();
              }
            }}
          />
          {(
            product?.literature?.filter((i) => {
              return i.type === 'Sell Sheet';
            })?.length
          ) ?
            <SellsheetButton
              clickHandler={() => {
                // setDownloadMedia(true);

                window.open(
                  `${
                    product?.literature?.filter((i) => {
                      return i.type === 'Sell Sheet';
                    })[0].full_link
                  }`,
                  '_blank'
                );
              }}
            />
          : <div style={{ cursor: 'auto' }}></div>}
        </div>

        {/* {stockShowing ?
        <StockTable
          stockData={stockData}
          series={seriesID}
        />
      : <></>} */}
      </>
    );
  };

export default ProductInfoButtons;
