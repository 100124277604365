import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import '../src/CSS/App.css';
import '../src/CSS/MobileApp/M_App.css';
import Admin from './Components/admin';
import AccountRequests from './Components/AdminPortal/AccountRequests';
import AS400Tables from './Components/AdminPortal/AS400Tables';
import DealerAccountRequests from './Components/AdminPortal/DealerAccountRequests';
import NewUsers from './Components/AdminPortal/NewUsers';
import NondealerRequests from './Components/AdminPortal/NondealerRequests';
import RouteRequests from './Components/AdminPortal/RouteRequests';
import UsersNew from './Components/AdminPortal/UsersNew';
import EditableFormDesign from './Components/EditorPortal/EditableForm';
import EditCategoryTree from './Components/EditorPortal/EditCategoryTree';
import EditCloudinaryPdf from './Components/EditorPortal/EditCloudinaryPdf';
import EditEnvList from './Components/EditorPortal/EditEnvList';
import EditItems from './Components/EditorPortal/EditItems';
import EditTable from './Components/EditorPortal/EditTable';
import ExportForDesign from './Components/EditorPortal/ExportForDesign';
import EditCalendarTasks from './Components/EmployeePortal/EditCalendarTasks';
import PurchasingPortal from './Components/EmployeePortal/PurchasingPortal';
import SalesPortal from './Components/EmployeePortal/SalesPortal';
import Layout from './Components/Layout';
import PersistLogin from './Components/Login/PersistLogin';
import Register from './Components/Login/Register';
import RequestLogin from './Components/Login/RequestLogin';
import RequestPasswordReset from './Components/Login/requestPasswordReset';
import RequireAuth from './Components/Login/RequireAuth';
import ResetPassword from './Components/Login/resetPassword';
import Unauthorized from './Components/Login/Unauthorized';
import ApiTests from './Components/oldAdmin';
import TableView from './Components/Quickship/TableView';
import About from './Pages/About/About';
import AboutMenu from './Pages/About/AboutMenu';
import Contracts from './Pages/About/Contracts';
import DesignServices from './Pages/About/DesignServices';
import Environmental from './Pages/About/Environmental';
import Patents from './Pages/About/Patents';
import Returns from './Pages/About/Returns';
import Testimonials from './Pages/About/Testimonials';
import Warranty from './Pages/About/Warranty';
import CatalogsPricer from './Pages/Catalogs_Pricer';
import Category2 from './Pages/category2';
import CycleCalendar from './Pages/CycleCalendar';
import AccountManagerPortal from './Pages/DealerPortal/AccountManagerPortal';
import DealerMap from './Pages/DealerPortal/DealerMap';
import DealerPortal from './Pages/DealerPortal/DealerPortal';
import FreightCart from './Pages/DealerPortal/freightCart';
import OrderTracking from './Pages/DealerPortal/orderTracking';
import SavedQuotes from './Pages/DealerPortal/SavedQuotes';
import ServicePortal from './Pages/DealerPortal/ServicePortal';
import SpacePlanning from './Pages/DealerPortal/SpacePlanning';
import EditorPortalNew from './Pages/EditorPortalNew';
import EmployeePortal from './Pages/EmployeePortal';
import EmployeePortalCustomForm from './Pages/EmployeePortalCustomForm';
import EmployeePortalIcons from './Pages/EmployeePortalIcons';
import Environments from './Pages/Environments/Environments';
import Home from './Pages/HomePage/Home';
import Brochures from './Pages/Media/Brochures';
import Catalog from './Pages/Media/Catalog';
import MediaCenter from './Pages/Media/MediaCenter';
import MediaDownloads from './Pages/Media/MediaDownloads';
import Videos from './Pages/Media/Videos';
import NewProducts from './Pages/NewProducts';
import NJSDA from './Pages/NJSDA';
import NotFound from './Pages/NotFound';
import ReusableComponents from './Pages/NPSportal/ReusableComponents';
import ProductsPage from './Pages/Products/productsPageNew';
import StageDragDrop from './Pages/Products/StageDragDrop';
import TabletopGuide from './Pages/Products/TabletopGuide';
import InStockLaminateItems from './Pages/QuickShip/InStockLaminateItems';
import QuickShip from './Pages/QuickShip/QuickShip';
import Search from './Pages/Search';
import Swag from './Pages/Swag';
import Terms from './Pages/Terms';
import TestCloudinary from './Pages/testCloudinary';
import WhatsNew from './Pages/WhatsNew';
import EditCloudinaryLinks from './Components/EditorPortal/EditCloudinaryLinks';
import { StageProvider } from './context/StageProvider';
// import ProductBatchUpdate from './Components/EditorPortal/ArchivedTools.js/ProductBatchUpdate';
// import TestPdf from "./Pages/TestPdf";
// import EditByGroup from "./Components/EditorPortal/EditByGroup";

function RouteDirectory() {
  const location = useLocation();

  const editorPaths = [
    { path: 'EditCloudinaryPdf', element: <EditCloudinaryPdf /> },
    { path: 'exportDesign', element: <ExportForDesign /> },
    { path: 'editItems', element: <EditItems /> },
    { path: 'catTree', element: <EditCategoryTree /> },
    { path: 'envList', element: <EditEnvList /> },
    { path: 'updateCloudinaryLinks', element: <EditCloudinaryLinks /> },
    // { path: "updateGroup", element: <EditByGroup /> },
    // { path: "productupdate", element: <ProductBatchUpdate /> },
  ];

  const searchParams = new URLSearchParams(location.search);
  const catalog = searchParams.get('catalog');
  return (
    <>
      <Routes>
        <Route element={<PersistLogin />}>
          <Route
            path='/njsda'
            element={<NJSDA />}
          />
          <Route
            path='/catalogs_pricer'
            element={<CatalogsPricer />}
          />
          <Route
            path='/north-carolina-contract-info'
            element={<CatalogsPricer />}
          />
          <Route
            path='/'
            element={<Layout />}
          >
            <Route
              path='/'
              element={<Home />}
            />
            <Route
              path='unauthorized'
              element={<Unauthorized />}
            />
            <Route
              path='requestlogin'
              element={<RequestLogin />}
            />
            <Route
              path='register'
              element={<Register />}
            />
            <Route path='reset-password'>
              <Route
                path=':resetID'
                element={<ResetPassword />}
              />
              <Route
                path=''
                element={<RequestPasswordReset />}
              />
            </Route>
            <Route
              path='categories/:categoryID'
              element={<Category2 />}
            />
            <Route
              path='categories'
              element={<Category2 />}
            />
            <Route path='products/:seriesID'>
              <Route
                path=':productID'
                element={<ProductsPage />}
              />
              <Route
                path=''
                element={<ProductsPage />}
              />
            </Route>
            <Route
              path='TabletopGuide'
              element={<TabletopGuide />}
            />
            <Route
              path='TabletopGuide/:scroll'
              element={<TabletopGuide />}
            />
            <Route
              path='brochures'
              element={<Brochures />}
            />
            {(
              catalog?.toLowerCase() === 'nps price list' ||
              catalog?.toLowerCase() === 'lista de precios de nps'
            ) ?
              <Route element={<RequireAuth />}>
                <Route
                  path='catalog'
                  element={<Catalog />}
                />
              </Route>
            : <Route
                path='catalog'
                element={<Catalog />}
              />
            }
            <Route
              path='videos'
              element={<Videos />}
            />
            <Route
              path='media-center'
              element={<MediaCenter />}
            />
            <Route
              path='environments/:envID'
              element={<Environments />}
            />
            <Route
              path='environments'
              element={<Environments />}
            />
            <Route
              path='whats-new'
              element={<WhatsNew />}
            />

            <Route
              path='stage-configurator'
              element={
                <StageProvider>
                  <Outlet />
                </StageProvider>
              }
            >
              <Route
                path=':seriesID'
                element={<StageDragDrop />}
              />
              <Route
                path=''
                element={<StageDragDrop />}
              />
            </Route>

            <Route path='about'>
              <Route
                path='terms'
                element={<Terms />}
              />
              <Route
                path='testimonials'
                element={<Testimonials />}
              />
              <Route
                path='patents'
                element={<Patents />}
              />
              <Route
                path='government'
                element={<Contracts />}
              />
              <Route
                path='environmental'
                element={<Environmental />}
              />
              <Route
                path='warranty'
                element={<Warranty />}
              />
              <Route
                path='returns'
                element={<Returns />}
              />
              <Route
                path='design-services'
                element={<DesignServices />}
              />
              <Route
                path='overview'
                element={<About />}
              />
              <Route
                path=''
                element={<AboutMenu />}
              />
            </Route>

            <Route
              path='new'
              element={<NewProducts />}
            />
            <Route
              path='dealer-locator'
              element={<DealerMap />}
            />
            <Route
              path='search'
              element={<Search />}
            />
            <Route
              path='quickship'
              element={<QuickShip />}
            />
            <Route
              path='quickship/:seriesID'
              element={<QuickShip />}
            />
            <Route
              path='overstock-products'
              element={<InStockLaminateItems />}
            />
            <Route
              path='contact'
              element={<ServicePortal />}
            />
            <Route element={<RequireAuth />}>
              <Route
                path='account-users'
                element={<AccountManagerPortal />}
              />
              <Route
                path='route-requests'
                element={<RouteRequests />}
              />
            </Route>

            <Route element={<RequireAuth />}>
              <Route
                path='saved-quotes'
                element={<SavedQuotes />}
              />
              <Route
                path='space-planning'
                element={<SpacePlanning />}
              />
              <Route
                path='swag'
                element={<Swag />}
              />
              <Route
                path='media'
                element={<MediaDownloads />}
              />
              <Route element={<RequireAuth />}>
                <Route
                  path='cart'
                  element={<FreightCart />}
                />
              </Route>
              <Route element={<RequireAuth />}>
                <Route
                  path='track-order'
                  element={<OrderTracking />}
                />
              </Route>
              <Route element={<RequireAuth />}>
                <Route
                  path='dealer-portal'
                  element={<DealerPortal />}
                />
              </Route>
            </Route>

            {/* Routes for employees*/}
            <Route element={<RequireAuth />}>
              <Route
                path='employee-portal'
                element={<EmployeePortal />}
              ></Route>
              <Route
                path='employee-portal/icons'
                element={<EmployeePortalIcons />}
              ></Route>
              <Route
                path='employee-portal/customForm'
                element={<EmployeePortalCustomForm />}
              ></Route>
              <Route
                path='employee-portal/:parentTab'
                element={<EmployeePortal />}
              ></Route>
              <Route
                path='cycle-calendar'
                element={<CycleCalendar />}
              ></Route>
              <Route
                path='cycle-calendar/calendarTasks'
                element={<EditCalendarTasks />}
              ></Route>
              <Route
                path='employee-portal/Sales'
                element={<SalesPortal />}
              ></Route>
              <Route
                path='employee-portal/Purchasing'
                element={<PurchasingPortal />}
              ></Route>
              <Route
                path='employee-portal/Purchasing/:tableName'
                element={<TableView />}
              ></Route>
            </Route>

            {/* Routes for admin */}
            <Route element={<RequireAuth />}>
              <Route
                path='admin'
                element={<Admin />}
              ></Route>
              <Route
                path='admin/users'
                element={<UsersNew />}
              ></Route>
              <Route
                path='admin/new-users'
                element={<NewUsers />}
              ></Route>
              <Route
                path='admin/account-requests'
                element={<AccountRequests />}
              ></Route>
              <Route
                path='admin/nondealers'
                element={<NondealerRequests />}
              ></Route>
              <Route
                path='admin/dealer-account-requests'
                element={<DealerAccountRequests />}
              />
              <Route
                path='admin/route-requests'
                element={<RouteRequests />}
              />
              <Route element={<RequireAuth />}>
                <Route
                  path='admin/apitests'
                  element={<ApiTests />}
                ></Route>
                <Route
                  path='admin/as400Tables'
                  element={<AS400Tables />}
                ></Route>
                <Route
                  path='admin/testCloudinary'
                  element={<TestCloudinary />}
                ></Route>
              </Route>
            </Route>

            {/*Routes for Editors*/}
            <Route element={<RequireAuth />}>
              <Route
                path='edit'
                element={<EditorPortalNew />}
              ></Route>
              <Route
                path='editTable/:tableName'
                element={<EditTable />}
              ></Route>

              {editorPaths?.map((i, index) => {
                return (
                  <Route
                    key={index}
                    path={`${i.path}`}
                    element={i.element}
                  ></Route>
                );
              })}
            </Route>
            <Route
              path='editableform'
              element={<EditableFormDesign />}
            ></Route>
            <Route
              path='*'
              element={<NotFound />}
            />
            <Route element={<RequireAuth />}>
              <Route
                path='reusableComponents'
                element={<ReusableComponents />}
              ></Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default RouteDirectory;
