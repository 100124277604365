import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../../context/CartContext';

const CartButton = () => {
  const [hover, setHover] = useState(false);
  const { cart } = useCart();
  const [cartCount, setCartCount] = useState(cart?.length);

  useEffect(() => {
    setCartCount(cart?.length);
  }, [cart]);
  const navigate = useNavigate();
  return (
    <div
      role='button'
      aria-label='Go to Cart'
      className='svg-div-cart'
      tabIndex={0}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={() => {
        navigate(`/cart`);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          navigate(`/cart`);
        }
      }}
    >
      <svg
        data-name='Capa 2'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 49.75 49.75'
      >
        <g data-name='Capa 1'>
          <circle
            className='cart-button-border'
            cx={24.88}
            cy={24.88}
            r={24.5}
            style={{
              fill: '#fff',
              stroke: `${hover ? 'var(--darkblue)' : '#b3b3b3'}`,
              strokeWidth: `${hover ? '1px' : '.75px'}`,
              cursor: `${hover ? 'pointer' : 'default'}`,
            }}
          />
          <path
            className='cart-button-icon-path'
            d='M6.87 25.73c.14-.2.25-.43.41-.6 1.76-1.84 3.54-3.66 5.31-5.49.26-.27.56-.4.94-.4h3.03c.11 0 .23 0 .37-.02v-4.01c0-.72.37-1.1 1.09-1.1h17.72c.71 0 1.08.38 1.09 1.1v2.76h.65c.61.01 1.04.39 1.05.94.01.56-.42.97-1.05.99h-.62v1.93h2.11c.44.01.77.31.88.72.1.38-.06.83-.42 1.03-.2.11-.45.16-.67.16-.63.02-1.26 0-1.93 0v8.7c0 .78-.37 1.14-1.15 1.14-.5 0-1.01.01-1.51 0-.26 0-.39.06-.49.32a3.948 3.948 0 0 1-3.7 2.52c-1.63 0-3.1-.97-3.69-2.49-.11-.27-.24-.34-.52-.34-2.83 0-5.65.01-8.48 0-.28 0-.4.09-.51.35-.62 1.44-1.72 2.3-3.28 2.45-1.66.16-2.94-.52-3.84-1.92-.18-.28-.26-.7-.51-.83-.28-.15-.69-.05-1.05-.03-.6.04-1.02-.18-1.24-.75V25.7Zm28.01-5.82H29.4c-.14 0-.28 0-.42-.03-.5-.09-.82-.51-.79-1.02.03-.48.4-.86.9-.88.65-.02 1.31 0 1.97 0h3.81v-1.91H18.89v15.62c.13 0 .24.02.34.02h6.48c.24 0 .35-.05.42-.3a3.948 3.948 0 0 1 3.86-2.93c1.81.01 3.34 1.19 3.84 2.97.02.07.04.14.08.24h.97V23.8c-.13 0-.26-.02-.38-.02h-4.51c-.58 0-.98-.3-1.07-.78-.12-.63.33-1.14 1.01-1.15h4.95v-1.93ZM8.81 31.68h.35c.55-1.9 1.75-3.09 3.77-3.18 1.92-.09 3.21.91 4 2.72v-4.3H8.82v4.75Zm8.12-10.5h-2.91c-.12 0-.27.04-.34.12-1.17 1.2-2.33 2.4-3.53 3.64h6.78v-3.76Zm15.09 11.27c0-1.14-.92-2.04-2.06-2.03-1.15 0-2.07.94-2.05 2.08.02 1.14.93 2.04 2.06 2.03 1.15 0 2.06-.92 2.05-2.08ZM13.1 34.53c1.13 0 2.05-.91 2.06-2.04a2.06 2.06 0 0 0-2.06-2.07c-1.14 0-2.04.91-2.05 2.04 0 1.15.9 2.07 2.06 2.07Z'
            style={{
              fill: '#01425a',
            }}
          />
          <path
            className='cart-button-counter-path'
            d='M43.42 17.55c.02 4.88-3.98 8.92-8.86 8.94-4.91.02-8.96-3.96-8.98-8.82-.02-5.02 3.94-9 8.95-9.02 4.87-.02 8.87 3.99 8.89 8.91Z'
            style={{
              strokeMiterlimit: 10,
              fill: '#bed46e',
              stroke: '#fff',
            }}
          />
          <text
            transform={`${
              cartCount < 10 ? 'translate(31.5 21)'
              : cartCount < 20 ? 'translate(30 21)'
              : 'translate(29.5 21)'
            }`}
            style={{
              fontFamily: 'Poppins',
              fontSize: `${cartCount < 20 ? '10px' : '9px'}`,
              fontWeight: 600,
              fill: '#01425a',
              userSelect: 'none',
            }}
          >
            <tspan
              x={0}
              y={0}
            >
              {cartCount}
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
};

export default CartButton;
