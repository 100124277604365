// import {SiQuicktime} from 'react-icons/si'
// import {RiNumber1, RiNumber0} from 'react-icons/ri'
// import {MdOutlineMiscellaneousServices} from 'react-icons/md'
// import { TfiLayoutLineSolid } from "react-icons/tfi";

import { useNavigate } from 'react-router-dom';

const AboutUsSection = () => {
  const navigate = useNavigate();
  return (
    <div>
      <h1 className='aboutTitle'>
        ABOUT US
        <div className='home-underline'></div>
      </h1>

      <div className='aboutUsCards'>
        <div
          role='button'
          aria-label='Quick Ship'
          tabIndex={0}
          className='aboutCard'
          onClick={() => {
            navigate('/catalog?catalog=2023+Quick+Ship');
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              navigate('/catalog?catalog=2023+Quick+Ship');
            }
          }}
        >
          <div className='quick-ship about-us-img'></div>
          <h2>Quick Ship</h2>
          <p className='about-p'>
            Most orders ship from stock in 24 hours from one of our three distribution centers.
            Orders received before 12pm often ship same day.
          </p>
          <div className='learn-more-button'>
            <div className='learn-more-line'></div>
            <div>View Guide</div>
          </div>
        </div>
        <div
          role='button'
          aria-label='Industrial Grade'
          tabIndex={0}
          className='aboutCard'
          onClick={() => {
            navigate('/about/warranty');
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              navigate('/about/warranty');
            }
          }}
        >
          <div className='industrial-grade about-us-img'></div>
          <h2>Industrial Grade</h2>
          <p className='about-p'>
            NPS products undergo rigorous quality control testing to ensure they surpass every
            industry standard. As such, products are backed by a comprehensive 10 year warranty.
          </p>
          <div className='learn-more-button'>
            <div className='learn-more-line'></div>
            <div>Learn More</div>
          </div>
        </div>
        <div
          role='button'
          aria-label='Exceptional Service'
          tabIndex={0}
          className='aboutCard'
          onClick={() => {
            navigate('/contact');
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              navigate('/contact');
            }
          }}
        >
          <div className='exceptional-service about-us-img'></div>
          <h2>Exceptional Service</h2>
          <p className='about-p'>
            Our team is constantly finding new ways to improve customer experience. Whether before,
            during, or after the sale, NPS is here for you.
          </p>
          <div className='learn-more-button'>
            <div className='learn-more-line'></div>
            <div>Go To Portal</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsSection;
