import { useEffect, useState } from 'react';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import LoadingIcon from '../Shared/LoadingIcon';
const AS400Tables = () => {
  const axiosJWT = useAxiosJWT();
  const [notification, setNotification] = useState('');
  const [schema, setSchema] = useState('');
  const [table, setTable] = useState('');
  const [loading, setLoading] = useState(false);
  function updateTables() {
    if (schema && table) {
      setLoading(true);
      axiosJWT
        .post(`tables/updateAS400Table`, {
          schema: schema.toUpperCase(),
          table: table.toUpperCase(),
        })
        .then((response) => {
          setNotification(response?.data?.message || response.data);
          setSchema('');
          setTable('');
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setNotification(err);
          setSchema('');
          setTable('');
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  return (
    <div
      className='basic-flex inner-padding'
      style={{ height: '30rem' }}
    >
      <input
        className='schema-input'
        placeholder='Schema'
        onChange={(e) => setSchema(e.target.value)}
        value={schema}
        disabled={loading}
      ></input>
      <input
        className='schema-input'
        placeholder='Table'
        onChange={(e) => setTable(e.target.value)}
        value={table}
        disabled={loading}
      ></input>
      {loading ?
        <LoadingIcon />
      : <button
          className='editableFormButton'
          onClick={() => updateTables()}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              updateTables();
            }
          }}
        >
          UPDATE
        </button>
      }
      {notification ?
        <div
          style={{
            fontSize: '16px',
            top: '47.7rem',
            marginLeft: '4.8rem',
            width: '520px',
          }}
          className='notice'
        >
          {notification}
        </div>
      : ''}
    </div>
  );
};

export default AS400Tables;
