const TemplateTileElement = ({ element, empty = false }) => {
  return (
    <>
      {empty ?
        <div className='template-tile-empty'></div>
      : <div>{element}</div>}
    </>
  );
};

export default TemplateTileElement;
