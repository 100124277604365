import { useState } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import '../../CSS/NPSportal/reusableComponents.css';

const BasicDropdown = ({ list, title }) => {
  const [openList, setOpenList] = useState(false);
  const [chosenItem, setChosenItem] = useState('');

  return (
    <div>
      <div
        role='button'
        aria-label={title}
        tabIndex={0}
        onClick={() => setOpenList(!openList)}
        className={`basic-dropdown-tab ${openList || chosenItem ? 'dropdown-clicked' : ''}`}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setOpenList(!openList);
          }
        }}
      >
        <div className='basic-flex'>
          <div className='basic-dropdown-title'>{chosenItem ? chosenItem.text : title}</div>
        </div>

        <MdKeyboardArrowDown
          className={`basic-dropdown-arrow ${openList ? 'rotate-up' : 'rotate-down'}`}
        />
      </div>
      {openList && (
        <div className='basic-dropdown-list'>
          {list?.map((i, index) => (
            <div
              role='button'
              aria-label={i.text}
              tabIndex={0}
              key={index}
              onClick={() => {
                setChosenItem(i);
                setOpenList(false);
              }}
              className='dropdown-entire-item'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setChosenItem(i);
                  setOpenList(false);
                }
              }}
            >
              {i.icon && (
                <img
                  className='dropdown-icon'
                  src={`${i.icon}`}
                  alt={i.text}
                />
              )}
              <div className='dropdown-list-item'>{i.text}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BasicDropdown;
