import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import EditTemplate from '../EditorPortal/EditTemplate';

const UsersNew = () => {
  const axiosJWT = useAxiosJWT();
  const [accountRoles, setAccountsRoles] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // Convert searchParams to string
  const queryString = searchParams.toString();
  const columns = [
    {
      type: 'text',
      name: 'ID',
      searchAllowed: true,
      editable: false,
      display: false,
      table: 'users',
      fieldName: 'ID',
    },
    {
      type: 'text',
      name: 'Name',
      searchAllowed: true,
      editable: true,
      display: true,
      table: 'users',
      fieldName: 'rep',
    },
    {
      type: 'text',
      name: 'Company',
      searchAllowed: true,
      editable: true,
      display: true,
      table: 'users',
      fieldName: 'dealer',
    },
    {
      type: 'text',
      name: 'Username',
      searchAllowed: true,
      editable: true,
      display: true,
      table: 'users',
      fieldName: 'username',
    },
    {
      type: 'text',
      name: 'Password',
      searchAllowed: true,
      editable: false,
      display: false,
      table: 'users',
      fieldName: 'password',
    },

    {
      type: 'text',
      name: 'NPSAccessToken',
      searchAllowed: true,
      editable: false,
      display: false,
      table: 'users',
      fieldName: 'NPSAccessToken',
    },
    {
      type: 'text',
      name: 'refreshToken',
      searchAllowed: true,
      editable: false,
      display: false,
      table: 'users',
      fieldName: 'refreshToken',
    },
    {
      type: 'text',
      name: 'resetLink',
      searchAllowed: true,
      editable: false,
      display: false,
      table: 'users',
      fieldName: 'resetLink',
    },

    {
      type: 'accounts',
      name: 'Accounts',
      searchAllowed: true,
      editable: false,
      display: true,
      table: 'user_accounts',
      fieldName: 'account_num',
    },
    {
      type: 'account_roles',
      name: 'Accounts Roles',
      searchAllowed: true,
      editable: false,
      display: true,
      table: 'user_accounts',
      fieldName: 'Accounts',
    },
    {
      type: 'manage',
      name: 'Manage Account',
      searchAllowed: true,
      editable: false,
      display: true,
      table: 'users',
      fieldName: 'manage',
    },
    {
      type: 'roles',
      name: 'internalRoles',
      searchAllowed: true,
      editable: true,
      display: true,
      table: 'roles_by_user',
      fieldName: 'internalRoles',
    },
    {
      type: 'boolean',
      name: 'active',
      searchAllowed: true,
      editable: true,
      display: true,
      table: 'users',
      fieldName: 'active',
    },
  ];

  let decoded;
  if (queryString) {
    decoded = decodeURIComponent(queryString)?.substring(queryString?.indexOf('=') + 1);
  }

  function saveHandlerUsers(values) {
    const userValues = Object.keys(values).reduce((result, key) => {
      const column = columns.find((col) => col.name.toLowerCase() === key.toLowerCase());
      if (column && column.table === 'users' && column.editable) {
        result[column.fieldName] = values[key];
      }
      return result;
    }, {});

    const roleValues = Object.keys(values).reduce((result, key) => {
      const column = columns.find((col) => col.name.toLowerCase() === key.toLowerCase());
      if (column && column.table === 'roles_by_user') {
        result[key] = values[key];
      }
      return result;
    }, {});

    axiosJWT
      .post(`adminPortal/saveUser`, {
        ID: values.ID,
        userValues: userValues,
        roleValues: roleValues,
      })
      .then(() => {})
      .catch((err) => {
        console.error(err.sqlMessage);
      });
  }

  useEffect(() => {
    axiosJWT
      .post(`register/getAccountRoles`)
      .then((response) => {
        setAccountsRoles(response.data);
      })
      .catch((err) => {
        console.error(err.sqlMessage);
      });
  }, []);

  return (
    <EditTemplate
      columns={columns}
      getEndPoint={'users'}
      title={'Users'}
      table={'users'}
      exportAvailable={true}
      saveHandlerIncoming={saveHandlerUsers}
      accountRoles={accountRoles}
      addHandlerIncoming={null}
      searchInput={decoded}
    />
  );
};

export default UsersNew;
