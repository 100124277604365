
      if (
        import.meta.env.VITE_SERVER_API_ADDRESS === 'https://www.nationalpublicseating.com/node'
      ) {
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-2N7C0PFVZK';
        document.head.appendChild(script);

        script.onload = () => {
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'G-2N7C0PFVZK');
        };
      }
    