import PropTypes from 'prop-types';
import React from 'react';
import '../../CSS/DealerMap.css';
import DealerInfoReturn from './DealerInfoReturn';
import USAState from './USAState';
class USAMap extends React.Component {
  clickHandler = (stateAbbreviation) => {
    this.props.onClick(stateAbbreviation);
  };

  fillStateColor = (state) => {
    if (this.props.customize && this.props.customize[state] && this.props.customize[state].fill) {
      return this.props.customize[state].fill;
    }

    return this.props.defaultFill;
  };

  stateClickHandler = (state) => {
    if (
      this.props.customize &&
      this.props.customize[state] &&
      this.props.customize[state].clickHandler
    ) {
      return this.props.customize[state].clickHandler;
    }
    return this.clickHandler;
  };

  buildPaths = () => {
    var paths = [];
    if (this.props.dataStates.length > 0) {
      for (const state of this.props.dataStates) {
        var path = (
          <USAState
            key={state?.abbreviation}
            stateName={
              this.props.dataStates.find((i) => i.abbreviation === state.abbreviation)?.name
            }
            dimensions={
              this.props.dataStates.find((i) => i.abbreviation === state.abbreviation)?.dimensions
            }
            state={state?.abbreviation}
            fill={this.fillStateColor(state.abbreviation)}
            onClickState={() => {
              this.props.onClick(
                this.props.dealerData[state.abbreviation].managers.find((i) => i.type === 'account')
                  .email,
                this.props.dealerData[state.abbreviation].StateSubject
              );
            }}
            onEnterState={() => {
              this.props.onEnterState(
                <DealerInfoReturn data={this.props.dealerData[state.abbreviation]} />
              );
            }}
            onLeaveState={() => {
              this.props.onLeaveState(state.abbreviation);
            }}
            stateData={this.props.dealerData[state.abbreviation]}
          />
        );
        paths.push(path);
      }
      return paths;
    }
  };

  render() {
    return (
      <svg
        className='us-state-map'
        xmlns='http://www.w3.org/2000/svg'
        width={this.props.width}
        height={this.props.height}
        viewBox='0 0 959 593'
      >
        <g className='outlines'>{this.buildPaths()}</g>
      </svg>
    );
  }
}

USAMap.propTypes = {
  onClick: PropTypes.func.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  title: PropTypes.string,
  defaultFill: PropTypes.string,
  customize: PropTypes.object,
};

USAMap.defaultProps = {
  onClick: () => {},
  width: 959,
  height: 593,
  defaultFill: '#D3D3D3',
  customize: {},
};

export default USAMap;
