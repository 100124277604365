import { useEffect, useState } from 'react';
import { BiError } from 'react-icons/bi';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import InputField from './InputField';
import useGeneralImages from '../../hooks/useGeneralImages';

const GeneralFeedback = () => {
  const { generalImages } = useGeneralImages();
  const check = generalImages.find((i) => i.publicId === 'green_check_d3olgm')?.imageLink;
  const axiosJWT = useAxiosJWT();
  const [formValues, setFormValues] = useState({
    email: '',
    firstName: '',
    company: '',
    files: [],
    comments: '',
    dealer: '',
    lastName: '',
  });
  const [inputFields, setInputFields] = useState();
  const [submitValid, setSubmitValid] = useState(false);
  const [submitComplete, setSubmitComplete] = useState(false);

  function getData() {
    axiosJWT.post(`content/getFormFields`).then((response) => {
      const formInputs = response.data.formfields;
      setInputFields(
        formInputs?.filter((i) => {
          return i['Form Name'].toLowerCase() === 'contact us';
        })
      );
      var holderObj = {};
      formInputs
        ?.filter((i) => i['Form Name'].toLowerCase() === 'contact us')
        ?.forEach((i) => {
          holderObj[i['Input Name']] = '';
        });
      setFormValues(holderObj);
    });
  }

  function onInputchange(event) {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  }
  function setFiles(files) {
    setFormValues({ ...formValues, files: files });
  }

  function saveForm() {
    // eslint-disable-next-line no-console
    console.log(formValues);
    const formData = new FormData();

    Object.values(formValues.files).forEach((file) => {
      formData.append('arrayOfFiles', file);
    });
    formData.append('formValues', JSON.stringify(formValues));
    axiosJWT
      .post(`forms/generalFeedback`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setSubmitComplete('success');
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        setSubmitComplete('error');
      });
  }

  function submitHandler() {
    saveForm();
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setSubmitValid(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formValues.email));
  }, [formValues.email]);

  return (
    <div className='claimForm generalForm'>
      <h2>Contact Us</h2>
      {!submitComplete ?
        <form>
          {inputFields?.map((i, index) => {
            return (
              <InputField
                key={index}
                data={i}
                onInputchange={onInputchange}
                formValues={formValues}
                files={formValues.files}
                setFiles={setFiles}
              />
            );
          })}
          <button
            onClick={(e) => {
              e.preventDefault();
              if (submitValid) {
                submitHandler();
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                if (submitValid) {
                  submitHandler();
                }
              }
            }}
            className={`${submitValid ? 'valid-continue' : 'invalid-continue'}`}
          >
            Submit
          </button>
        </form>
      : submitComplete === 'success' ?
        <form className='feedbackComplete'>
          <img
            src={check}
            alt='Check Icon'
          />
          <h2>Thank you for your feedback!</h2>
          <p>
            Thanks for letting us know your thoughts.<br></br> We take all feedback seriously and
            will work to incorporate your suggestions to improve our products and services.
          </p>
          <div className='underline'></div>
        </form>
      : <form className='feedbackComplete'>
          <BiError style={{ height: '11rem', width: '11rem', color: '#b90202' }} />
          <h2>
            Oops! Something went wrong while submitting your form. Please try submitting your form
            again in a few minutes. If the issue persists, contact our support team for assistance.
          </h2>
          <div className='underline'></div>
        </form>
      }
    </div>
  );
};

export default GeneralFeedback;
