import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { HiSquares2X2 } from 'react-icons/hi2';
import DownloadExcel from './DownloadExcel';
import decodeCloudinary from '../../hooks/decodeCloudinary';
const MediaButtons = ({
  setSelectAll,
  selectedList,
  mediaList,
  setSelectedList,
  setClearList,
  setListItems,
  listItems,
  fullMediaList,
  imageEffects,
  applyEffects,
  selectedType,
}) => {
  const decodeCloudinaryURL = decodeCloudinary();
  const createFilters = async () => {
    if (
      Object.keys(imageEffects)
        .filter((i) => i !== 'type')
        .filter((i) => imageEffects[i] !== '').length > 0
    ) {
      let string = `/${Object.keys(imageEffects)
        .filter((i) => i !== 'type')
        .filter((i) => imageEffects[i] !== '')
        .map((key) => {
          return `${key}_${imageEffects[key]}`;
        })
        .join(',')}`;
      if (!Object.keys(imageEffects).includes('q')) {
        string = `${string},q_auto`;
      }
      return string;
    }
    return '';
  };

  async function handleDownloadClick() {
    if (selectedType !== 'Images') {
      const zip = new JSZip();
      const folder = zip.folder('Images.zip'); // folder name where all files will be placed in
      selectedList.forEach((item) => {
        const blobPromise = fetch(`${item.imageLink}`).then((r) => {
          if (r.status === 200) {
            return r.blob();
          }
          return Promise.reject(new Error(r.statusText));
        });
        const name = item.modelNum + item.imageLink?.substring(item.imageLink?.indexOf('_'));
        folder.file(name, blobPromise);
      });

      zip.generateAsync({ type: 'blob' }).then((blob) => saveAs(blob, 'Images.zip'));
    } else {
      let filterString = '';

      if (applyEffects) {
        filterString = await createFilters();
      }

      const zip = new JSZip();
      const folder = zip.folder('Images.zip');

      try {
        await Promise.all(
          selectedList.map(async (item) => {
            let newImage;
            if (Object.keys(imageEffects).includes('type') && imageEffects.type !== '') {
              newImage =
                item.imageLink.substring(0, item.imageLink.lastIndexOf('.') + 1) +
                imageEffects.type;
            } else {
              newImage = item.imageLink;
            }
            const response = await fetch(
              `https://res.cloudinary.com/da3rom333/image/upload${filterString}/fl_attachment/${decodeCloudinaryURL(
                newImage
              )}`
            );

            if (response.ok) {
              const blob = await response.blob();
              const name = item.modelNum + newImage.substring(newImage.indexOf('_'));

              folder.file(name, blob);
            } else {
              throw new Error(response.statusText);
            }
          })
        );
        const zipBlob = await zip.generateAsync({ type: 'blob' });
        saveAs(zipBlob, 'Images.zip');
      } catch (error) {
        console.error('Error generating the zip file:', error);
      }
    }
  }

  return (
    <div className='media-download-buttons'>
      <div>
        <button
          onClick={() => {
            var holderArray = [];
            Object.values(mediaList).forEach((item) => {
              item.imageLinks.forEach((i) => {
                holderArray.push(i);
              });
            });
            setSelectedList(holderArray);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              var holderArray = [];
              Object.values(mediaList).forEach((item) => {
                item.imageLinks.forEach((i) => {
                  holderArray.push(i);
                });
              });
              setSelectedList(holderArray);
            }
          }}
        >
          Select All
        </button>
        <button
          onClick={() => {
            setClearList(true);
            setSelectedList([]);
            setSelectAll(false);
            setTimeout(() => {
              setClearList(false);
            }, 500);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setClearList(true);
              setSelectedList([]);
              setSelectAll(false);
              setTimeout(() => {
                setClearList(false);
              }, 500);
            }
          }}
        >
          Clear Selection
        </button>
        <button
          className={`${
            selectedList ?
              selectedList?.length ?
                ''
              : 'inactive'
            : 'inactive'
          }`}
          onClick={selectedList?.length ? handleDownloadClick : null}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              selectedList?.length ? handleDownloadClick() : null;
            }
          }}
        >
          Download Selected ({selectedList ? selectedList.length : 0})
        </button>

        <DownloadExcel
          mediaList={fullMediaList}
          imageEffects={imageEffects}
          applyEffects={applyEffects}
        />
      </div>
      <div className='media-view'>
        <AiOutlineUnorderedList
          onClick={() => {
            setListItems(true);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setListItems(true);
            }
          }}
          className={`${listItems ? 'selected-view' : ''}`}
        />
        <HiSquares2X2
          onClick={() => {
            setListItems(false);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setListItems(false);
            }
          }}
          className={`${!listItems ? 'selected-view' : ''}`}
        />
      </div>
    </div>
  );
};

export default MediaButtons;
