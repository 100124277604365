import { useRef } from 'react';
import '../../CSS/AdminPortal.css';
import * as ExcelJS from 'exceljs';

function UploadFile({ text, setData }) {
  const convertExcelToObject = (file) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      try {
        // Read the file as ArrayBuffer
        const arrayBuffer = event.target.result;

        // Create a new ExcelJS workbook
        const workbook = new ExcelJS.Workbook();

        // Load the file into ExcelJS
        await workbook.xlsx.load(arrayBuffer);

        // Assuming you want to read the first sheet
        const sheet = workbook.worksheets[0];
        const rows = [];

        // Iterate through each row of the sheet
        sheet.eachRow((row, rowIndex) => {
          const rowData = {};
          row.eachCell((cell, colIndex) => {
            const columnName = sheet.getRow(1).getCell(colIndex).text; // Use first row as headers
            rowData[columnName] = cell.value;
          });
          rows.push(rowData);
        });

        // Set the converted data to state
        setData(rows);
      } catch (error) {
        console.error('Error processing the Excel file:', error);
      }
    };

    // Read file as ArrayBuffer
    reader.readAsArrayBuffer(file);
  };

  const uploadFileHandler = (event) => {
    const file = event.target.files[0];
    convertExcelToObject(file);
  };

  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <div className='uploadFile'>
      <div>
        <label htmlFor='file'>
          <button
            className='editableFormButton'
            onClick={handleClick}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleClick();
              }
            }}
          >
            {text}
          </button>
          <div>
            <input
              onChange={uploadFileHandler}
              ref={hiddenFileInput}
              id='file'
              type='file'
              accept='.xlsx, .xls, .csv'
              style={{ display: 'none' }}
            />
          </div>
        </label>
      </div>
    </div>
  );
}

export default UploadFile;
