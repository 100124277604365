import { useEffect, useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import LaminateBar from '../Products/LaminateBar';
import LaminatesInfoHover from '../Products/LaminatesInfoHover';
import OptionsBar from './OptionsBar';
const FinishesOptions = ({ data, selectedFinishes, setSelectedFinishes, assignClass }) => {
  const [open, setOpen] = useState(false);
  const [infoHover, setInfoHover] = useState(false);
  useEffect(() => {
    if (selectedFinishes[data.name]) {
      setOpen(false);
    }
  }, [selectedFinishes[data.name]]);
  return (
    <div className={`finishOption ${open ? 'finishOptionOpen' : ''}`}>
      {infoHover && <LaminatesInfoHover />}
      <div
        role='button'
        aria-label={selectedFinishes[data.name]}
        tabIndex={0}
        className='title'
        onClick={() => {
          setOpen(!open);
        }}
        style={{
          borderColor: selectedFinishes[data.name] ? `#C0E66C` : `#BBBBBA`,
          color: selectedFinishes[data.name] ? `#C0E66C` : `#707070`,
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setOpen(!open);
          }
        }}
      >
        <h4>
          {selectedFinishes[data.name] ?
            `${selectedFinishes[data.name]} ${data.name}`
          : `Select ${data.name}`}
        </h4>
        {open ?
          <BsChevronUp className='arrow' />
        : <BsChevronDown className='arrow' />}
      </div>
      {open ?
        <div className='line'></div>
      : ''}
      {open ?
        <div className='options'>
          {data.listName === 'WoodLaminates' ?
            <LaminateBar
              setOptionsSelected={setSelectedFinishes}
              cartOptionsSelected={selectedFinishes}
              configSelected={data}
              laminates={data.values}
              setTopHexCode={false}
              setBenchHexCode={false}
              setStoolHexCode={false}
              assignClass={assignClass}
              setInfoHover={setInfoHover}
            />
          : data.listName === 'StoolColors' ?
            <OptionsBar
              configSelected={data}
              optionsSelected={selectedFinishes}
              setOptionsSelected={setSelectedFinishes}
              finishTypes={data.values}
            />
          : ''}
        </div>
      : ''}
    </div>
  );
};

export default FinishesOptions;
