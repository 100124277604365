import html2pdf from 'html2pdf.js';
import '../../CSS/StagePDF.css';

function createBuildElement(allProducts, comingSoonImg) {
  const cartContainer = document.createElement('div');
  cartContainer.className = 'cart';
  cartContainer.style.width = '612px';
  cartContainer.style.marginTop = '0px';

  const cartHeaders = document.createElement('div');
  cartHeaders.className = 'cart-headers';

  const modelHeader = document.createElement('div');
  Object.assign(modelHeader.style, {
    fontSize: '16px',
    width: '200px',
  });
  modelHeader.textContent = 'Model #';
  cartHeaders.appendChild(modelHeader);

  const descriptionHeader1 = document.createElement('div');
  descriptionHeader1.className = 'description-header';
  Object.assign(descriptionHeader1.style, {
    fontSize: '16px',
    width: '300px',
  });
  descriptionHeader1.textContent = 'Product Description';
  cartHeaders.appendChild(descriptionHeader1);

  const descriptionHeader2 = document.createElement('div');
  descriptionHeader2.className = 'description-header';
  Object.assign(descriptionHeader2.style, {
    fontSize: '16px',
    width: '112px',
  });
  descriptionHeader2.textContent = 'Quantity';
  cartHeaders.appendChild(descriptionHeader2);

  cartContainer.appendChild(cartHeaders);

  allProducts.forEach((product) => {
    const cartRow = document.createElement('div');
    cartRow.className = 'cart-row';

    const rowImage = document.createElement('div');
    rowImage.className = 'rowImage';
    Object.assign(rowImage.style, {
      fontSize: '14px',
      width: '200px',
    });

    const imageDiv = document.createElement('div');
    imageDiv.className = 'image-div';
    Object.assign(imageDiv.style, {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    });

    const img = document.createElement('img');
    img.src = product.product.cdnLinks[0]?.Image || comingSoonImg;
    img.alt = 'Product Image';
    const modelNum = document.createElement('div');
    modelNum.textContent = `SKU: ${product.modelNum}`;
    imageDiv.appendChild(img);
    imageDiv.appendChild(modelNum);
    rowImage.appendChild(imageDiv);

    const description = document.createElement('div');
    description.className = 'description';
    Object.assign(description.style, {
      fontSize: '14px',
      fontWeight: 'normal',
      width: '300px',
    });
    description.textContent = product.product.productInfo.ShortDescription;

    const quantity = document.createElement('div');
    quantity.className = 'description';
    quantity.style.width = '100px';
    Object.assign(quantity.style, {
      width: '112px',
      fontSize: '14px',
      fontWeight: 'normal',
    });

    quantity.textContent = `QTY: ${product.quantity}`;

    cartRow.appendChild(rowImage);
    cartRow.appendChild(description);
    cartRow.appendChild(quantity);

    cartContainer.appendChild(cartRow);
  });

  return cartContainer;
}

function getSurfaceColor(surface) {
  switch (surface) {
    case 'Black Carpet':
      return 'black';
    case 'Blue Carpet':
      return '#3D4C69';
    case 'Grey Carpet':
      return '#665E5D';
    case 'Hardboard':
      return '#9C684C';
    case 'Red Carpet':
      return '#6B3637';
    default:
      return 'var(--darkblue)';
  }
}

async function exportPDF(
  cartProducts,
  steps,
  guardrails,
  skirting,
  buildDims,
  groupByProduct,
  capacity,
  imageRef,
  stageDollies,
  riserDollies,
  comingSoonImg
) {
  const allProducts = [
    ...cartProducts,
    ...groupByProduct(steps),
    ...groupByProduct(guardrails.filter((g) => !steps.find((s) => s.id === g.id))),
    ...groupByProduct(skirting.filter((g) => !steps.find((s) => s.id === g.id))),
  ];

  if (stageDollies) {
    allProducts.push(stageDollies);
  }
  if (riserDollies) {
    allProducts.push(riserDollies);
  }
  const originalWidthInches = 1000 / 96;
  const originalHeightInches = 700 / 96;

  const targetHeight = 11 / 2.3;

  const scale = targetHeight / originalHeightInches;

  const scaledWidthInches = originalWidthInches * scale;

  const firstPageContainer = document.createElement('div');
  Object.assign(firstPageContainer.style, {
    width: `8.5in`,
    // height: `11in`,
    position: 'relative',
    fontFamily: 'poppins',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    backgroundColor: 'transparent',
  });

  const secondPageContainer = document.createElement('div');
  Object.assign(secondPageContainer.style, {
    width: `8.5in`,
    // height: `11in`,
    position: 'relative',
    fontFamily: 'poppins',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    backgroundColor: 'transparent',
  });

  const svg = imageRef.current.cloneNode(true);
  svg.setAttribute('viewBox', `0 0 ${1000} ${700}`);
  svg.setAttribute('preserveAspectRatio', 'xMidYMid meet');

  Object.assign(svg.style, {
    width: `${scaledWidthInches}in`,
    height: `${targetHeight}in`,
    transform: `scale(${scale})`,
    transformOrigin: 'top left',
  });

  const title = document.createElement('div');
  title.textContent = 'Custom Stage Configuration';

  const innerHeader = document.createElement('div');
  innerHeader.textContent = 'Items in build:';

  Object.assign(title.style, {
    fontSize: '30px',
    fontWeight: 'bold',
    color: 'var(--darkblue)',
    marginBottom: '30px',
  });

  Object.assign(innerHeader.style, {
    fontSize: '24px',
    fontWeight: 'bold',
    color: 'var(--darkblue)',
    alignSelf: 'start',
    margin: '30px 0px 20px 0px',
    textTransform: 'uppercase',
  });

  const style = document.createElement('style');
  style.innerHTML = `
      .cart-row {
        page-break-inside: avoid;
      }
     
    `;

  const options = {
    filename: 'custom-stage.pdf',
    margin: 0.2,
    image: { type: 'jpeg', quality: 0.98 },
    pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
    html2canvas: { scale: 2, useCORS: true },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    html2pdf: {
      header: {
        text: 'Header Content',
        fontSize: 12,
        margin: [0, 0, 0, 10],
      },
      footer: {
        text: function (pageNum, totalPages) {
          return `Page ${pageNum} of ${totalPages}`;
        },
        fontSize: 12,
        margin: [0, 0, 0, 10],
      },
    },
  };

  const stageInformation = document.createElement('div');
  stageInformation.className = 'pdf-info-div';
  stageInformation.innerHTML = `
   <span class='pdf-info-header'>STAGE INFORMATION:</span>
<div class='pdf-flex-div outer-div'>
  <!-- COLOR REFERENCES -->
  <div>
    <span class='pdf-subheader'>COLOR REFERENCES</span>
    <div class="height-ref-list">
      <div class="height-ref-tile" style="background-color: #73D262;"></div>
      <span>Steps</span>
      <div class="height-ref-tile" style="background-color: #E5A703;"></div>
      <span>Guardrails</span>
      <div class="height-ref-tile" style="background-color: #658DCC;"></div>
      <span>Skirting</span>
      <div class="height-ref-tile gradient-background"></div>
      <span>Skirting + Guardrails</span>
    </div>
  </div>

  <!-- HEIGHT REFERENCES -->
  <div>
    <span class='pdf-subheader'>HEIGHT REFERENCES</span>
    <div class='height-ref-list'>
      <div class='height-ref-tile level-one' style="background-color: ${getSurfaceColor(
        allProducts[0]?.product?.configOptions?.Surface?.selectionName
      )};"></div>
      <span>8"</span>
      <div class='height-ref-tile level-two' style="background-color: ${getSurfaceColor(
        allProducts[0]?.product?.configOptions?.Surface?.selectionName
      )};"></div>
      <span>16"</span>
      <div class='height-ref-tile level-three' style="background-color: ${getSurfaceColor(
        allProducts[0]?.product?.configOptions?.Surface?.selectionName
      )};"></div>
      <span>24"</span>
      <div class='height-ref-tile level-four' style="background-color: ${getSurfaceColor(
        allProducts[0]?.product?.configOptions?.Surface?.selectionName
      )};"></div>
      <span>32"</span>
    </div>
  </div>
</div>

<!-- BUILD DIMENSIONS AND SUGGESTED CAPACITY -->
<div class='product-list-totals'>
  <div class='build-total-capacity'>
    <div class='capacity-title'>Build Dimensions</div>
    <div>
      ${Math.floor(parseFloat(buildDims.right.x - buildDims.left.x).toFixed(1) / 12)}'
      ${Math.round(parseFloat(buildDims.right.x - buildDims.left.x).toFixed(1) % 12)}" x
      ${Math.floor(parseFloat(buildDims.bottom.y - buildDims.top.y).toFixed(1) / 12)}'
      ${Math.round(parseFloat(buildDims.bottom.y - buildDims.top.y).toFixed(1) % 12)}"
    </div>
  </div>
  <div class='build-total-capacity'>
    <div class='capacity-title'>Suggested Capacity</div>
    ${Object.keys(capacity)
      .map((c) => {
        if (capacity[c]) {
          return `<div>${c.replace(/([A-Z])/g, ' $1').trim()}: ${capacity[c]}</div>`;
        }
        return '';
      })
      .join('')}
  </div>
</div>

  `;

  const build = createBuildElement(allProducts, comingSoonImg);
  firstPageContainer.appendChild(style);
  // firstPageContainer.appendChild(header);
  firstPageContainer.appendChild(title);
  firstPageContainer.appendChild(svg);
  firstPageContainer.appendChild(stageInformation);
  const pageBreak = document.createElement('div');
  const outerDiv = document.createElement('div');
  outerDiv.appendChild(innerHeader);
  outerDiv.appendChild(build);
  firstPageContainer.appendChild(pageBreak);
  secondPageContainer.appendChild(style);
  secondPageContainer.append(outerDiv);
  pageBreak.className = 'page-break';

  const parentContainer = document.createElement('div');
  parentContainer.appendChild(firstPageContainer);
  parentContainer.appendChild(secondPageContainer);

  await html2pdf().set(options).from(parentContainer).save();

  parentContainer.remove();
}

// <!-- ASSEMBLY INSTRUCTIONS -->
// <div class='outer-div'>
//   <span class='pdf-subheader'>Assembly Instructions</span>
//   <div class='basic-flex'>
//     ${allProducts
//       ?.map((product) =>
//         product?.product?.literature
//           ?.filter((i) => i.type === 'Assembly Instructions')
//           ?.map(
//             (lit) =>
//               `<a href="${lit.full_link}" target="_blank" style="display: block; margin-top: 10px; text-align: center; color: blue; text-decoration: underline;">
//               ${product.modelNum} - ${lit.type}
//             </a>`
//           )
//           .join('')
//       )
//       .join('')}
//   </div>
// </div>

export { exportPDF };
