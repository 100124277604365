import Axios from 'axios';
import { useState } from 'react';
import { useStageInfo } from '../../context/StageProvider';
import PrebuiltPackages from './PrebuiltPackages';

const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

function EditStageBuild() {
  const { stageBuild, setOpenPopup } = useStageInfo();
  const [selectedPackage, setSelectedPackage] = useState();
  const [message, setMessage] = useState();

  function handleSave() {
    // eslint-disable-next-line no-console
    console.log(selectedPackage);
    Axios.post(`${apiAddress}/products/editStageBuild`, {
      id: selectedPackage.id,
      build: stageBuild,
    })
      .then((response) => {
        setMessage(response.data);
      })
      .catch((error) => {
        console.error('Error editing stage build data:', error);
      });
  }

  return (
    <div className='save-build-popup'>
      {!message ?
        <>
          <h2>Edit Prebuilt Package</h2>
          <PrebuiltPackages
            editPackageData={true}
            selectedPackage={selectedPackage}
            setSelectedPackage={setSelectedPackage}
          />
          <button
            onClick={handleSave}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSave();
              }
            }}
            className={`submit save-build ${!selectedPackage ? 'disabled-basic-button' : ''}`}
          >
            Save
          </button>
        </>
      : <>
          <h4>{message}</h4>
          <button
            onClick={() => setOpenPopup(false)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setOpenPopup(false);
              }
            }}
            className='submit save-build'
          >
            Finish
          </button>
        </>
      }
    </div>
  );
}

export default EditStageBuild;
