import moment from 'moment';
import { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import EditCalendarEvent from '../Components/EmployeePortal/EditCalendarEvent';
import GenerateCalendar from '../Components/EmployeePortal/GenerateCalendar';
import ScheduleCalendarEvent from '../Components/EmployeePortal/ScheduleCalendarEvent';
import '../CSS/CycleCalendar.css';
import useAxiosJWT from '../hooks/useAxiosJWT';

const localizer = momentLocalizer(moment);

const CycleCalendar = () => {
  const axiosJWT = useAxiosJWT();
  const [eventsList, setEventsList] = useState([]);
  const [editPopup, setEditPopup] = useState();
  const [editedCalendar, setEditedCalendar] = useState();
  const [addEvent, setAddEvent] = useState();

  async function getEvents() {
    await axiosJWT
      .get(`employeePortal/events`)
      .then((response) => {
        formatEvents(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function formatEvents(events) {
    // calendar event times need to be a date object
    events?.forEach((event) => {
      event.start = new Date(event.start);
      event.end = new Date(event.end);
      if (event.count !== 'undefined') {
        event.count = JSON.parse(event.count);
      }
      if (event.comments) {
        event.comments = JSON.parse(event.comments);
      }
    });
    setEventsList(events);
  }

  useEffect(() => {
    getEvents();
    setEditedCalendar(false);
  }, [editedCalendar]);

  useEffect(() => {
    window.scrollTo({
      top: 170,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className='inner-padding'>
      <h2>Cycle Calendar</h2>
      <br></br>
      {eventsList && (
        <Calendar
          localizer={localizer}
          events={eventsList ? eventsList : []}
          onSelectEvent={(e) => setEditPopup(e)}
          style={{ height: 700, width: '1410px' }}
          eventPropGetter={(event) => ({
            style: {
              backgroundColor: event.color,
            },
          })}
        />
      )}
      {editPopup && (
        <EditCalendarEvent
          event={editPopup}
          setEditPopup={setEditPopup}
          eventsList={eventsList}
          setEventsList={setEventsList}
          setEditedCalendar={setEditedCalendar}
        />
      )}
      <button
        className='edit-cal-button add-event'
        onClick={() => setAddEvent({ title: '', start: '', end: '' })}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setAddEvent({ title: '', start: '', end: '' });
          }
        }}
      >
        Add Event
      </button>
      {addEvent && (
        <ScheduleCalendarEvent
          type='add'
          setShowAddEvent={setAddEvent}
          setEditedCalendar={setEditedCalendar}
        />
      )}
      <button
        className='edit-cal-button view-tasks'
        onClick={() => window.open('/cycle-Calendar/calendarTasks')}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            window.open('/cycle-Calendar/calendarTasks');
          }
        }}
      >
        View Tasks
      </button>
      <GenerateCalendar setEditedCalendar={setEditedCalendar} />
    </div>
  );
};

export default CycleCalendar;
